import axios from 'axios';
import { createApi } from './api';
export function create(_ref, logger) {
  let {
    httpClient
  } = _ref;
  const geolocationApi = createApi(httpClient);
  async function getSuggestions(params, signal) {
    try {
      const reponse = await geolocationApi.getSuggestions(params, signal);
      return (reponse === null || reponse === void 0 ? void 0 : reponse.features) || [];
    } catch (err) {
      if (!axios.isCancel(err)) {
        logger.error({
          error: err,
          message: "[GEOLOCATION MODULE] Cannot get location suggestion for: " + JSON.stringify(params)
        });
      }
      return [];
    }
  }
  return {
    getSuggestions
  };
}