import _defineProperty from "@babel/runtime/helpers/defineProperty";
import axios from 'axios';
export class CFELoggerAPIService {
  constructor(appConfig) {
    let axiosConfig = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    _defineProperty(this, "axiosInstance", void 0);
    this.axiosInstance = axios.create({
      baseURL: appConfig.loggerBaseApiUrl,
      headers: {
        'Content-Type': 'application/json'
      },
      ...axiosConfig
    });
  }
  post(logData) {
    this.axiosInstance.post('/', JSON.stringify(logData));
  }
}