export function createApi(httpClient) {
  async function getSuggestions(_ref, signal) {
    let {
      text,
      types,
      language,
      country,
      size
    } = _ref;
    const response = await httpClient.get('/geo/suggestions/geojson', {
      params: {
        text,
        country: country === null || country === void 0 ? void 0 : country.toUpperCase(),
        language,
        size,
        types: types === null || types === void 0 ? void 0 : types.join(',')
      },
      signal
    });
    return response.data;
  }
  return {
    getSuggestions
  };
}