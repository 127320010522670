import { createApi } from './api';
export function create(_ref) {
  let {
    httpClient,
    config
  } = _ref;
  const api = createApi(httpClient);
  return {
    getAreas: () => {
      return api.getAreas(config.country).then(res => {
        return res.data;
      });
    }
  };
}