export function createApi(httpClient) {
  function getAppointmentsByReference(referenceId, type) {
    return httpClient.post('appointments/search', {
      filter: {
        filters: [{
          property: 'type',
          value: type,
          type: 'eq'
        }, {
          property: 'referenceId',
          value: referenceId,
          type: 'eq'
        }]
      }
    });
  }
  function getLastAppointments(referenceId) {
    return httpClient.post('appointments/last', {
      referenceId: referenceId
    });
  }
  return {
    getAppointmentsByReference,
    getLastAppointments
  };
}