const apiUrlsPerEnv = {
  local: 'http://127.0.0.1:3000/v1',
  qa: 'https://api-marketing-aws-eu-qa-1.auto1-test.com/v1',
  preprod: 'https://api-mcj.wkda.de/v1',
  prod: 'https://api-mcj.wkda.de/v1'
};
const partnerApiUrlsPerEnv = {
  local: 'http://127.0.0.1:3000/v1',
  qa: 'https://api-partner-aws-eu-qa-1.auto1-test.com/v1',
  preprod: 'https://api-partner.auto1.com/v1',
  prod: 'https://api-partner.auto1.com/v1'
};
const apiUrlsPerLocale = {
  'de': {
    local: 'http://127.0.0.1:3000/napi',
    qa: 'https://frontend-qa-1.auto1-test.com/napi',
    preprod: 'https://www.wirkaufendeinauto.de/napi',
    prod: 'https://www.wirkaufendeinauto.de/napi'
  },
  'nl': {
    local: 'http://127.0.0.1:3000/napi',
    qa: 'https://nl-frontend-qa-1.auto1-test.com/napi',
    preprod: 'https://www.wijkopenautos.nl/napi',
    prod: 'https://www.wijkopenautos.nl/napi'
  },
  'es': {
    local: 'http://127.0.0.1:3000/napi',
    qa: 'https://es-frontend-qa-1.auto1-test.com/napi',
    preprod: 'https://www.compramostucoche.es/napi',
    prod: 'https://www.compramostucoche.es/napi'
  },
  'fr': {
    local: 'http://127.0.0.1:3000/napi',
    qa: 'https://fr-frontend-qa-1.auto1-test.com/napi',
    preprod: 'https://www.vendezvotrevoiture.fr/napi',
    prod: 'https://www.vendezvotrevoiture.fr/napi'
  },
  'it': {
    local: 'http://127.0.0.1:3000/napi',
    qa: 'https://it-frontend-qa-1.auto1-test.com/napi',
    preprod: 'https://www.noicompriamoauto.it/napi',
    prod: 'https://www.noicompriamoauto.it/napi'
  },
  'de-AT': {
    local: 'http://127.0.0.1:3000/napi',
    qa: 'https://at-frontend-qa-1.auto1-test.com/napi',
    preprod: 'https://www.wirkaufendeinauto.at/napi',
    prod: 'https://www.wirkaufendeinauto.at/napi'
  },
  'nl-BE': {
    local: 'http://127.0.0.1:3000/napi',
    qa: 'https://nlbe-frontend-qa-1.auto1-test.com/napi',
    preprod: 'https://www.wijkopenautos.be/napi',
    prod: 'https://www.wijkopenautos.be/napi'
  },
  'fr-BE': {
    local: 'http://127.0.0.1:3000/napi',
    qa: 'https://frbe-frontend-qa-1.auto1-test.com/napi',
    preprod: 'https://www.vendezvotrevoiture.be/napi',
    prod: 'https://www.vendezvotrevoiture.be/napi'
  },
  'sv-SE': {
    local: 'http://127.0.0.1:3000/napi',
    qa: 'https://sv-frontend-qa-1.auto1-test.com/napi',
    preprod: 'https://www.vikoperdinbil.se/napi',
    prod: 'https://www.vikoperdinbil.se/napi'
  },
  'pl': {
    local: 'http://127.0.0.1:3000/napi',
    qa: 'https://pl-frontend-qa-1.auto1-test.com/napi',
    preprod: 'https://www.kupimytwojsamochod.pl/napi',
    prod: 'https://www.kupimytwojsamochod.pl/napi'
  },
  'pt': {
    local: 'http://127.0.0.1:3000/napi',
    qa: 'https://pt-frontend-qa-1.auto1-test.com/napi',
    preprod: 'https://www.compramososeucarro.pt/napi',
    prod: 'https://www.compramososeucarro.pt/napi'
  },
  'da': {
    local: 'http://127.0.0.1:3000/napi',
    qa: 'https://da-frontend-qa-1.auto1-test.com/napi',
    preprod: 'https://www.vikoberallebiler.dk/napi',
    prod: 'https://www.vikoberallebiler.dk/napi'
  }
};
const customerApiUrlsPerLocale = {
  'de': {
    local: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    qa: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    preprod: 'https://account.wirkaufendeinauto.de',
    prod: 'https://account.wirkaufendeinauto.de'
  },
  'nl': {
    local: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    qa: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    preprod: 'https://account.wijkopenautos.nl',
    prod: 'https://account.wijkopenautos.nl'
  },
  'es': {
    local: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    qa: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    preprod: 'https://account.compramostucoche.es',
    prod: 'https://account.compramostucoche.es'
  },
  'fr': {
    local: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    qa: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    preprod: 'https://account.vendezvotrevoiture.fr',
    prod: 'https://account.vendezvotrevoiture.fr'
  },
  'it': {
    local: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    qa: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    preprod: 'https://account.noicompriamoauto.it',
    prod: 'https://account.noicompriamoauto.it'
  },
  'de-AT': {
    local: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    qa: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    preprod: 'https://account.wirkaufendeinauto.at',
    prod: 'https://account.wirkaufendeinauto.at'
  },
  'nl-BE': {
    local: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    qa: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    preprod: 'https://account.wijkopenautos.be',
    prod: 'https://account.wijkopenautos.be'
  },
  'fr-BE': {
    local: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    qa: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    preprod: 'https://account.vendezvotrevoiture.be',
    prod: 'https://account.vendezvotrevoiture.be'
  },
  'sv-SE': {
    local: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    qa: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    preprod: 'https://account.vikoperdinbil.se',
    prod: 'https://account.vikoperdinbil.se'
  },
  'pl': {
    local: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    qa: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    preprod: 'https://account.kupimytwojsamochod.pl',
    prod: 'https://account.kupimytwojsamochod.pl'
  },
  'pt': {
    local: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    qa: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    preprod: 'https://account.compramososeucarro.pt',
    prod: 'https://account.compramososeucarro.pt'
  },
  'da': {
    local: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    qa: 'https://api-customer-account-aws-eu-qa-1.auto1-test.com',
    preprod: 'https://account.vikoberallebiler.dk',
    prod: 'https://account.vikoberallebiler.dk'
  }
};
export function getBaseApiUrl(env) {
  let urlMap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : apiUrlsPerEnv;
  return urlMap[env];
}
export function getPartnerBaseApiUrl(env) {
  return partnerApiUrlsPerEnv[env];
}
export function getPartnerBaseNapiUrl(env) {
  const urlMap = {
    local: 'http://localhost:3000/napi',
    qa: 'https://whitelabel-partners-apps-server.1.qa.marketing.auto1.cloud/napi',
    preprod: 'https://partner.wirkaufendeinauto.de/napi',
    prod: 'https://partner.wirkaufendeinauto.de/napi'
  };
  return urlMap[env];
}
export function getBasePapiUrlByLocale(locale, env) {
  var _apiUrlsPerLocale$loc;
  const targetUrl = ((_apiUrlsPerLocale$loc = apiUrlsPerLocale[locale]) !== null && _apiUrlsPerLocale$loc !== void 0 ? _apiUrlsPerLocale$loc : apiUrlsPerLocale.de)[env];
  return targetUrl.replace('napi', '');
}
export function getCustomerBaseApiUrl(locale, env) {
  var _customerApiUrlsPerLo;
  return ((_customerApiUrlsPerLo = customerApiUrlsPerLocale[locale]) !== null && _customerApiUrlsPerLo !== void 0 ? _customerApiUrlsPerLo : customerApiUrlsPerLocale.de)[env];
}
export function getLoggerBaseApiurl(env) {
  return {
    local: 'https://frontend-logger.int.1.qa.marketing.auto1.cloud/v1/log',
    qa: 'https://frontend-logger.int.1.qa.marketing.auto1.cloud/v1/log',
    preprod: 'https://frontend-logger.prod.marketing.auto1.cloud/v1/log',
    prod: 'https://frontend-logger.prod.marketing.auto1.cloud/v1/log'
  }[env];
}
export function getBaseApiUrlFromLocale(locale, env) {
  var _localeMap$locale;
  let localeMap = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : apiUrlsPerLocale;
  const urlsForLocale = (_localeMap$locale = localeMap[locale]) !== null && _localeMap$locale !== void 0 ? _localeMap$locale : localeMap.de;
  return urlsForLocale[env];
}
export function getGoogleClientId(env) {
  return {
    local: '535827184523-16s72ovt5uk68vet6al2u47d18seuel4.apps.googleusercontent.com',
    qa: '535827184523-16s72ovt5uk68vet6al2u47d18seuel4.apps.googleusercontent.com',
    preprod: '535827184523-16s72ovt5uk68vet6al2u47d18seuel4.apps.googleusercontent.com',
    prod: '667260348629-gim0duocedome5b0rhboe8139rors9b8.apps.googleusercontent.com'
  }[env];
}
const urlToShortMap = {
  'wirkaufendeinauto.de': 'wkda.de',
  'wirkaufendeinauto.at': 'wkfda.at',
  'wijkopenautos.be': 'wikoau.be',
  'wijkopenautos.nl': 'wikoau.nl',
  'compramostucoche.es': 'cotuco.es',
  'noicompriamoauto.it': 'nocoau.it',
  'vendezvotrevoiture.fr': 'vevovo.fr',
  'vendezvotrevoiture.be': 'vevovo.be',
  'compramososeucarro.pt': 'cosc.pt',
  'vikoberallebiler.dk': 'vkab.dk',
  'vikoperdinbil.se': 'vkdb.se'
};
export function isShortUrl(url) {
  return Object.values(urlToShortMap).some(shortUrl => url.includes(shortUrl));
}
export function shortenUrl(url) {
  const matchedUrl = Object.keys(urlToShortMap).find(keyUrl => url.includes(keyUrl));
  if (!matchedUrl) {
    return url;
  }
  return url.replace(matchedUrl, urlToShortMap[matchedUrl]);
}