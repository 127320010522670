import type { Request, Response } from 'express';

export const getCarLead = (url: string) => {
  const pattern = /[0-9a-f]{32}/;

  return pattern.test(url) ? pattern.exec(url)?.[0] : undefined;
};

export const getSourceIDByLocale = (locale: string): string | undefined => {
  const sourceIDByLocale: Record<string, string> = {
    de: '1',
    nl: '2',
    it: '3',
    fr: '4',
    es: '5',
    pl: '6',
    'de-at': '7',
    'nl-be': '8',
    'fr-be': '9',
    'sv-se': '11',
    da: '14',
    pt: '23',
  };

  const fullLocale = locale?.toLowerCase()
  const shortLocale = fullLocale?.split('-')[0];

  return sourceIDByLocale[fullLocale] || sourceIDByLocale[shortLocale];
};

export const isValidPageForTracking = (req: Request, res: Response) =>
  req.originalUrl !== '/management/health' &&
  req.method.toUpperCase() === 'GET' &&
  res.get('Content-Type')?.toLowerCase().includes('text/html') &&
  res.statusCode === 200;
