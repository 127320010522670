const IMAGES_API_PATH = '/classifieds-image';
export function createApi(httpClient) {
  function uploadSelfEvaluationImage(_ref) {
    let {
      carleadHash,
      file,
      timeout = 90000
    } = _ref;
    const fd = new FormData();
    fd.append('file', file);
    return httpClient.post(createApiPath("car_hash/" + carleadHash), fd, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      timeout
    }).then(res => res.data);
  }
  return {
    uploadSelfEvaluationImage
  };
}
function createApiPath(path) {
  return IMAGES_API_PATH + "/" + path;
}