export function createApi(httpClient) {
  return {
    searchBranches,
    searchClosestBranches,
    partnerContactPageLanding,
    savePartnerContactInfo,
    getBranchMerchantInfo,
    getBranchProperties
  };
  function searchBranches(_ref) {
    let {
      location,
      hash,
      limit,
      specialAreas,
      ipAddress,
      enableBranchPreselection = true,
      slotAvailabilityMaxDays,
      includeExperimentalBranches = false
    } = _ref;
    const options = ipAddress ? {
      headers: {
        'x-forwarded-for': ipAddress
      }
    } : {};
    return httpClient.post('/consumer/branch-list/search', {
      hash,
      limit,
      location,
      specialAreas,
      excludedBranchIds: [],
      // This field is mandatory, but in our case it's always empty
      enableBranchPreselection,
      slotAvailabilityMaxDays,
      includeExperimentalBranches
    }, options);
  }
  function searchClosestBranches(_ref2) {
    let {
      carleadId,
      countryCode,
      distance,
      limit,
      location
    } = _ref2;
    return httpClient.post('/consumer/branch/closest/search', {
      carleadId,
      countryCode,
      distance,
      limit,
      location
    });
  }
  function partnerContactPageLanding(carleadHash) {
    return httpClient.post("/consumer/branch-network/" + carleadHash + "/landing");
  }
  function savePartnerContactInfo(carleadHash, values) {
    return httpClient.post("/consumer/branch-network/" + carleadHash + "/contact", values);
  }
  function getBranchMerchantInfo(carleadHash) {
    return httpClient.get("/consumer/branch-network/" + carleadHash + "/merchant");
  }
  function getBranchProperties(branchId) {
    return httpClient.get("/branch-properties/branch/" + branchId);
  }
}