import { LoggerService } from '@wkda/logger-core';
import { EcsLogFormatter } from '@wkda/logger-ecs-formatter';
import { CFELoggerModule } from './cfe-logger';
import { CFELoggerProvider } from './cfe-logger-provider';
import { ConsoleLoggerProvider } from './console-logger-provider';
import { CFELoggerAPIService } from './logger-api-service';
export function createLogger(appConfig, axiosConfig) {
  const logFormatter = new EcsLogFormatter({
    serviceName: 'cfe-logger'
  });
  const loggerProvider = appConfig.env === 'local' ? new ConsoleLoggerProvider(logFormatter) : new CFELoggerProvider(logFormatter, new CFELoggerAPIService(appConfig, axiosConfig));
  return new CFELoggerModule(new LoggerService(loggerProvider), appConfig);
}
export { CFELoggerModule };