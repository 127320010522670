import { createApi, getInnerPartialContent, parseContent } from './api';
export function create(_ref) {
  let {
    httpClient
  } = _ref;
  const {
    getPartials,
    getPartial
  } = createApi(httpClient);
  return {
    getPartial,
    getAllPartials: getPartials,
    getTextPartial,
    getParseablePartial,
    getExperiment,
    getSelfEvaOnlinePriceInfo,
    getSelfEvaHowItWOrksInfo,
    getSelfEvaBanners,
    getCookieBanner
  };
  function getSelfEvaHowItWOrksInfo(locale) {
    return getParseablePartial('self-eva-how-it-works', locale);
  }
  function getSelfEvaOnlinePriceInfo(locale) {
    return getParseablePartial('self-eva-online-price-info', locale);
  }
  function getSelfEvaBanners(locale) {
    const partial = getPartial('self-eva-banners', locale).then(getInnerPartialContent).then(content => parseContent(content)).catch(() => {
      console.log("[selfEvaBanner " + locale + " error]");
      return null;
    });
    return partial;
  }
  function getExperiment(locale) {
    let name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'self-eva-experiment-snippet';
    let path = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'google-experiment-snippet';
    let groupId = arguments.length > 3 ? arguments[3] : undefined;
    return getTextPartial(name, path, locale, groupId, true);
  }
  function getTextPartial(name) {
    let path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'content';
    let locale = arguments.length > 2 ? arguments[2] : undefined;
    let groupId = arguments.length > 3 ? arguments[3] : undefined;
    let resolvePartials = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
    return getPartial(name, locale, {
      groupId
    }, resolvePartials).then(res => {
      var _res$path;
      return (_res$path = res[path]) !== null && _res$path !== void 0 ? _res$path : null;
    }).catch(e => {
      console.log("[getTextPartial " + name + " error] " + e.message);
      return null;
    });
  }
  function getParseablePartial(name, locale) {
    let params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return getPartial(name, locale, params).then(getInnerPartialContent).then(res => parseContent(res)).catch(err => {
      var _err$response$status, _err$response;
      console.log(err);
      console.log("Parseable partial " + name + " error: " + ((_err$response$status = err === null || err === void 0 || (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.status) !== null && _err$response$status !== void 0 ? _err$response$status : 'Unknown error'));
      throw err;
    });
  }
  function getCookieBanner(locale, cookieBannerName) {
    return getParseablePartial(cookieBannerName, locale, {
      active: true
    });
  }
}