import _defineProperty from "@babel/runtime/helpers/defineProperty";
export class WhatsappService {
  constructor(eventSourceManager, baseURL) {
    _defineProperty(this, "_eventSourceManager", void 0);
    _defineProperty(this, "_baseURL", void 0);
    this._eventSourceManager = eventSourceManager;
    this._baseURL = baseURL;
  }
  init(carleadHash) {
    this._eventSourceManager.init(this._baseURL + "/self-evaluation-client-notifications/whatsapp-opt-in/" + carleadHash);
  }
  get isInitialized() {
    return this._eventSourceManager.isInitialized;
  }
  _createMessageHandler(onMessage, onError, options) {
    const handler = event => {
      if (options.single) {
        this._eventSourceManager.unsubscribe(handler);
      }
      try {
        const eventData = JSON.parse(event.data);
        onMessage({
          status: eventData.data.status
        });
      } catch (err) {
        onError(err);
      }
    };
    return handler;
  }
  listenOnce() {
    return new Promise((resolve, reject) => {
      this.listen(resolve, reject, {
        single: true
      });
    });
  }
  listen(onMessage, onError) {
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    const handler = this._createMessageHandler(onMessage, onError, options);
    const errorHandler = err => {
      this._eventSourceManager.unsubscribe(handler);
      this._eventSourceManager.removeErrorHandler(errorHandler);
      onError(err);
    };
    this._eventSourceManager.addErrorHandler(errorHandler);
    this._eventSourceManager.subscribe(handler);
    return () => this._eventSourceManager.unsubscribe(handler);
  }
  closeConnection() {
    this._eventSourceManager.close();
  }
}