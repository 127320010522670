import { createApi } from './api';
export function create(_ref) {
  let {
    httpClient
  } = _ref;
  const {
    sendOTP
  } = createApi(httpClient);
  return {
    sendOTP
  };
}