import { parseSafeJSON } from '@wkda/funnel-utils';
export function createMarketingPropertiesObject() {
  let properties = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return properties.reduce((acc, e) => {
    const value = parseSafeJSON(e.value);
    if (e.propertyName) {
      // mutates the acc reference
      createPath(acc, e.propertyName, value);
    }
    return acc;
  }, {});
}
function createPath(obj, path) {
  let value = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  path = typeof path === 'string' ? path.split('.') : path;
  let current = obj;
  while (path.length > 1) {
    const [head, ...tail] = path;
    path = tail;
    if (current[head] === undefined) {
      current[head] = {};
    }
    current = current[head];
  }
  current[path[0]] = value;
  return obj;
}