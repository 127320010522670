export function createApi(httpClient) {
  function getCidData() {
    return httpClient.get('cid-data');
  }
  function createRawCid(_ref) {
    let {
      rawCid,
      sourceId
    } = _ref;
    return httpClient.post("cid-tracking/cid/raw", {
      rawCid,
      sourceId
    });
  }
  function getCIDSessionData(id) {
    return httpClient.get("cid-persist/" + id);
  }
  function setCIDSessionData(id, cid) {
    return httpClient.put("cid-persist/cid", {
      id,
      cid
    });
  }
  return {
    getCidData,
    createRawCid,
    getCIDSessionData,
    setCIDSessionData
  };
}