import isNumber from 'lodash-es/isNumber';
import { createSelector } from 'reselect';
import { DataLayerEventStack } from '@wkda/data-layer';
import { formatDateAsISOString, getTimestamp } from '@wkda/funnel-utils';
import { isSelectionAvailableInTimeSlotSelector } from '../appointment';
import { PROPERTY_IDs } from '../booking-tracking/constants';
import { getCarProperties, getConsumercustomerPhone, hasPriceRangesSelector } from '../consumer';
import { getAppointmentCreationEvent, getDataLayerEvent } from './getAppointmentCreationEvent';
import { getBookingCreateOptions } from './getBookingCreateOptions';
import { getDoubleBookingOptions } from './getDoubleBookingParams';
import { userFullPhoneNumberConcatenatedSelector, userFullPhoneNumberSelector } from '../inspection';
import { getFormattedSubmitRedirectUrl } from './getFormattedSubmitRedirectUrl';
import { CAR_PROPERTIES } from '../consumer/constants';
export function create(external) {
  function submit(params) {
    let promise;
    const isDoubleBookingPage = params.config.doubleBooking && params.currentRoute.includes(params.config.doubleBookingUrl);
    if (params.config.doubleBooking && !isDoubleBookingPage) {
      promise = submitDoubleBooking(params);
    } else {
      promise = submitBooking(params).then(res => ({
        appointment: res,
        isSelectedAppointmentAvailable: true,
        isExperimentalBranch: false
      }));
    }
    return promise.then(_ref => {
      let {
        appointment,
        isSelectedAppointmentAvailable,
        isExperimentalBranch
      } = _ref;
      return onAppointmentCreationSuccess({
        appointment,
        isSelectedAppointmentAvailable,
        isExperimentalBranch,
        isDoubleBookingPage,
        config: params.config,
        branchArea: params.branchArea,
        carCrmData: params.carCrmData
      }).then(_ref2 => {
        let {
          appointmentEvent,
          redirectUrl
        } = _ref2;
        return {
          isSelectedAppointmentAvailable,
          isExperimentalBranch,
          appointment,
          redirectUrl,
          appointmentEvent
        };
      });
    });
  }
  async function submitBooking(_ref3) {
    let {
      config,
      carlead,
      payload
    } = _ref3;
    // Note: Seems like this is not used anymore, latest funnel created with this is 4 years old.
    if (config.enabledBookingTracking) {
      await trackSellingDecision(carlead, payload);
    }
    let options = getBookingCreateOptions({
      config
    });
    const appointment = await external.createBookingAppointment({
      ...payload,
      options
    });
    return appointment;
  }
  async function submitDoubleBooking(_ref4) {
    let {
      config,
      carlead,
      payload,
      currentRoute,
      formValues
    } = _ref4;
    if (config.enabledBookingTracking) {
      await trackSellingDecision(carlead, payload);
    }
    const {
      isSelectedAppointmentAvailable,
      isExperimentalBranch
    } = await handleDoubleBookingCreation({
      payload,
      config,
      carlead,
      currentRoute: currentRoute,
      formValues
    });
    const options = getBookingCreateOptions({
      config,
      isSelectedAppointmentAvailable
    });
    const createdAppointment = await external.createBookingAppointment({
      ...payload,
      options
    });
    return {
      isSelectedAppointmentAvailable,
      isExperimentalBranch,
      appointment: createdAppointment
    };
  }
  async function trackSellingDecision(carlead, payload) {
    const hasPriceRanges = hasPriceRangesSelector(carlead);
    // TODO: It's not clear how we would get 'sellingPoint' field value, thus I am defined it like this for now.
    // @ts-expect-error
    if (hasPriceRanges && payload.sellingPoint) {
      await external.updateMarketingProperty(carlead.hash, {
        propertyId: PROPERTY_IDs.sellingDecision,
        // @ts-expect-error
        value: payload.sellingPoint
      });
    }
  }
  async function trackAppointmentLocation(hash, area) {
    if (area) {
      await external.setAppointmentLocation(hash, area);
    }
  }
  async function handleDoubleBookingCreation(_ref5) {
    let {
      config,
      carlead,
      payload,
      currentRoute,
      formValues
    } = _ref5;
    const dateString = formatDateAsISOString(payload.start).split('T')[0];

    // Construct request options for timeslots req.
    const options = getDoubleBookingOptions(carlead, config, currentRoute, formValues);
    const {
      multiBranchWidget: {
        experimentalBranches
      }
    } = config;
    if (experimentalBranches) {
      const properties = await external.getBranchProperties(payload.branchId);
      if (properties.some(p => p.name === 'experimental-branch' && p.value === 'true')) {
        return {
          isSelectedAppointmentAvailable: false,
          isExperimentalBranch: true
        };
      }
    }

    // For double booking, get latest available time slots.
    const latestAppointmentTimeSlots = await external.getAvailableAppointmentTimes(payload.branchId, payload.hash, options);

    // If config has doubleBooking enabled, and there is no available time slot for selection.
    // Note: It could be changed when user was on the screen, and if it's double booking we refetch latest timeslots when user submits.
    const isSelectedAppointmentAvailable = isSelectionAvailableInTimeSlotSelector(latestAppointmentTimeSlots, dateString, payload.start);
    return {
      isSelectedAppointmentAvailable,
      isExperimentalBranch: false
    };
  }

  /**
   * Do post creatation tracking and return redirect URL.
   */
  async function onAppointmentCreationSuccess(_ref6) {
    let {
      appointment,
      carCrmData,
      config,
      branchArea,
      isSelectedAppointmentAvailable = true,
      isExperimentalBranch = false,
      isDoubleBookingPage = false
    } = _ref6;
    const appointmentEvent = await getAppointmentCreationEvent({
      hash: appointment.hash,
      type: appointment.type,
      getAppointmentsByReference: external.getAppointmentsByReference
    });
    const dataLayerEventStack = new DataLayerEventStack(appointment.hash);
    dataLayerEventStack.update(getDataLayerEvent(appointmentEvent));
    await trackAppointmentLocation(appointment.hash, branchArea);
    await external.trackCidEvent({
      event: appointmentEvent,
      carleadHash: appointment.hash
    });
    if (config.doubleBooking && !isSelectedAppointmentAvailable) {
      await external.updateCarCrmProperty(appointment.hash, {
        bookingEmailPaused: true
      });
      if (isExperimentalBranch) {
        return {
          redirectUrl: getFormattedSubmitRedirectUrl(config.doubleBookingUrl, appointment.hash, config),
          appointmentEvent
        };
      }
      return {
        redirectUrl: getFormattedSubmitRedirectUrl(config.doubleBookingUrl, appointment.hash, config),
        appointmentEvent
      };
    }
    if (carCrmData.bookingEmailPaused) {
      await external.updateCarCrmProperty(appointment.hash, {
        bookingEmailPaused: false
      });
    }
    if (config.doubleBooking) {
      await external.trackEvent({
        action: isDoubleBookingPage ? 'confirmed2' : 'confirmed1',
        hash: appointment.hash,
        referrer: document.URL,
        userAgent: navigator.userAgent,
        category: 'double-booking',
        locale: config === null || config === void 0 ? void 0 : config.locale,
        timestamp: getTimestamp()
      });
    }
    if (config.doubleBooking && isDoubleBookingPage) {
      await external.updateMarketingProperty(appointment.hash, {
        propertyId: PROPERTY_IDs.doubleBooking,
        value: 1
      });
    }
    let {
      bookingConfirmationUrl,
      funnelBaseUrl
    } = config;
    bookingConfirmationUrl = typeof bookingConfirmationUrl === 'string' ?
    // maintain old behavior when bookingConfirmationUrl is a string
    // remove leading and trailing slashes to maintain backward compatibility
    getFormattedSubmitRedirectUrl(bookingConfirmationUrl, appointment.hash, config) : bookingConfirmationUrl;
    if (typeof bookingConfirmationUrl === 'function') {
      bookingConfirmationUrl = bookingConfirmationUrl({
        env: config.env,
        baseUrl: funnelBaseUrl,
        carleadHash: appointment.hash
      });
    }
    return {
      redirectUrl: bookingConfirmationUrl,
      appointmentEvent
    };
  }
  function getAppointmentSearchOptions(_ref7) {
    let {
      config,
      availableSlotsDayOffset
    } = _ref7;
    const {
      virtualSlots,
      bookingSkipMinutes,
      doubleBooking
    } = config;
    const params = {
      virtualSlots
    };
    if (isNumber(bookingSkipMinutes) && bookingSkipMinutes > 0) {
      params.skipMinutes = bookingSkipMinutes;
    }
    if (doubleBooking) {
      params.overbooking = true;
    }
    if (config.forceRealSlots) {
      params.overbooking = false;
    }
    if (availableSlotsDayOffset) {
      params.dayOffset = availableSlotsDayOffset;
    }
    if (config.allowSameDayOverBooking) {
      params.allowSameDayOverBooking = true;
      params.includeOverbookingSlotAfterMinutes = config.includeOverbookingSlotAfterMinutes;
    }
    if (config.dynamicSlot) {
      params.dynamicSlot = config.dynamicSlot;
    }
    return params;
  }
  function getConsumerForBookingPage(carleadHash) {
    return external.getConsumerCarlead(carleadHash).then(consumerCarlead => {
      const mobile = getConsumercustomerPhone(consumerCarlead);
      // eslint-disable-next-line eqeqeq
      if (mobile !== '' && mobile != undefined) {
        return consumerCarlead;
      }
      return external.getSelfEvaluation(carleadHash).then(evaluationCarlead => {
        const mobile = userFullPhoneNumberConcatenatedSelector(evaluationCarlead);
        if (mobile) {
          const splitMobile = userFullPhoneNumberSelector(evaluationCarlead);
          if (consumerCarlead.customer == null) {
            consumerCarlead.customer = {
              mobile,
              firstName: '',
              lastName: '',
              fullName: '',
              postalCode: '',
              mobileCountryCode: splitMobile.phoneCountryCode,
              mobileNumber: splitMobile.phoneNumber
            };
          } else if (consumerCarlead.customer.mobile == null || consumerCarlead.customer.mobile === '') {
            consumerCarlead.customer.mobile = mobile;
            consumerCarlead.customer.mobileCountryCode = splitMobile.phoneCountryCode;
            consumerCarlead.customer.mobileNumber = splitMobile.phoneNumber;
          }
        }
        return consumerCarlead;
      }).catch(() => {
        return consumerCarlead;
      });
    });
  }
  return {
    submit,
    onAppointmentCreationSuccess,
    trackAppointmentLocation,
    trackSellingDecision,
    getBookingCreateOptions,
    getAppointmentSearchOptions,
    getConsumerForBookingPage
  };
}

// #region Selectors
export const isRiskFree = createSelector([getCarProperties], properties => {
  const purchaseTrackProp = properties.find(p => p.propertyId === CAR_PROPERTIES.CAR_PURCHASE_TRACK);
  return purchaseTrackProp && purchaseTrackProp.value === 'risk-free';
});

//#endregion

export { getAvailableSlotsDayOffsetValueFromConfig } from './getAvailableSlotsDayOffsetValueFromConfig';
export { getRedirectUrlForBooking } from './getRedirectUrlForBooking';