export function createApi(httpClient) {
  return {
    sendOTP
  };
  function sendOTP(country, email) {
    return httpClient.post('/customer-account/otp', {
      country,
      email
    }).then(res => res.data);
  }
}