export class CIDData {
  private fields: string[];

  constructor(
    CIDItem: string,
    fieldSeparator: string,
    private parameterSeparator: string,
  ) {
    this.fields = CIDItem.split(fieldSeparator);
  }

  get channel(): string {
    return this.cid.split(this.parameterSeparator)[1] ?? '';
  }

  get cid(): string {
    return this.fields[0] ?? '';
  }

  get timestamp(): number {
    return Number(
      this.parameters.find((param) => param.includes('cl='))?.split('=')[1],
    );
  }

  get daysSinceAttribution(): number {
    const SECOND_IN_MS = 1000;
    const DAY_IN_SECONDS = 86400;

    return Math.floor(
      (Date.now() / SECOND_IN_MS - this.timestamp) / DAY_IN_SECONDS,
    );
  }

  get parameters(): string[] {
    return this.fields[1]?.split(this.parameterSeparator) ?? [];
  }
}
