const MARKETING_ATTRIBUTES_PATH = 'marketing-attribute';
export function createApi(httpClient) {
  return {
    getMarketingAttributes,
    putMarketingAttributes
  };
  function getMarketingAttributes(hash) {
    let referenceType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'carlead';
    return httpClient.get(MARKETING_ATTRIBUTES_PATH + "/" + referenceType + "/" + hash).then(r => r.data);
  }
  function putMarketingAttributes(hash, data) {
    let referenceType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'carlead';
    return httpClient.put(MARKETING_ATTRIBUTES_PATH + "/" + referenceType + "/" + hash, data);
  }
}