export const A1A = 'A1A';
export const AFF = 'AFF';
export const BIN = 'BIN';
export const COP = 'COP';
export const CRM = 'CRM';
export const CSP = 'CSP';
export const DSC = 'DSC';
export const DIR = 'DIR';
export const DIS = 'DIS';
export const DTI = 'DTI';
export const EMA = 'EMA';
export const EMH = 'EMH';
export const EMI = 'EMI';
export const EMM = 'EMM';
export const EMW = 'EMW';
export const FBA = 'FBA';
export const FBR = 'FBR';
export const GAB = 'GAB';
export const GDR = 'GDR';
export const GSP = 'GSP';
export const ICC = 'ICC';
export const LDA = 'LDA';
export const MAN = 'MAN';
export const MAX = 'MAX';
export const NET = 'NET';
export const OCM = 'OCM';
export const OES = 'OES';
export const OFF = 'OFF';
export const OGA = 'OGA';
export const PCM = 'PCM';
export const PRT = 'PRT';
export const RAF = 'RAF';
export const RDD = 'RDD';
export const SEC = 'SEC';
export const SER = 'SER';
export const SES = 'SES';
export const TWA = 'TWA';
export const VOD = 'VOD';
export const YAH = 'YAH';
export const YOR = 'YOR';
export const YOU = 'YOU';
export const ZAN = 'ZAN';