import { RumLogger } from './RumLogger';
import { ServerLogger } from './ServerLogger';
import type { ElasticRumConfig, UserOptions } from './types';

export function createLogger(
  config: ElasticRumConfig,
  userOptions: UserOptions,
) {
  return typeof window === 'undefined'
    ? new ServerLogger()
    : new RumLogger(config, userOptions);
}

export type {
  ElasticApmInstance,
  ElasticRumConfig,
  ElasticTransaction,
  Logger as RumLogger,
  UserOptions,
} from './types';
