import React from 'react';
import { asyncComponent } from '@wkda/after-framework';
import '../../default/styles/main-topbluebg.scss';

const routes = [
  {
    component: asyncComponent({
      loader: () => import('./pages/step1'), // required
      Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
    }),
    aboveTheFoldLoader: async ({ loadRelativeS3File, log }) => {
      try {
        return await loadRelativeS3File('inspection-above-the-fold.css');
      } catch (err) {
        log.error('[AboveTheFold] Failed for inspection page.');
      }
    },
  },
];

export default routes;
