import { createApi } from './api';
export function create(_ref) {
  let {
    httpClient
  } = _ref;
  const {
    getDoubleOptInConfig: _getDoubleOptInConfig
  } = createApi(httpClient);
  function getDoubleOptInConfig(hash) {
    return _getDoubleOptInConfig(hash).then(res => res.data);
  }
  return {
    getDoubleOptInConfig
  };
}