import Cookies from 'universal-cookie';
export function create(_ref, _ref2) {
  let {
    httpClient
  } = _ref;
  let {
    env
  } = _ref2;
  const APIService = {
    post(url, data) {
      return httpClient.post(url, data);
    }
  };
  return {
    APIService,
    cookieService: new Cookies(),
    isProd: ['preprod', 'prod'].includes(env)
  };
}