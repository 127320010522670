export function createApi(httpClient) {
  return {
    getCarCrm,
    update
  };
  function getCarCrm(carleadHash) {
    return httpClient.get("/car-crm/" + carleadHash).then(r => {
      return r.data;
    });
  }
  function update(carleadHash, data) {
    return httpClient.put("/car-crm/" + carleadHash, data).then(r => r.data);
  }
}