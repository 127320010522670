export default memoryStorage();
function memoryStorage() {
  let inMemoryStorage = {};
  return {
    getItem,
    setItem,
    removeItem,
    clear,
    key,
    get length() {
      return length();
    }
  };
  function getItem(key) {
    return inMemoryStorage[key];
  }
  function setItem(key, value) {
    inMemoryStorage[key] = String(value);
  }
  function removeItem(key) {
    delete inMemoryStorage[key];
  }
  function clear() {
    inMemoryStorage = {};
  }
  function key(index) {
    var _Object$keys$index;
    return (_Object$keys$index = Object.keys(inMemoryStorage)[index]) !== null && _Object$keys$index !== void 0 ? _Object$keys$index : null;
  }
  function length() {
    return Object.keys(inMemoryStorage).length;
  }
}