import { createApi } from './api';
export function create(_ref) {
  let {
    httpClient
  } = _ref;
  const {
    getCustomerData,
    createCustomerData
  } = createApi(httpClient);
  return {
    getCustomerData,
    createCustomerData
  };
}