export function createApi(httpClient) {
  return {
    fetchQuestionnaire,
    submitQuestion,
    submitQuestionnaire,
    completeQuestionnaire
  };
  function fetchQuestionnaire(questionnaireId) {
    return httpClient.get(createApiPath("/" + questionnaireId)).then(r => {
      return r.data;
    });
  }
  function submitQuestion(questionnaireId, question) {
    return httpClient.post(createApiPath("/" + questionnaireId + "/question"), {
      question
    }).then(r => {
      return r.data;
    });
  }
  function submitQuestionnaire(questionnaireType) {
    let questions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    return httpClient.post(createApiPath("/"), {
      type: questionnaireType,
      questions
    }).then(r => {
      return r.data;
    });
  }
  function completeQuestionnaire(questionnaireId, data) {
    return httpClient.patch(createApiPath("/" + questionnaireId), data, {
      headers: {
        'Content-Type': 'application/json-patch+json'
      }
    });
  }
}
function createApiPath(path) {
  return "/marketing-questionnaire" + path;
}