import { LocalStorage } from './LocalStorage';
import type { IStorage } from './dataLayer.types';

/**
 * Managing data layer events. Stores values in the external `Storage`
 */

type DataLayerEvent =
  | 'leadCreated'
  | 'bookingCreated'
  | 'rebookingCreated'
  | 'handoverBookingCreated'
  | 'handoverRebookingCreated'
  | 'reevaluationCreated'
  | 'revisit';

export const DataLayerEvents: Record<string, DataLayerEvent> = {
  LEAD_CREATED: 'leadCreated',
  REVISIT: 'revisit',
  BOOKING_CREATED: 'bookingCreated',
  REBOOKING_CREATED: 'rebookingCreated',
  HANDOVER_BOOKING_CREATED: 'handoverBookingCreated',
  HANDOVER_REBOOKING_CREATED: 'handoverRebookingCreated',
  REEVALUATION_CREATED: 'reevaluationCreated',
};

export class DataLayerEventStack {
  static STORAGE_KEY = 'dataLayerEventStack';

  static events = DataLayerEvents;

  private storage: IStorage;

  constructor(
    private hash: string = '',
    storage: IStorage = new LocalStorage(),
  ) {
    this.storage = storage;
  }

  get isEmpty() {
    return !Array.isArray(this.getFullStack()[this.hash]);
  }

  private getStack(hash: string) {
    let stack = this.storage.get<{ [key: string]: string[] }>(
      DataLayerEventStack.STORAGE_KEY,
    );

    return stack && Array.isArray(stack[hash]) ? stack[hash] : [];
  }

  private getFullStack() {
    return (
      this.storage.get<{ [key: string]: string[] }>(
        DataLayerEventStack.STORAGE_KEY,
      ) || {}
    );
  }

  private pushToStack(value: DataLayerEvent) {
    this.storage.set(DataLayerEventStack.STORAGE_KEY, {
      ...this.getFullStack(),
      [this.hash]: [...this.getStack(this.hash), value],
    });
  }

  private setStackValue(value: string[]) {
    this.storage.set(DataLayerEventStack.STORAGE_KEY, {
      ...this.getFullStack(),
      [this.hash]: value,
    });
  }

  init() {
    this.setStackValue([]);
  }

  /**
   * Updates the event stack. Will push `DataLayerEventStack.events.REVISIT` if there is
   * an existing event in stack. Otherwise it will push `DataLayerEventStack.events.LEAD_CREATED`
   * event. `DataLayerEventStack.events.REVISIT` will be pushed only once.
   */
  update(event?: DataLayerEvent) {
    if (event) {
      this.pushToStack(event);
    } else if (!this.peek()) {
      this.pushToStack(DataLayerEventStack.events.LEAD_CREATED);
    } else if (this.peek() === DataLayerEventStack.events.LEAD_CREATED) {
      this.pushToStack(DataLayerEventStack.events.REVISIT);
    }
  }

  /**
   * Returns the top event from stack
   */
  peek() {
    const stack: string[] = this.getStack(this.hash);

    return stack[stack.length - 1];
  }
}
