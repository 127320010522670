import { createCarTypesParamsSerializer, createInjectAPICache } from '../car-types/api';
import { createHttpClient } from './axiosDefaults';
export function createHttpClients(configuration, logger) {
  const {
    baseApiUrl,
    baseNapiUrl
  } = configuration;
  const apiHttpClient = createHttpClient({
    baseURL: baseApiUrl
  }, configuration, logger);
  const napiHttpClient = createHttpClient({
    baseURL: baseNapiUrl
  }, configuration, logger);
  const carTypesHttpClient = createInjectAPICache(createHttpClient({
    baseURL: "" + baseApiUrl,
    paramsSerializer: createCarTypesParamsSerializer(configuration.fullLocale)
  }, configuration, logger));
  const baseNoV1Url = baseApiUrl.replace('/v1', '');
  const noV1ApiHttpClient = createHttpClient({
    baseURL: baseNoV1Url
  }, configuration, logger);
  const sameOriginHttpClient = createHttpClient({
    baseURL: ''
  }, configuration, logger);
  return {
    apiHttpClient,
    napiHttpClient,
    carTypesHttpClient,
    noV1ApiHttpClient,
    sameOriginHttpClient
  };
}