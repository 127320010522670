import { camelizeKeys } from 'humps';
import get from 'lodash-es/get';
import has from 'lodash-es/has';
import pick from 'lodash-es/pick';
import { createSelector } from 'reselect';
import { createApi } from './api';
import { createMarketingPropertiesObject } from './createMarketingPropertiesObject';
export function create(_ref) {
  let {
    httpClient
  } = _ref;
  const {
    getConsumerCarlead: _getConsumerCarlead,
    getConsumerCarleadPrices: _getConsumerCarleadPrices,
    getConsumerCarleadPriced: _getConsumerCarleadPriced,
    getCarleadObfuscation: _getCarleadObfuscation,
    getConsumerSelfEvaluation: _getConsumerSelfEvaluation,
    patchConsumerCarlead: _patchConsumerCarlead,
    createCarlead: _createCarlead,
    createCarleadLicensePlate: _createCarleadLicensePlate,
    createExpectedPrice,
    getAveragePriceCounter
  } = createApi(httpClient);
  return {
    getConsumerCarlead,
    getConsumerCarleadPrices,
    getConsumerCarleadPriced,
    getCarleadObfuscation,
    getConsumerSelfEvaluation,
    updateConsumerCarLeadField,
    createConsumerCarlead,
    createConsumerCarleadLicensePlate,
    createExpectedPrice,
    getAveragePriceCounter
  };
  function getConsumerCarlead(carleadHash) {
    return _getConsumerCarlead(carleadHash).then(res => res.data);
  }
  function getConsumerSelfEvaluation(carleadHash) {
    return _getConsumerSelfEvaluation(carleadHash).then(res => res.data);
  }
  function getConsumerCarleadPrices(carleadHash) {
    return _getConsumerCarleadPrices(carleadHash).then(res => res.data);
  }
  function getConsumerCarleadPriced(carleadHash) {
    return _getConsumerCarleadPriced(carleadHash).then(res => res.status === 200).catch(() => {
      return false;
    });
  }
  function createConsumerCarlead(payload) {
    return _createCarlead(payload).then(res => res.data);
  }
  function createConsumerCarleadLicensePlate(payload) {
    return _createCarleadLicensePlate(payload).then(res => res.data);
  }
  function getCarleadObfuscation(carleadHash) {
    return _getCarleadObfuscation(carleadHash).then(res => res.status === 204).catch(() => {
      // Not obfuscated
      return false;
    });
  }
  function updateConsumerCarLeadField(carleadHash, data) {
    return _patchConsumerCarlead(carleadHash, [{
      ...data,
      op: 'replace'
    }]);
  }
}
// selectors

export function getExperimentVariables() {
  let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return pick(data, ['sourceId', 'questionnaires', 'carProperties', 'carMarketingProperties', 'priceExpired', 'priceExpiresInDays', 'statusId', 'onsiteToExpectedPriceDeltaLocal', 'onsiteToExpectedPriceDeltaLocalPercentage', 'closestBranch']);
}
export function getOnlinePriceData(state) {
  return pick(state, ['canSeePrice', 'price']);
}
export function getMarketingProperties(state) {
  return camelizeKeys(createMarketingPropertiesObject(_getMarketingProperties(state)));
}
export const licensePlateFromMarketingPropertiesSelector = createSelector([getMarketingProperties], properties => get(properties, ['licensePlate', 'plateNumber'], ''));
export const licensePlateFailedFromMarketingPropertiesSelector = createSelector([getMarketingProperties], properties => get(properties, ['licensePlate', 'failedPlateNumber'], ''));
export const licensePlateIdentificationResultSelector = createSelector([getMarketingProperties], properties => get(properties, ['licensePlate', 'vehicleIdentificationResult'], ''));
export const allLicensePlatesFromMarketingPropertiesSelector = createSelector([licensePlateFromMarketingPropertiesSelector, licensePlateFailedFromMarketingPropertiesSelector], (licensePlate, failedLicensePlate) => {
  return licensePlate || failedLicensePlate;
});
export const directBookingSelector = createSelector([getMarketingProperties], properties => get(properties, ['directBooking'], false));
export const marketingExperimentSelector = createSelector([getMarketingProperties], properties => get(properties, ['marketingExperiment'], {}));
export const selfInspectionSelector = createSelector([getMarketingProperties], properties => get(properties, ['selfInspection'], null));
export const subtypeModelDetailsParamsSelector = createSelector([getSubtypeModelDetailsParams, (_, locale) => locale], _transformToSubtypeModelDetailsParams);
export const subtypeExtraParamsSelector = createSelector([getSubtypeExtraParams, (_, locale) => locale], _transformToSubtypeExtraParams);
export const hasPriceRangesSelector = createSelector([getMarketingProperties], properties => {
  return has(properties, 'direct_price_min') && has(properties, 'direct_price_max');
});
export const getCarPropertyByIdSelector = createSelector([getCarProperties, (_, propertyId) => propertyId], (properties, id) => {
  return properties.find(p => p.propertyId === id);
});
export const getCarMarketingPropertyByIdSelector = createSelector([_getMarketingProperties, (_, propertyId) => propertyId], (properties, id) => {
  return properties.find(p => p.propertyId === id);
});
export const getCarSelector = createSelector([_getCar], res => res);
export function getCarProperties(state) {
  return get(state, ['carProperties'], []);
}
export function getComparePriceEntities(state) {
  return get(state, ['entities'], []);
}
export const getComparePriceByType = createSelector([getComparePriceEntities, (_, priceType) => priceType], (entities, priceType) => {
  var _entities$find;
  const price = (_entities$find = entities.find(p => p.type === priceType)) === null || _entities$find === void 0 ? void 0 : _entities$find.amountInMinorUnits;
  return price && price / 100;
});
export function getConsumerCustomer(payload) {
  return payload === null || payload === void 0 ? void 0 : payload.customer;
}
export const getConsumercustomerPhone = createSelector([getConsumerCustomer], customer => {
  if (customer == null) {
    return '';
  }
  return customer.mobile;
});
export function getDataForVehicleName(payload) {
  if (!payload) {
    return undefined;
  }
  const {
    car
  } = payload;
  return {
    manufacturer: car.manufacturer,
    model: car.mainType,
    subModel: car.subtype,
    registrationYear: car.builtYear
  };
}
function getSubtypeModelDetailsParams(state) {
  return _getSubtypeModelDetailsParams(_getCar(state));
}
function getSubtypeExtraParams(state) {
  return _getSubtypeExtraParams(_getCar(state));
}
function _getMarketingProperties(state) {
  return get(state, ['carMarketingProperties'], []);
}
function _getCar(state) {
  return state === null || state === void 0 ? void 0 : state.car;
}
function _getSubtypeModelDetailsParams(state) {
  return pick(state, ['manufacturerCode', 'mainType', 'builtYear', 'bodyType']);
}
function _getSubtypeExtraParams(state) {
  return pick(state, ['manufacturerCode', 'mainType', 'builtYear', 'subtype', 'bodyType', 'mainTypeDetail']);
}
function _transformToSubtypeModelDetailsParams(_ref2, locale) {
  let {
    mainType,
    manufacturerCode,
    builtYear,
    bodyType
  } = _ref2;
  return {
    manufacturer: manufacturerCode,
    mainType: mainType,
    builtDate: builtYear,
    bodyType: bodyType,
    locale
  };
}
function _transformToSubtypeExtraParams(_ref3, locale) {
  let {
    mainType,
    manufacturerCode,
    builtYear,
    subtype,
    bodyType
  } = _ref3;
  return {
    mainType: mainType,
    manufacturer: manufacturerCode,
    builtDate: builtYear,
    mainTypeDetail: subtype,
    bodyType: bodyType,
    locale
  };
}