import drop from 'lodash-es/drop';
import toNumber from 'lodash-es/toNumber';
import mapKeys from 'lodash-es/mapKeys';
import has from 'lodash-es/has';
import get from 'lodash-es/get';
import { isCidValid } from './validator';
import { dropRight } from 'lodash-es';

// TODO: Move everything related CID cookie to the napi server

const ITEM_SEPARATOR = '||';
const PARAMETER_SEPARATOR = '_';
const FIELD_SEPARATOR = '&';
const QUEUE_LIMIT = 15;
const TIMESTAMP_FIELD_KEY = 'cl';
const COOKIE_TIME_LIMIT_IN_SECONDS = 3600; // 1 hour
const ONE_SECOND_IN_MS = 1000;
const PARAMETER_ALIASES = {
  utm_expid: 'expid'
};
const CID_LOCALE_MAPPING = {
  'fr-BE': 'B2',
  'nl-BE': 'B1',
  'de-AT': 'AT',
  'da': 'DK',
  'sv-SE': 'SE'
};
const paramsRegex = /_?([^=]+=[^=]+)(?:_|$)/g;
function getCIDPrefixFromLocale(locale) {
  var _CID_LOCALE_MAPPING$l;
  return (_CID_LOCALE_MAPPING$l = CID_LOCALE_MAPPING[locale]) !== null && _CID_LOCALE_MAPPING$l !== void 0 ? _CID_LOCALE_MAPPING$l : locale.toUpperCase();
}
function getMainPartToSet(_ref) {
  let {
    MID,
    seoCmsCid
  } = _ref;
  const lastMID = Array.isArray(MID) ? MID[MID.length - 1] : MID;
  if (isCidValid(lastMID)) return lastMID;
  if (isCidValid(seoCmsCid)) return seoCmsCid;
  return undefined;
}
export function setInspectionSEOCookie(_ref2) {
  let {
    currentSEOCookie,
    locale,
    trackingParams = {},
    MID,
    seoCmsCid
  } = _ref2;
  const queue = initQueue(currentSEOCookie);
  const mainPart = getMainPartToSet({
    MID,
    seoCmsCid
  });
  const newQueue = appendToQueue({
    locale,
    queue,
    mainPart,
    trackingParams
  });
  return stringifyQueue(newQueue);
}
function getCurrentTimeInSeconds() {
  return Math.round(Date.now() / ONE_SECOND_IN_MS);
}
function getCookieTimestamp(_ref3) {
  let {
    createdOn
  } = _ref3;
  return toNumber(createdOn);
}
const isLastCookieExpired = timestamp => getCurrentTimeInSeconds() - timestamp > COOKIE_TIME_LIMIT_IN_SECONDS;
function initQueue() {
  let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  const list = value ? value.split(ITEM_SEPARATOR) : [];
  return list.map(item => {
    const fields = item.split(FIELD_SEPARATOR);
    const [mainPart, rawParameters = ''] = fields;
    const matchedParams = [...rawParameters.matchAll(paramsRegex)].map(match => match[1]);
    const {
      cl,
      ...parameters
    } = Object.fromEntries(matchedParams.map(param => param.split('=')));
    return {
      mainPart,
      parameters,
      createdOn: cl
    };
  });
}
function canAppendValueToQueue(value, queue) {
  if (queue.length === 0) return true;
  const lastValue = queue[queue.length - 1];
  const timestamp = getCookieTimestamp(lastValue);

  // If we want to add the default CID, always check for expiration
  if (value === undefined) return isLastCookieExpired(timestamp);
  return lastValue.mainPart === value ? isLastCookieExpired(timestamp) : true;
}
export function injectExpId() {
  let queue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let expid = arguments.length > 1 ? arguments[1] : undefined;
  const lastValue = queue[queue.length - 1];

  // if expid is present than update last segment in queue
  if (lastValue && expid) {
    const newQueue = dropRight(queue, 1).concat({
      ...lastValue,
      parameters: {
        ...(lastValue.parameters || {}),
        expid
      }
    });
    return newQueue;
  }
  return queue;
}
function appendToQueue(_ref4) {
  let {
    locale,
    mainPart,
    queue,
    trackingParams
  } = _ref4;
  trackingParams = aliasParameters(trackingParams);
  const DEFAULT_CID = getCIDPrefixFromLocale(locale) + "_DTI_0_0_0_0_0_0";
  if (!canAppendValueToQueue(mainPart, queue)) {
    return injectExpId(queue, trackingParams.expid);
  }
  const newQueue = queue.concat({
    mainPart: mainPart !== null && mainPart !== void 0 ? mainPart : DEFAULT_CID,
    parameters: trackingParams,
    createdOn: "" + getCurrentTimeInSeconds()
  });
  return newQueue.length > QUEUE_LIMIT ? drop(newQueue, 1) : newQueue;
}
function aliasParameters(params) {
  return mapKeys(params, (_, key) => {
    return has(PARAMETER_ALIASES, [key]) ? get(PARAMETER_ALIASES, [key]) : key;
  });
}
function queryToCookieParams(_ref5) {
  let {
    parameters,
    createdOn
  } = _ref5;
  // the timestamp field always goes last, and always starts with "_", even if it's the only parameter
  const timestamp = "_" + TIMESTAMP_FIELD_KEY + "=" + createdOn;
  return Object.entries(parameters).filter(_ref6 => {
    let [_key, value] = _ref6;
    return value != null;
  }).map(_ref7 => {
    let [key, value] = _ref7;
    if (Array.isArray(value)) {
      return value.map(v => key + "=" + v).join(PARAMETER_SEPARATOR);
    }
    return key + "=" + value;
  }).join(PARAMETER_SEPARATOR) + timestamp;
}
function stringifyQueue(queue) {
  return queue.map(cidItem => [cidItem.mainPart, queryToCookieParams(cidItem)].join(FIELD_SEPARATOR)).join(ITEM_SEPARATOR);
}