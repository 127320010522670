export const AT = 'AT';
export const B1 = 'B1';
export const B2 = 'B2';
export const DE = 'DE';
export const ES = 'ES';
export const FR = 'FR';
export const IT = 'IT';
export const NL = 'NL';
export const PL = 'PL';
export const SE = 'SE';
export const DK = 'DK';
export const PT = 'PT';