import type { CID } from './dataLayer.types';

interface CarLeadData {
  hash: string;
  stockNumber: string;
  createdOn?: string;
}

interface EventLeadData {
  cidChannels: CID[];
  daysSinceFirstVisit: number;
  hash: string;
  stockNumber: string;
  unique: boolean;
  daysSinceLeadCreated?: number;
}

function getDaysSinceFirstVisit(CIDChannels: CID[]) {
  const SEC_IN_DAY = 86400;
  const MS_IN_SEC = 1000;

  return Math.floor(
    (Date.now() / MS_IN_SEC - getEarliestCIDTimestamp(CIDChannels)) /
      SEC_IN_DAY,
  );
}

const getEarliestCIDTimestamp = (CIDChannels: CID[]) => {
  return CIDChannels[0] && CIDChannels[0].timestamp
    ? CIDChannels[0].timestamp
    : 0;
};

const getDaysSinceCreated = (createdOn: string) =>
  Math.floor(
    (Date.now() - new Date(createdOn).getTime()) / (1000 * 60 * 60 * 24),
  );

export function createEvent(
  name: string,
  CIDChannels: CID[],
  carLeadData: CarLeadData,
  isUnique: boolean,
) {
  const leadData: EventLeadData = {
    cidChannels: CIDChannels,
    daysSinceFirstVisit: getDaysSinceFirstVisit(CIDChannels),
    hash: carLeadData.hash,
    stockNumber: carLeadData.stockNumber,
    unique: isUnique,
  };
  if (carLeadData.createdOn) {
    leadData.daysSinceLeadCreated = getDaysSinceCreated(carLeadData.createdOn);
  }

  return {
    event: name,
    isDirectBooking: false,
    leadData,
  };
}
