export function createApi(httpClient, config) {
  const {
    locale,
    country
  } = config;
  const MAPPING_URL = ExtraMappingUrlsByLocale[locale];
  return {
    getMappingWithLicensePlate,
    searchCarDataMapping
  };
  function getMappingWithLicensePlate(licensePlate) {
    if (!MAPPING_URL) {
      return Promise.reject(new Error("Attempting to get license plate mapping with invalid locale " + locale));
    }
    return httpClient.post(MAPPING_URL, {
      licensePlate
    }).then(r => {
      return r.data;
    });
  }
  function searchCarDataMapping(licensePlate) {
    return httpClient.post("/license-plate/mapping", {
      licensePlate,
      country
    }).then(r => {
      return r.data;
    });
  }
}
const ExtraMappingUrlsByLocale = {
  'nl': '/license-plate/nl/mapping',
  'sv-SE': '/sweden-license-plate/extra-mapping'
};