import { createApi } from './api';
import { LEAD_SOURCE_IDs, PROPERTY_IDs } from './constants';
export function create(_ref,
//TODO: unify logger interface (use different loggers on client and ssr)
logger, apmLogger) {
  let {
    httpClient
  } = _ref;
  const api = createApi(httpClient);
  function trackFieldValue(hash, field, value) {
    if (Object.keys(PROPERTY_IDs).includes(field)) {
      return api.trackFieldValue(hash, {
        propertyId: PROPERTY_IDs[field],
        value
      });
    }
    return Promise.reject(new Error("Unable to track booking field " + field));
  }
  function trackEvent(params) {
    return api.trackEvent({
      source: LEAD_SOURCE_IDs[params.locale],
      ...params
    }).catch(error => {
      const message = 'Tracking event cannot not be sent';
      apmLogger.error(error);
      if (logger !== null && logger !== void 0 && logger.error) {
        logger.error({
          error,
          message,
          meta: {
            errorType: 'tracking-error'
          }
        });
      } else {
        console.error(message);
        console.warn('booking-tracking module is missing `logger` dependency');
      }
    });
  }
  return {
    trackFieldValue,
    trackEvent
  };
}