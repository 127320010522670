import get from 'lodash-es/get';
import { createSelector } from 'reselect';
import { VinResolutionState } from '../vin-resolution-state';
import { createApi } from './api';
import { isLPIdentificationError } from './inspection-conflicts';
import { identificationStates, isVINVerificationError, maybeIdentifyVehicle } from './vehicleIdentification';
export const CONFLICT_ERROR = 409;
export const NOT_FOUND_ERROR = 404;
export function create(_ref, external) {
  let {
    httpClient
  } = _ref;
  const completeSelfEva = createSelfEvaCompleteAction(updateCarLeadData);
  const {
    getSelfEvaluation,
    getSelfEvaluationPreview,
    postReevaluation,
    createSelfEvaluation,
    updateEvaluationStep,
    updateACIEvaluationStep,
    patchSelfEvaluation,
    updateSelfEvaluation,
    finalizeSelfEvaluation,
    removeSelfEvaluationImage,
    updateSelfEvaluationImage,
    identifyVehicleByLicensePlate,
    savePartialSelfEvaluation,
    updateSelfEvaluationProperties,
    updateSelfEvaluationCarleadBranch,
    getReevaluationApplicability,
    getVinVerificationOptions,
    getAutopricingElegibility,
    updateSelfEvaluationVin,
    createEssentialSelfEvaluation,
    createSelfEvaluationAssumption,
    createAciAuction,
    createSelfEvaluationDamage,
    deleteSelfEvaluationDamage,
    createACIRedirect,
    getSelfEvaluationDamage,
    updateSelfEvaluationDamage,
    deleteSelfEvaluationDamageImage,
    getVinUpdateRequest,
    postVinUpdateRequest
  } = createApi(httpClient);
  return {
    patchSelfEvaluation,
    createSelfEvaluationCarlead,
    getSelfEvaluation,
    getSelfEvaluationPreview,
    getOrCreateSelfEvaluation,
    updateCarLeadData,
    completeSelfEva,
    updateAndIdentifyCarLeadData,
    createUpdateAndIdentifyOnStep,
    createSelfEvaCompleteAction,
    dynamicSubmitAndIdentifyAction,
    uploadImages,
    updateImage,
    removeImage,
    rotateImage,
    postReevaluation,
    savePartialSelfEvaluation,
    updateSelfEvaluationProperties,
    updateSelfEvaluationCarleadBranch,
    updateSelfEvaluation,
    updateEvaluationStep,
    updateACIEvaluationStep,
    finalizeSelfEvaluation,
    trackCIDCompleteEvent,
    getReevaluationApplicability: _getReevaluationApplicability,
    identifyVehicleByLicensePlate,
    getVinVerificationOptions,
    getAutopricingElegibility,
    updateSelfEvaluationVin,
    createEssentialSelfEvaluation,
    createSelfEvaluationAssumption: _createSelfEvaluationAssumption,
    createAciAuction,
    createSelfEvaluationDamage,
    deleteSelfEvaluationDamage,
    createACIRedirect,
    getSelfEvaluationDamage,
    updateSelfEvaluationDamage,
    deleteSelfEvaluationDamageImage,
    identifyVehicleAndGetStatus,
    getVinUpdateRequest,
    postVinUpdateRequest
  };
  function createSelfEvaluationCarlead(consumerCarlead) {
    var _consumerCarlead$carM;
    const carleadHash = consumerCarlead.hash;
    const prefilledVinProperty = ((_consumerCarlead$carM = consumerCarlead === null || consumerCarlead === void 0 ? void 0 : consumerCarlead.carMarketingProperties) !== null && _consumerCarlead$carM !== void 0 ? _consumerCarlead$carM : []).find(property => property.propertyId === external.marketingPropertyIds.carleadVinInput);
    const params = {
      flow: 'self-evaluation',
      ...(prefilledVinProperty ? {
        vin: prefilledVinProperty.value
      } : undefined)
    };
    return createSelfEvaluation(carleadHash, params).then(function response(res) {
      return updateEvaluationStep(carleadHash, 0).then(() => res);
    });
  }
  function getOrCreateSelfEvaluation(consumerCarlead, selfEvaluationProperties) {
    const carleadHash = consumerCarlead.hash;
    return getSelfEvaluation(carleadHash).catch(function onGetSelfEvaluationError(error) {
      if (error.response.status === 404) {
        return createSelfEvaluationCarlead(consumerCarlead);
      }
      throw error;
    }).then(function updateProperties(selfEvaluationCarlead) {
      const evaluationProperties = createSelfEvaluationPropertiesPayload(selfEvaluationProperties, selfEvaluationCarlead.evaluationProperties);
      return shouldUpdateEvaluationProperties(selfEvaluationCarlead) ? updateSelfEvaluationProperties(carleadHash, evaluationProperties).then(function onUpdateProperties() {
        return {
          ...selfEvaluationCarlead,
          evaluationProperties: evaluationProperties.values
        };
      }).catch(() => {
        return selfEvaluationCarlead;
      }) : Promise.resolve(selfEvaluationCarlead);
    });
  }
  function updateCarLeadData(_ref2) {
    let {
      request,
      step,
      carleadHash
    } = _ref2;
    return updateSelfEvaluation(carleadHash, request).then(newCarlead => {
      return updateEvaluationStep(carleadHash, step).then(() => {
        return newCarlead.data;
      });
    });
  }
  function createUpdateAndIdentifyOnStep(identifyOnStep, vinResolutionState) {
    return function _updateAndIdentifyCarLeadData(_ref3) {
      let {
        request,
        step,
        carleadHash,
        isLastStep
      } = _ref3;
      return updateAndIdentifyCarLeadData({
        request,
        step,
        carleadHash,
        identifyOnStep,
        isLastStep,
        vinResolutionState
      });
    };
  }
  function updateAndIdentifyCarLeadData(_ref4) {
    let {
      request,
      step,
      carleadHash,
      identifyOnStep,
      isLastStep,
      vinResolutionState = new VinResolutionState(carleadHash)
    } = _ref4;
    const {
      licensePlate
    } = request.vehicle;
    const resolutionState = vinResolutionState.isResolutionFailed ? 'IDENTIFICATION_FAILED' : '';
    return updateSelfEvaluation(carleadHash, request).then(carleadResponse => {
      return maybeIdentifyVehicle(resolutionState, carleadHash, licensePlate, step, identifyOnStep, identifyVehicleByLicensePlate).catch(err => {
        var _err$response, _err$response2;
        if (isLPIdentificationError({
          result: err === null || err === void 0 || (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.data,
          statusCode: err === null || err === void 0 || (_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : _err$response2.status
        })) {
          vinResolutionState.update(true);
        }
        if (isLastStep) {
          throw err;
        } else {
          return carleadResponse;
        }
      }).then(vehicleIdentified => {
        return vehicleIdentified === true ? getSelfEvaluation(carleadHash) : carleadResponse.data;
      }).then(carlead => {
        return updateEvaluationStep(carleadHash, step).then(() => {
          return carlead;
        });
      });
    });
  }
  function createSelfEvaCompleteAction(saveCarleadAction) {
    return function completeSelfEva(_ref5) {
      let {
        request,
        carleadHash,
        step = 3,
        finalizeData = {},
        isLastStep,
        dlp,
        funnelType
      } = _ref5;
      return saveCarleadAction({
        request,
        carleadHash,
        step,
        isLastStep
      }).then(function track(newCarlead) {
        return trackCIDCompleteEvent(carleadHash).then(() => {
          return newCarlead;
        });
      }).then(function finalize(newCarlead) {
        return finalizeSelfEvaluation(carleadHash, finalizeData).then(() => {
          return updateSelfEvaluationPropertiesCallback({
            carleadHash,
            dlp,
            funnelType,
            selfEvaluationPayload: newCarlead
          });
        }).then(() => {
          return newCarlead;
        });
      });
    };
  }
  function dynamicSubmitAndIdentifyAction(_ref6) {
    let {
      request,
      carleadHash,
      step,
      identifyOnStep,
      finalizeData,
      isLastStep,
      previousIdentificationState,
      dlp,
      funnelType
    } = _ref6;
    const {
      licensePlate
    } = request.vehicle;
    return updateCarLeadData({
      request: request,
      step: step,
      carleadHash: carleadHash
    }).then(carlead => {
      return identifyVehicleAndGetStatus({
        carleadHash,
        licensePlate,
        step,
        identifyOnStep,
        previousIdentificationState
      }).then(state => {
        const response = {
          ...carlead,
          identificationStatus: state
        };
        return response;
      });
    }).then(newCarlead => {
      const iState = newCarlead.identificationStatus;
      if (isLastStep && (iState === 'IDENTIFICATION_SUCCESS' || iState === 'PREVIOUS_IDENTIFICATION_ERROR' || iState === 'PREVIOUS_IDENTIFICATION_SUCCESS')) {
        return trackCIDCompleteEvent(carleadHash).then(() => {
          return finalizeSelfEvaluation(carleadHash, finalizeData).then(() => {
            return updateSelfEvaluationPropertiesCallback({
              carleadHash,
              dlp,
              funnelType,
              selfEvaluationPayload: newCarlead
            });
          }).then(() => newCarlead);
        });
      }
      return newCarlead;
    });
  }
  function identifyVehicleAndGetStatus(_ref7) {
    let {
      carleadHash,
      licensePlate,
      step,
      identifyOnStep,
      previousIdentificationState
    } = _ref7;
    if (previousIdentificationState) {
      const state = previousIdentificationState === 'success' ? identificationStates.previousSuccess : identificationStates.previousFailed;
      return Promise.resolve(state);
    }
    if (licensePlate && identifyOnStep === step) {
      return identifyVehicleAndSaveMarketingProp(carleadHash, licensePlate).then(r => r.value === 'success' ? identificationStates.success : identificationStates.failed);
    }
    if (!licensePlate && identifyOnStep === step) {
      return setVehicleIdentificationResult('failure', carleadHash).then(() => {
        return identificationStates.failed;
      });
    }
    return Promise.resolve(identificationStates.skipped);
  }
  function identifyVehicleAndSaveMarketingProp(carleadHash, licensePlate) {
    return identifyVehicleByLicensePlate(carleadHash, licensePlate).then(() => {
      return setVehicleIdentificationResult('success', carleadHash);
    }).catch(e => {
      if (isVINVerificationError(e)) {
        return setVehicleIdentificationResult('failure', carleadHash);
      }
      throw e;
    });
  }
  function setVehicleIdentificationResult(value, carleadHash) {
    return external.updateMarketingProperty(carleadHash, {
      propertyId: 8603,
      value
    });
  }
  function updateSelfEvaluationPropertiesCallback(_ref8) {
    let {
      carleadHash,
      dlp,
      funnelType,
      selfEvaluationPayload
    } = _ref8;
    return updateSelfEvaluationProperties(carleadHash, createSelfEvaluationPropertiesPayload({
      submit_dlp: dlp,
      submit_funnel_type: funnelType
    }, selfEvaluationPayload.evaluationProperties)).catch(() => {
      console.log('[PUT selfEvaluationProperties] failed');
    });
  }
  function trackCIDCompleteEvent(carleadHash) {
    return external.trackCidEvent({
      carleadHash,
      event: 'SELFEVALUATIONCOMPLETED'
    });
  }
  function uploadImages(carleadHash, images, fieldName) {
    return external.uploadClassifiedImages(carleadHash, images).then(res => {
      return updateImage(carleadHash, res, fieldName);
    });
  }
  function updateImage(carleadHash, images, fieldName) {
    const tasks = [images].flat().map(image => {
      return updateSelfEvaluationImage(carleadHash, {
        ...image,
        type: getImageType(fieldName)
      }).then(() => {
        return image;
      });
    });
    return Promise.all(tasks);
  }
  function removeImage(carleadHash, id) {
    return removeSelfEvaluationImage(carleadHash, id).then(res => res.data);
  }
  function rotateImage(carleadHash, image, prevImageId, fieldName) {
    return uploadImages(carleadHash, image, fieldName).then(function uploadImagesSucess(_ref9) {
      let [img] = _ref9;
      return removeImage(carleadHash, prevImageId).then(() => {
        return img;
      });
    });
  }
  function _getReevaluationApplicability(carleadHash, params) {
    return getReevaluationApplicability(carleadHash, params).then(res => res.data).catch(error => {
      if (error.response.status === 404) {
        return {
          isReevaluationApplicable: false
        };
      }
      throw error;
    });
  }
  function _createSelfEvaluationAssumption(carleadHash) {
    return createSelfEvaluationAssumption(carleadHash).then(() => {
      return finalizeSelfEvaluation(carleadHash, {});
    });
  }
}
function shouldUpdateEvaluationProperties(payload) {
  const evaluationProperties = payload.evaluationProperties;
  return evaluationProperties == null || evaluationProperties['frontend.dlp'] == null || evaluationProperties['frontend.funnel_type'] == null;
}
function createSelfEvaluationPropertiesPayload(_properties, currentCarleadProperties) {
  const propertyPrefix = 'frontend';
  // create the properties for this new funnel
  let properties = {};
  for (const [key, value] of Object.entries(_properties)) {
    const propertyKey = propertyPrefix + "." + key;
    // eslint-disable-next-line eqeqeq
    if (value != undefined) {
      properties[propertyKey] = value;
    }
  }

  // now merge with carlead payload properties
  properties = {
    ...properties,
    ...currentCarleadProperties
  };
  return {
    values: properties
  };
}

//Selectors

export function userSelector(state) {
  var _state$user;
  return (_state$user = state === null || state === void 0 ? void 0 : state.user) !== null && _state$user !== void 0 ? _state$user : null;
}
export const userFullPhoneNumberSelector = createSelector([userSelector], user => {
  var _user$phoneNumber, _user$phoneCountryCod;
  return {
    phoneNumber: (_user$phoneNumber = user === null || user === void 0 ? void 0 : user.phoneNumber) !== null && _user$phoneNumber !== void 0 ? _user$phoneNumber : '',
    phoneCountryCode: (_user$phoneCountryCod = user === null || user === void 0 ? void 0 : user.phoneCountryCode) !== null && _user$phoneCountryCod !== void 0 ? _user$phoneCountryCod : ''
  };
});
export const useHasPhoneNumberSelector = createSelector([userFullPhoneNumberSelector], _ref10 => {
  let {
    phoneNumber,
    phoneCountryCode
  } = _ref10;
  return phoneCountryCode !== '' && phoneNumber !== '';
});
export const userFullPhoneNumberConcatenatedSelector = createSelector([useHasPhoneNumberSelector, userFullPhoneNumberSelector], (hasPhoneNumber, _ref11) => {
  let {
    phoneNumber,
    phoneCountryCode
  } = _ref11;
  return hasPhoneNumber ? "" + phoneCountryCode + phoneNumber : '';
});
function getFieldByName(fieldName) {
  const fieldsMap = {
    vehicleOutsideImages: 'images',
    damagesImages: 'issuesImages',
    additionalImages: 'extraImages',
    paperImages: 'papersImages',
    // ACI
    photosOfExtras: 'aciPhotosOfExtras',
    registrationCertificate: 'aciRegistrationCertificate',
    serviceBooklet: 'aciServiceBooklet',
    conditionsDamages: 'aciConditionsDamages',
    opFrontLeft: 'aciOpFrontLeft',
    opOverviewPhotoDriverSide: 'aciOpDriverSide',
    opRear: 'aciOpRear',
    opPassengerSide: 'aciOpPassengerSide',
    opDriverSideFrontWheel: 'aciOpDriverSideFrontWheel',
    opDriverSideFrontTire: 'aciOpDriverSideFrontTire',
    opDriverSideRearWheel: 'aciOpDriverSideRearWheel',
    opDriverSideRearTire: 'aciOpDriverSideRearTire',
    opPassengerSideFrontWheel: 'aciOpPassengerSideFrontWheel',
    opPassengerSideFrontTire: 'aciOpPassengerSideFrontTire',
    opPassengerSideRearWheel: 'aciOpPassengerSideRearWheel',
    opPassengerSideRearTire: 'aciOpPassengerSideRearTire',
    opDashboard: 'aciOpDashboard',
    opSpeedoMeter: 'aciOpSpeedoMeter',
    opOpenTrunk: 'aciOpOpenTrunk',
    opOpenHood: 'aciOpOpenHood',
    bidAccepted: 'aciBidAccepted'
  };
  return fieldsMap[fieldName] || fieldName;
}
export function getFieldPath(fieldName) {
  return "/" + getFieldByName(fieldName);
}
export function getImageType(fieldName) {
  const typesMap = {
    images: 'regular',
    seatsImages: 'interior',
    dashboardImages: 'dashboard',
    papersImages: 'papers',
    issuesImages: 'damages',
    extraImages: 'others',
    // ACI
    aciPhotosOfExtras: 'aci_photos_of_extras',
    aciRegistrationCertificate: 'aci_registration_certificate',
    aciServiceBooklet: 'aci_service_booklet',
    aciConditionsDamages: 'aci_conditions_damages',
    aciOpFrontLeft: 'aci_op_front_left',
    aciOpDriverSide: 'aci_op_overview_photo_driver_side',
    aciOpRear: 'aci_op_rear',
    aciOpPassengerSide: 'aci_op_passenger_side',
    aciOpDriverSideFrontWheel: 'aci_op_driver_side_front_wheel',
    aciOpDriverSideFrontTire: 'aci_op_driver_side_front_tire',
    aciOpDriverSideRearWheel: 'aci_op_driver_side_rear_wheel',
    aciOpDriverSideRearTire: 'aci_op_driver_side_rear_tire',
    aciOpPassengerSideFrontWheel: 'aci_op_passenger_side_front_wheel',
    aciOpPassengerSideFrontTire: 'aci_op_passenger_side_front_tire',
    aciOpPassengerSideRearWheel: 'aci_op_passenger_side_rear_wheel',
    aciOpPassengerSideRearTire: 'aci_op_passenger_side_rear_tire',
    aciOpDashboard: 'aci_op_dashboard',
    aciOpSpeedoMeter: 'aci_op_speedo_meter',
    aciOpOpenTrunk: 'aci_op_open_trunk',
    aciOpOpenHood: 'aci_op_open_hood',
    aciBidAccepted: 'aci_bid_accepted'
  };
  return typesMap[getFieldByName(fieldName)];
}
export const getVehicleName = createSelector([data => data === null || data === void 0 ? void 0 : data.vehicle], vehicle => {
  if (!vehicle) return '';
  const {
    make,
    model,
    subModel,
    registrationYear
  } = vehicle;
  return make + " " + model + (subModel ? " " + subModel : '') + ", " + registrationYear;
});
export function getDataForVehicleName(payload) {
  if (!payload) {
    return undefined;
  }
  const {
    make,
    model,
    subModel,
    registrationYear
  } = payload.vehicle;
  return {
    manufacturer: make,
    model,
    subModel,
    registrationYear
  };
}
export function datEcodeSelector(state) {
  return get(state, ['vehicle', 'datEcode'], '');
}
export const userEmailSelector = createSelector([userSelector], user => {
  var _user$email;
  return (_user$email = user === null || user === void 0 ? void 0 : user.email) !== null && _user$email !== void 0 ? _user$email : '';
});
export const imTypeSelector = state => get(state, 'imType', null);