import { RUM_CDN_URL } from './constants';
import type {
  ElasticApmInstance,
  ElasticRumConfig,
  UserOptions,
} from './types';
import prepareRumConfig from './utils/prepareRumConfig';

declare const elasticApm: ElasticApmInstance;

export default function loadRumAsync(
  config: ElasticRumConfig,
  { onLoad }: UserOptions,
) {
  return new Promise<ElasticApmInstance>((resolve) => {
    const j = document.createElement('script');
    const t = document.getElementsByTagName('script')[0];
    j.src = RUM_CDN_URL;
    j.onload = function () {
      elasticApm.init(prepareRumConfig(config));
      resolve(elasticApm);
      onLoad && onLoad();
    };
    t.parentNode?.insertBefore(j, t);
  });
}
