import { createApi } from './api';
export function create(_ref) {
  let {
    httpClient
  } = _ref;
  const {
    getManufacturers,
    getMainTypes,
    getBuiltDates,
    getBodyTypes,
    getFuelTypes,
    getHorsePower,
    getGearTypes,
    getDoorCount,
    getMainTypesDetails,
    getMainTypesSub,
    getSubtypeExtra
  } = createApi(httpClient);
  return {
    getManufacturers,
    getMainTypes,
    getBuiltDates,
    getBodyTypes,
    getFuelTypes,
    getHorsePower,
    getGearTypes,
    getDoorCount,
    getMainTypesDetails,
    getMainTypesSub,
    getSubtypeExtra
  };
}