export function create(_ref) {
  let {
    createConsumerCarlead,
    trackCidEvent
  } = _ref;
  return {
    createCarlead: payload => {
      return createConsumerCarlead(payload).then(res => {
        return trackCidEvent({
          carleadHash: res.hash,
          event: 'LEAD'
        }).then(() => {
          return res;
        }).catch(() => {
          console.log("[CID LEAD creation error]");
          return res;
        });
      });
    }
  };
}