export function isAxiosError(payload) {
  return payload !== null && typeof payload === 'object' && payload.isAxiosError === true;
}
export function isConflictError(error) {
  if (isAxiosError(error)) {
    var _error$response;
    return ((_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status) === 409;
  }
  return false;
}
export function isFieldError(error) {
  var _error$response2, _error$response3;
  return isAxiosError(error) &&
  // eslint-disable-next-line eqeqeq
  ((_error$response2 = error.response) === null || _error$response2 === void 0 || (_error$response2 = _error$response2.data) === null || _error$response2 === void 0 ? void 0 : _error$response2.globalErrors) != undefined &&
  // eslint-disable-next-line eqeqeq
  ((_error$response3 = error.response) === null || _error$response3 === void 0 || (_error$response3 = _error$response3.data) === null || _error$response3 === void 0 ? void 0 : _error$response3.fieldErrors) != undefined;
}
export function isConflictErrorWithFields(error) {
  return isConflictError(error) && isFieldError(error);
}