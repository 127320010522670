import { addDays, formatDateString, getDiffInDays, removeTime } from '@wkda/funnel-utils';
function getAvailableDatesAmount(now) {
  let availableSlotsDayOffset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 7;
  let dates = arguments.length > 2 ? arguments[2] : undefined;
  if (!dates || !Object.keys(dates).length) {
    return availableSlotsDayOffset + 1;
  }
  const availableDates = Object.keys(dates).map(key => new Date(key)).sort((d1, d2) => d1.getTime() - d2.getTime());
  const lastAvailableDate = availableDates[availableDates.length - 1];
  const lastAvailableDateWithOffset = addDays(now, availableSlotsDayOffset);
  const lastDate = lastAvailableDate > lastAvailableDateWithOffset ? lastAvailableDate : lastAvailableDateWithOffset;
  const finalLength = getDiffInDays(lastDate, now) + 1;
  return finalLength;
}
export function getCalendarDateOptions(dates, locale, availableSlotsDayOffset, startDate) {
  const now = removeTime(startDate);
  const availableDatesLength = getAvailableDatesAmount(now, availableSlotsDayOffset, dates);
  const allDates = Array(availableDatesLength).fill(0).reduce((result, _, idx) => {
    const day = new Date(+now + 1000 * 60 * 60 * 24 * idx);
    const date = day.getFullYear() + "-" + String(day.getMonth() + 1).padStart(2, '0') + "-" + String(day.getDate()).padStart(2, '0');
    result[date] = (dates === null || dates === void 0 ? void 0 : dates[date]) || {
      slots: []
    };
    result[date].day = day.toISOString();
    return result;
  }, {});
  const result = Object.keys(allDates).map(date => ({
    value: date,
    label: formatDateString(locale, allDates[date].day, {
      month: '2-digit',
      day: '2-digit'
    }) + "\n" + formatDateString(locale, allDates[date].day, {
      weekday: 'short'
    }),
    disabled: allDates[date].slots.length === 0,
    className: allDates[date].slots.length ? '' : 'box-selector__item--disabled'
  }));
  return result;
}