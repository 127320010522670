import { Statuses } from '../auto-pricing/statuses';
const warnAboutMissingLogger = () => console.warn('`whatsapp module` is missing `logger` dependency');
export function createWhatsapp(_ref) {
  let {
    whatsappService,
    logger,
    apmLogger
  } = _ref;
  function handleWhatsappMessage(_ref2, status) {
    let {
      carleadHash,
      bookingUrl,
      pageSource
    } = _ref2;
    let snapshotTime = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    if (status === Statuses.SUCCESS) {
      const redirectUrl = bookingUrl + "/" + carleadHash + "/?from=" + pageSource;
      const diff = Date.now() - snapshotTime;
      if (!snapshotTime || diff < 0) {
        return window.location.assign(redirectUrl);
      }

      // Pricing should take at least 10 seconds
      setTimeout(() => {
        return window.location.assign(redirectUrl);
      }, 10000 - diff);
    }
  }
  function isValidStatus(status) {
    return Object.values(Statuses).includes(status);
  }
  const checkStatus = status => {
    if (!isValidStatus(status)) {
      throw new Error("Unknown whatsapp status: " + status + ". Valid values are: " + Object.values(Statuses));
    }
  };
  async function getStatus(_ref3) {
    let {
      carleadHash,
      bookingUrl,
      pageSource = 'step-3'
    } = _ref3;
    whatsappService.init(carleadHash);
    try {
      const {
        status
      } = await whatsappService.listenOnce();
      checkStatus(status);
      handleWhatsappMessage({
        bookingUrl,
        carleadHash,
        pageSource
      }, status);
    } catch (err) {
      apmLogger.error(err);
      if (logger !== null && logger !== void 0 && logger.error) {
        logger.error({
          error: err,
          message: '[WHATSAPP] Cannot get whatsapp status'
        });
      } else {
        console.error(err);
        warnAboutMissingLogger();
      }
    }
  }
  function subscribe(_ref4) {
    let {
      carleadHash,
      onMessageHandler,
      onErrorHandler
    } = _ref4;
    const onMessage = _ref5 => {
      let {
        status
      } = _ref5;
      checkStatus(status);
      onMessageHandler(carleadHash, status, whatsappService);
    };
    const onError = err => {
      apmLogger.error(err);
      if (logger !== null && logger !== void 0 && logger.error) {
        logger.error({
          error: err,
          message: '[WHATSAPP] Subscription error'
        });
      } else {
        console.error(err);
        warnAboutMissingLogger();
      }
      onErrorHandler(err, carleadHash);
    };
    try {
      whatsappService.init(carleadHash);
      whatsappService.listen(onMessage, onError);
    } catch (err) {
      onError(err);
    }
  }
  return {
    subscribe,
    getStatus
  };
}