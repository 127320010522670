import type { Request } from 'express';

import { getCarLead, getSourceIDByLocale } from './helpers';

export interface BannerLandingEvent {
  hash?: string;
  type: string;
  category: string;
  source?: string;
  url: string;
  action: string;
  appname: string;
  timestamp: Date;
  userHash: string;
}

function validateEvent(event: BannerLandingEvent) {
  if (!event.source) throw new Error('`source` field cannot be empty');
  return event;
}

export function createEvent(
  req: Request,
  { userHash, locale }: { userHash: string; locale: string }
): BannerLandingEvent {
  return validateEvent({
    hash: getCarLead(req.url),
    type: 'cfe',
    category: 'gdpr-preferences',
    source: getSourceIDByLocale(locale),
    url: `${req.protocol}://${req.get('host')}${req.originalUrl}`,
    action: 'banner-landing',
    appname: 'wkda',
    timestamp: new Date(),
    userHash,
  });
}
