import { createSelector } from 'reselect';
import { createApi } from './api';
export function create(_ref) {
  let {
    httpClient
  } = _ref;
  const {
    getCarCrm,
    update
  } = createApi(httpClient);
  return {
    getCarCrm,
    update
  };
}
export const carCrmAsOptionsSelector = createSelector([carCrmSelector], carCrm => {
  return Object.entries(carCrm).map(_ref2 => {
    let [key, value] = _ref2;
    return {
      value: key,
      name: "_" + value,
      label: value
    };
  });
});
export function carCrmSelector(state) {
  return state !== null && state !== void 0 ? state : {};
}