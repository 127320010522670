const CUSTOMER_DATA_PATH = 'marketing-direct-auction/customer-data';
export function createApi(httpClient) {
  return {
    getCustomerData,
    createCustomerData
  };
  function getCustomerData(carleadHash) {
    return httpClient.get(CUSTOMER_DATA_PATH + "/hash/" + carleadHash + "/").then(res => res.data);
  }
  function createCustomerData(carleadHash, data) {
    return httpClient.post(CUSTOMER_DATA_PATH, {
      ...data,
      carLeadHash: carleadHash
    }).then(res => res.data);
  }
}