export const timezoneMap = {
  'de': 'Europe/Berlin',
  'es': 'Europe/Madrid',
  'nl': 'Europe/Amsterdam',
  'it': 'Europe/Rome',
  'fr': 'Europe/Paris',
  'pl': 'Europe/Warsaw',
  'pt': 'Europe/Lisbon',
  'da': 'Europe/Copenhagen',
  'de-AT': 'Europe/Vienna',
  'nl-BE': 'Europe/Brussels',
  'fr-BE': 'Europe/Brussels',
  'sv-SE': 'Europe/Stockholm'
};