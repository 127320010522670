function msToDays(ms) {
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  return days;
}
export function getDiffInDays(d1, d2) {
  return msToDays(d1.getTime() - d2.getTime());
}
export function removeTime(date) {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
}
export function addDays(date, days) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}
export function getTimestamp() {
  return String(Date.now());
}
export const isValidDate = d => !isNaN(d.getTime());