import { DSBAPICache, injectAPICache } from '@wkda/api-cache-client';
import { DataLocalStorage } from '@wkda/funnel-utils';
import { decamelizeKeys } from 'humps';
import { stringify } from 'query-string';
export function createApi(httpClient) {
  return {
    getManufacturers,
    getMainTypes,
    getMainTypesDetailKey,
    getBuiltDates,
    getBodyTypes,
    getMainTypesDetails,
    getMainTypesSub,
    getSubtypeExtra: getMainTypesSub
  };
  function getManufacturers(params) {
    return httpClient.get(createApiPath('/manufacturer'), {
      params
    }).then(r => {
      return r.data;
    });
  }
  function getMainTypes(params) {
    return httpClient.get(createApiPath('/main-types'), {
      params
    }).then(r => {
      return r.data;
    });
  }
  function getBuiltDates(params) {
    return httpClient.get(createApiPath('/built-dates'), {
      params
    }).then(r => {
      return r.data;
    });
  }
  function getBodyTypes(params) {
    return httpClient.get(createApiPath('/body-types'), {
      params
    }).then(r => {
      return r.data;
    });
  }
  function getMainTypesDetailKey(ecode) {
    return httpClient.get(createApiPath("/main-type-detail-key/" + ecode)).then(r => {
      return r.data;
    });
  }
  function getMainTypesDetails(params) {
    return httpClient.get(createApiPath('/main-types-details'), {
      params
    }).then(r => {
      return r.data;
    });
  }
  function getMainTypesSub(params) {
    return httpClient.get(createApiPath('/main-types-sub'), {
      params
    }).then(r => {
      return r.data;
    });
  }
}
export function createInjectAPICache(httpClient) {
  let cacheKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'dsb_apis_cache';
  return injectAPICache(getApiCache(cacheKey), httpClient);
}
export function createCarTypesParamsSerializer(locale) {
  return function paramsSerializer(params) {
    return stringify(decamelizeKeys({
      ...params,
      locale
    }, {
      separator: '-',
      process(key, convert, options) {
        // the only param which should not be dashrized is pageSize
        return key === 'pageSize' ? key : convert(key, options);
      }
    }));
  };
}
function getApiCache() {
  let cacheKey = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'dsb_apis_cache';
  if (typeof window === 'undefined') {
    return {
      get: () => null,
      set: () => null
    };
  }
  return new DSBAPICache(new DataLocalStorage(), cacheKey);
}
function createApiPath(path) {
  return "/car-types" + path;
}