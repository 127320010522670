import { timezoneMap } from '../timezones';
export function formatDateAsISOString(date) {
  if (typeof date === 'string') {
    date = new Date(formatTimezone(date));
  }
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
}
export function formatDateString(locale, value) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    weekday: 'long',
    month: '2-digit',
    day: '2-digit'
  };
  try {
    if (!value) {
      return;
    }
    const _options = {
      ...options,
      timeZone: timezoneMap[locale]
    };
    const formatter = new Intl.DateTimeFormat(locale, _options);
    return formatter.format(new Date(formatTimezone(value))).replace(/\.$/, '');
  } catch (error) {
    console.error(error);
    return formatTime({
      locale,
      value
    });
  }
}

// TODO: remove this hackish solution after the end of iOS 13 support
function formatTime(_ref) {
  let {
    locale,
    value
  } = _ref;
  let date = new Date(formatTimezone(value));
  let options = {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: timezoneMap[locale]
  };
  return date.toLocaleTimeString(locale, options);
}

// TODO: remove this hackish solution after the end of iOS 13 support
export function formatTimezone(date) {
  return date.replace(/([+-]\d{2})(\d{2})$/g, '$1:$2');
}

/**
 * Try to parse string date to Date object.
 * @param dateString String date to parse ex. 01.02.2001
 * @returns Date object if parse is successful, or false.
 */
export function tryGetDateByString(dateString, locale) {
  if (!dateString) {
    return false;
  }
  try {
    const {
      literal = '.',
      format
    } = getDateFormatByLocale(locale);
    const dateParts = dateString.split(literal).filter(x => isFinite(+x));
    const datePartMap = {
      year: 'yyyy',
      month: 'mm',
      day: 'dd'
    };
    const dateFormatMap = format.split(literal);
    if (dateParts.length < 3) {
      return false;
    }
    let tempDateString = dateParts[dateFormatMap.findIndex(x => x === datePartMap.year)] + "-" + dateParts[dateFormatMap.findIndex(x => x === datePartMap.month)] + "-" + dateParts[dateFormatMap.findIndex(x => x === datePartMap.day)];
    const res = Date.parse(tempDateString);
    return isFinite(res) ? new Date(res) : false;
  } catch (error) {
    return false;
  }
}
const dateFormatByLocaleMap = {
  'de': 'dd.mm.yyyy',
  'es': 'dd/mm/yyyy',
  'nl': 'dd-mm-yyyy',
  'it': 'dd/mm/yyyy',
  'fr': 'dd/mm/yyyy',
  'pl': 'dd.mm.yyyy',
  'pt': 'dd/mm/yyyy',
  'da': 'dd.mm.yyyy',
  'de-AT': 'dd.mm.yyyy',
  'nl-BE': 'dd/mm/yyyy',
  'fr-BE': 'dd/mm/yyyy',
  'sv-SE': 'yyyy-mm-dd'
};
export function getDateFormatByLocale(locale) {
  const format = dateFormatByLocaleMap[locale];
  const literal = format.split(/dd|mm|yyyy/).find(Boolean);
  let regexMask = [/[0-3]/, /[0-9]/, literal, /[0-1]/, /[0-9]/, literal, /[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  if (locale === 'sv-SE') {
    regexMask = [/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, literal, /[0-1]/, /[0-9]/, literal, /[0-3]/, /[0-9]/];
  }
  return {
    format,
    literal,
    regexMask
  };
}
export function formatDateToYYYYMMDD(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return year + "-" + month + "-" + day;
}