export function getBookingCreateOptions(_ref) {
  let {
    config,
    isSelectedAppointmentAvailable = true
  } = _ref;
  const {
    doubleBooking,
    bookingSkipMinutes,
    virtualSlots
  } = config;
  // Couldn't find where do we receive this 'isRealSlots'
  // const { booking: isRealSlots } = carlead;
  let options = {
    skipMinutes: bookingSkipMinutes,
    virtualSlots
  };
  if (config.allowSameDayOverBooking) {
    options.allowSameDayOverBooking = true;
    options.includeOverbookingSlotAfterMinutes = config.includeOverbookingSlotAfterMinutes;
  }
  if (doubleBooking) {
    options = {
      ...options,
      overbooking: false,
      prioritybooking: false,
      bookingConfirmationEmail: true
    };
    if (!isSelectedAppointmentAvailable) {
      options.overbooking = true;
      options.bookingConfirmationEmail = false;
    }
  }
  return options;
}