import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { parseSafeJSON } from '../json';
import { localStore } from '../safeStorage';
export class DataLocalStorage {
  constructor() {
    let customEventName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '$customLocalStorageEvent';
    _defineProperty(this, "customEventName", void 0);
    this.customEventName = customEventName;
  }
  setItem(key, item) {
    const oldValue = localStore.getJSON(key);
    const EventToFire = this._canHandleStorageEvent ? StorageEvent : CustomEvent;
    const _payload = {
      key,
      oldValue,
      newValue: item
    };
    const payload = this._canHandleStorageEvent ? _payload : {
      detail: _payload
    };
    localStore.setJSON(key, item);
    this._fireChangeEvent(new EventToFire(this.customEventName, payload));
  }
  getItem(key) {
    return localStore.getJSON(key);
  }
  onChange(key) {
    let handler = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : () => {};
    if (typeof localStorage === 'undefined') {
      return undefined;
    }
    window.addEventListener('storage', _handler);
    // `storage` event doesn't work on the same page, so we need a custom event
    // @ts-expect-error
    window.addEventListener(this.customEventName, _handler);
    return () => {
      window.removeEventListener('storage', _handler);
      // @ts-expect-error
      window.removeEventListener(this.customEventName, _handler);
    };
    function _handler(event) {
      // @ts-expect-error
      const payload = event.detail ? event.detail : event;
      if (payload.key !== key) return;
      handler({
        key,
        newValue: parseSafeJSON(payload.newValue),
        oldValue: parseSafeJSON(payload.oldValue)
      });
    }
  }
  get _canHandleStorageEvent() {
    try {
      new StorageEvent('test');
      return true;
    } catch (e) {
      return false;
    }
  }
  _fireChangeEvent(event) {
    if (typeof window === 'undefined') return;
    window.dispatchEvent(event);
  }
  key(index) {
    return localStore.key(index);
  }
  removeItem(key) {
    localStore.removeItem(key);
  }
  get length() {
    return localStore.length;
  }
  clear() {
    localStore.clear();
  }
}