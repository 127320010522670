export function tryCatch(tryer, catcher) {
  return function _tryCatch() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    try {
      return tryer.apply(this, args);
    } catch (e) {
      return catcher.apply(this, args);
    }
  };
}