import Cookies from 'universal-cookie';
import { getQueryParams } from '@wkda/funnel-utils';
import { createApi } from './api';
import { trackCidAsCookie as trackCidAsCookieSession } from './trackCid';
import { getCIDCookie } from './getCIDCookie';
export function create(_ref, external, logger, apmLogger) {
  let {
    httpClient
  } = _ref;
  const {
    getCidData,
    createRawCid,
    getCIDSessionData,
    setCIDSessionData
  } = createApi(httpClient);
  function trackCidEvent(_ref2) {
    let {
      event,
      carleadHash,
      timeStamp = Date.now() / 1000 | 0
    } = _ref2;
    return Promise.all([getCidData().then(res => res.data), external.getConsumerCarlead(carleadHash)]).then(_ref3 => {
      var _cookies$get;
      let [{
        ip,
        cid,
        scid
      }, {
        id: carleadId,
        sourceId
      }] = _ref3;
      const cookies = new Cookies();
      const {
        location,
        navigator
      } = window;
      const utmxCookie = (_cookies$get = cookies.get('__utmx')) !== null && _cookies$get !== void 0 ? _cookies$get : '';
      const searchParams = getQueryParams();

      /* prettier-ignore */
      const fullSCID = scid + "_" + timeStamp + getQParam(searchParams) + "_" + location.href + "_" + navigator.userAgent + " " + ip + "_\"" + utmxCookie + "\"";

      /* prettier-ignore */
      const rawCid = timeStamp + "_" + event + "_" + carleadId + "_" + cid + "_" + fullSCID + "_\"\"";
      return createRawCid({
        rawCid,
        sourceId
      });
    });
  }
  return {
    getCidData,
    createRawCid,
    trackCidEvent,
    trackCidAsCookie(config, req, res) {
      return trackCidAsCookieSession({
        config,
        req,
        res,
        env: external.env,
        getCIDSessionData,
        setCIDSessionData,
        getPartial: external.getPartial,
        logger,
        apmLogger
      });
    },
    getCIDCookie
  };
}
function getQParam(searchParams) {
  return searchParams.q ? "_" + searchParams.q.replace(/_/, ' ') : '';
}