const RETAIL_CUSTOMER_PURCHASE_PATH = '/car-purchase/customer/retail';
export function createApi(httpClient) {
  return {
    postRetailCarPurchase,
    uploadPurchasingCarPrivateImage
  };
  function postRetailCarPurchase(payload) {
    return httpClient.post("" + RETAIL_CUSTOMER_PURCHASE_PATH, payload);
  }
  function uploadPurchasingCarPrivateImage(payload) {
    const formData = new FormData();
    formData.append('stockNumber', payload.stockNumber);
    formData.append('type', payload.type);
    formData.append('file', payload.file);
    formData.append('source', payload.source.toString());
    return httpClient.post("/php-api/v1.1/car/" + payload.stockNumber + "/private-image", formData);
  }
}