import { createApi } from './api';
export const propertyIds = {
  appointmentlocation: 3471,
  sessionLocation: 3472,
  postalCode: 5902,
  directPartnerSell: 6503,
  carleadVinInput: 8615,
  selfInspectionStep: 8740,
  affiliateScenario: 9102,
  isMismatchDialogShown: 9206,
  obfuscatedPrice: 8781,
  nullEmail: 8743,
  diversionAccount: 6852,
  licensePlateSubtypeMappingResult: 8604,
  licensePlateIsReliableVIN: 8605
};
export function create(_ref) {
  let {
    httpClient
  } = _ref;
  const {
    save,
    get,
    clear
  } = createApi(httpClient);
  return {
    save,
    get,
    clear,
    propertyIds
  };
}