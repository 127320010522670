export const CAR_PROPERTIES = {
  ONLINE_SELF_EVALUATION: 6410,
  CAR_PURCHASE_TRACK: 7100,
  SELF_EVALUATION_ASSUMPTION: 6414,
  CONTRACT_PLUS_HANDOVER: 6110,
  DEAL_FLOW_PRICED: 6509,
  DEALFLOW_FINAL_ELIGIBILITY: 6507,
  ACI_STATUS: 6416
};
export const CAR_PROPERTIES_VALUES = {
  RISK_FREE: 'risk-free',
  BALANCE_SHEET: 'balance-sheet',
  ONLINE_SELF_EVALUATION: 'online-self-evaluation'
};