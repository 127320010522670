import { parse } from 'query-string';
export const getQueryParams = req => {
  const queryString = req ? req.query : parse(window.location.search);
  return Object.entries(queryString).reduce((prev, _ref) => {
    let [key, value] = _ref;
    if (typeof value === 'string' || Array.isArray(value)) {
      return {
        ...prev,
        [key]: value
      };
    }
    return prev;
  }, {});
};