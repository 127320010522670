import * as React from 'react';
import { loadInitialProps } from '@wkda/after-framework';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import pick from 'lodash-es/pick';
import isEqual from 'lodash-es/isEqual';
import { get404Component, isInstantTransition, getAllRoutes } from './utils';

class Afterparty extends React.Component {
  state = {
    data: this.props.data.initialData,
    previousLocation: null,
    currentLocation: this.props.location,
    isLoading: false,
  };

  prefetcherCache = {};
  NotfoundComponent = get404Component(this.props.routes);

  static defaultProps = {
    transitionBehavior: 'blocking',
  };

  static getDerivedStateFromProps(props, state) {
    const currentLocation = props.location;
    const previousLocation = state.currentLocation;

    const navigated = !areLocationsEqual(previousLocation, currentLocation);
    // const navigated = currentLocation !== previousLocation;
    if (navigated) {
      return {
        previousLocation: state.previousLocation || previousLocation,
        currentLocation,
        isLoading: true,
      };
    }

    return null;
  }

  componentDidUpdate(_prevProps, prevState) {
    // const navigated = prevState.currentLocation !== this.state.currentLocation;
    const navigated = !areLocationsEqual(
      prevState.currentLocation,
      this.state.currentLocation,
    );

    if (navigated) {
      const {
        location,
        history,
        routes,
        data,
        transitionBehavior,
        // we don't want to pass these
        // to loadInitialProps()
        match,
        staticContext,
        children,
        ...rest
      } = this.props;

      const { scrollToTop } = data.afterData;
      const isInstantMode = isInstantTransition(transitionBehavior);
      const isBloackedMode = !isInstantMode;

      const ctx = {
        location,
        history,
        scrollToTop,
        ...rest,
      };

      // Only for page changes, prevent scroll up for anchor links
      const isPageChanged =
        !!prevState.currentLocation &&
        prevState.currentLocation.pathname !== location.pathname;

      const isAllowedToScroll = isPageChanged && scrollToTop.current === true;

      // in instant mode, first we scroll to top then we fetch the data
      if (isInstantMode && isAllowedToScroll) {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 0);
      }

      // in ssg mode we don't call component.getInitialProps
      // instead we fetch the page-data.json file
      const loadData = loadInitialProps;

      loadData(location.pathname, routes, ctx)
        .then((res) => res.data)
        .then((data) => {
          // if user moved to a new page at the time we were fetching data
          // for the previous page, we ignore data of the previous page
          if (this.state.currentLocation !== location) return;

          // in blocked mode, first we fetch the data and then we scroll to top
          if (isBloackedMode && isAllowedToScroll) {
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 0);
          }

          this.setState({ previousLocation: null, data, isLoading: false });
        })
        .catch((e) => {
          // @todo we should more cleverly handle errors???
          console.log(e);
        });
    }
  }

  prefetch = (pathname) => {
    // const { ssg } = this.props.data.afterData;

    // in ssg mode we don't call component.getInitialProps
    // instead we fetch the page-data.json file
    const loadData = loadInitialProps;

    loadData(pathname, this.props.routes, {
      history: this.props.history,
    })
      .then(({ data }) => {
        this.prefetcherCache = {
          ...this.prefetcherCache,
          [pathname]: data,
        };
      })
      .catch((e) => console.log(e));
  };

  render() {
    const { previousLocation, data, isLoading } = this.state;
    const { location: currentLocation, transitionBehavior } = this.props;
    const initialData = this.prefetcherCache[currentLocation.pathname] || data;

    const instantMode = isInstantTransition(transitionBehavior);

    // when we are in the instant mode we want to pass the right location prop
    // to the <Route /> otherwise it will render previous matche component
    const location = instantMode
      ? currentLocation
      : previousLocation || currentLocation;

    return (
      <Switch location={location}>
        {initialData?.statusCode === 404 && (
          <Route component={this.NotfoundComponent} path={location.pathname} />
        )}
        {initialData?.redirectTo && <Redirect to={initialData.redirectTo} />}
        {getAllRoutes(this.props.routes).map((r, i) => (
          <Route
            key={`route--${i}`}
            path={r.path}
            exact={r.exact}
            render={(props) =>
              React.createElement(r.component, {
                ...initialData,
                history: props.history,
                match: props.match,
                prefetch: this.prefetch,
                location,
                isLoading,
              })
            }
          />
        ))}
      </Switch>
    );
  }
}
export const After = withRouter(Afterparty);

function areLocationsEqual(previousLocation, currentLocation) {
  const keys = ['pathname', 'search'];
  const prevStateLocation = pick(previousLocation, keys);
  const currentStateLocation = pick(currentLocation, keys);
  const navigated = isEqual(prevStateLocation, currentStateLocation);
  return navigated;
}
