const gtmScript = (GTMId: string) =>
  `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${GTMId}');
`.trim();

/**
 * Injects GTM script on `window.load` event.
 * @param GTMId Locale-specific GTM id
 */
export const injectDataLayer = (GTMId: string) =>
  `
  if (document.readyState === 'complete') {
    ${gtmScript(GTMId)}
  } else {
    window.addEventListener('load', function() {
      ${gtmScript(GTMId)}
    }, false);  
  }
`.trim();
