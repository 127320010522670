/**
 * @typedef {import('@wkda/internal-types').ISelfEvaluationAppConfig} ISelfEvaluationAppConfig
 * @typedef {import('@wkda/internal-types').IBookingAppConfig} IBookingAppConfig
 * @typedef {import('@wkda/internal-types').ICarleadCreationAppConfig} ICarleadCreationAppConfig
 */

/**@type {ISelfEvaluationAppConfig | IBookingAppConfig | ICarleadCreationAppConfig} */
const config = require(
  `${process.env.RAZZLE_ROOT_APP_PATH}/src/configuration`,
).default;

export default config;
