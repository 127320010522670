import type { Request, Response } from 'express';

import { GDPR_PREFERENCES_COOKIE } from '@wkda/gdprlib';

import { TrackingService } from './tracking-service';
import type { APIService } from './api-service.interface';
import type { LoggerService } from './logger-service.interface';
import { isValidPageForTracking } from './helpers';

interface GDPRTrackingServiceConfig {
  locale: string;
  apiService: APIService;
  loggerService: any;
  userHash: string;
  isBannerContentAvailable: () => Promise<boolean>;
}

export class GDPRTrackingService {
  private locale: string;
  private apiService: APIService;
  private loggerService: LoggerService;
  private userHash: string;
  private isBannerContentAvailable: () => Promise<boolean>;

  constructor({
    locale,
    apiService,
    loggerService,
    userHash,
    isBannerContentAvailable,
  }: GDPRTrackingServiceConfig) {
    this.locale = locale;
    this.apiService = apiService;
    this.loggerService = loggerService;
    this.userHash = userHash;
    this.isBannerContentAvailable = isBannerContentAvailable;
  }

  async trackBannerLanding(req: Request, res: Response) {
    if (
      isValidPageForTracking(req, res) &&
      !req.cookies[GDPR_PREFERENCES_COOKIE]
    ) {
      const isBannerAvailable = await this.isBannerContentAvailable();
      if (!isBannerAvailable) return;

      new TrackingService({
        apiService: this.apiService,
        locale: this.locale,
        loggerService: this.loggerService,
      })
        .trackBannerLanding(req, this.userHash)
        .then((result) => {
          this.loggerService.info(result);
        });
    }
  }
}
