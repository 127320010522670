import './client_configure';
import { createClient } from './internal/client';
import { activate, initializeI18n } from './internationalization';
import { buildRoutes } from './routes';
import config from './config';

initializeI18n();

/*
 *Entry point for the client application
 */
createClient({
  config,
  routes: buildRoutes(config),
  activateLocale: activate,
});
