import _defineProperty from "@babel/runtime/helpers/defineProperty";
export class EventSourceManager {
  constructor(name) {
    _defineProperty(this, "_name", void 0);
    _defineProperty(this, "_eventSource", null);
    this._name = name;
  }
  init(url) {
    if (!this._eventSource) {
      this._eventSource = new EventSource(url);
      window[this._name] = this._eventSource;
    }
  }
  close() {
    if (window[this._name]) {
      window[this._name].close();
      window[this._name] = undefined;
    }
  }
  subscribe(handler) {
    var _this$_eventSource;
    (_this$_eventSource = this._eventSource) === null || _this$_eventSource === void 0 || _this$_eventSource.addEventListener('message', handler);
  }
  unsubscribe(handler) {
    var _this$_eventSource2;
    (_this$_eventSource2 = this._eventSource) === null || _this$_eventSource2 === void 0 || _this$_eventSource2.removeEventListener('message', handler);
  }
  addErrorHandler(handler) {
    var _this$_eventSource3;
    (_this$_eventSource3 = this._eventSource) === null || _this$_eventSource3 === void 0 || _this$_eventSource3.addEventListener('error', handler);
  }
  removeErrorHandler(handler) {
    var _this$_eventSource4;
    (_this$_eventSource4 = this._eventSource) === null || _this$_eventSource4 === void 0 || _this$_eventSource4.removeEventListener('error', handler);
  }
  get isInitialized() {
    return Boolean(window[this._name]);
  }
}