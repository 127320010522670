import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { DataLocalStorage } from '@wkda/funnel-utils';
export class VinResolutionState {
  constructor(hash) {
    let storage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new DataLocalStorage();
    _defineProperty(this, "storage", void 0);
    _defineProperty(this, "key", '');
    _defineProperty(this, "_unsubscribe", () => {});
    this.storage = storage;
    this.key = "vinResolveFailed_" + hash;
  }
  get isResolutionFailed() {
    var _this$storage$getItem;
    return (_this$storage$getItem = this.storage.getItem(this.key)) !== null && _this$storage$getItem !== void 0 ? _this$storage$getItem : false;
  }
  get resolutionFailedTimes() {
    return this.isResolutionFailed ? 1 : 0;
  }
  update(value) {
    this.storage.setItem(this.key, value);
  }
  observe(handler) {
    this._unsubscribe = this.storage.onChange(this.key, handler);
  }
  unsubscribe() {
    if (typeof this._unsubscribe === 'function') {
      this._unsubscribe();
    }
  }
}