import { createAutoPricing } from './autoPricing';
import { AutoPricingService } from './autoPricingService';
import { EventSourceManager } from './eventSourceManager';
export function create(apiUrl, _ref) {
  let {
    marketingPropertiesService,
    logger,
    apmLogger,
    config
  } = _ref;
  const autoPricingService = new AutoPricingService(new EventSourceManager('auto-pricing-sse'), apiUrl);
  return createAutoPricing({
    autoPricingService,
    marketingPropertiesService,
    logger,
    apmLogger,
    config
  });
}