import { stringify as stringifyQueryString } from 'query-string';
export function getProtocol(req) {
  return req ? req.protocol : window.location.protocol;
}
export function getHost(req) {
  return req ? req.get('host') : window.location.host;
}
export function getOrigin(req) {
  return req ? getProtocol(req) + "://" + getHost(req) : window.origin;
}
export function getPathname(req) {
  return req ? req.url : window.location.pathname;
}
export function getFullUrl(req) {
  return req ? req.protocol + "://" + req.get('host') + req.originalUrl : window.location.href;
}
export function removeLeadingSlashes(url) {
  return url.replace(/^\/+/g, '');
}
export function removeTrailingSlashes(url) {
  return url.replace(/\/+$/, '');
}
export function removeLeadingAndTrailingSlashes(url) {
  return removeLeadingSlashes(removeTrailingSlashes(url));
}

// from https://github.com/unjs/ufo/blob/v1.5.4/src/utils.ts#L212
export function cleanDoubleSlashes() {
  let input = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return input.split('://').map(string_ => string_.replace(/\/{2,}/g, '/')).join('://');
}
export function getDLPfromPath(pattern, path) {
  const result = path.match(pattern);
  if (result !== null) {
    var _result$;
    return removeTrailingSlashes((_result$ = result[0]) !== null && _result$ !== void 0 ? _result$ : '');
  }
  return '';
}
export function buildDLPPathRegexp(path) {
  return new RegExp(path.replace(/(__[^/]*)?/g, '').split('/').filter(Boolean).slice(-1).join('') + "/(__[^/]*)?");
}
export function isAbsoluteUrl(url) {
  return new RegExp('^(?:[a-z]+:)?//', 'i').test(url);
}
export function isDomainRelativeUrl(url) {
  return url.startsWith('/');
}
const filterQuery = (whitelist, query) => whitelist.reduce((acc, key) => query[key] ? {
  ...acc,
  [key]: query[key]
} : acc, {});
export const buildPathWithQuery = function (path) {
  let query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let whitelist = arguments.length > 2 ? arguments[2] : undefined;
  const whitelistedQuery = Array.isArray(whitelist) ? filterQuery(whitelist, query) : query;
  return Object.keys(whitelistedQuery).length ? path + "?" + stringifyQueryString(whitelistedQuery, {
    arrayFormat: 'bracket'
  }) : path;
};

// To be used in the context of form submission, window object should be available
export function createDomainRelativeUrl(url, hash, params) {
  const urlObj = new URL(url, window.location.origin);
  urlObj.pathname = urlObj.pathname + "/" + hash + "/";
  if (params) urlObj.search = params.toString();
  return cleanDoubleSlashes(urlObj.toString());
}

/**
 * @description Remove the version from the path with the preceding slash
 * @description Example: /inspection/basic/__CFE-9999/:carleadHash/thank-you -> /inspection/basic/:carleadHash/thank-you
 * @param {string} path
 * @returns {string}
 */
export function removeVersionFromPath(path) {
  return path.replace(/\/(__[^/]*)\/?/, '');
}

/**
 *
 * @param {string} path
 * @returns {boolean}
 */
export function pathHasVersion(path) {
  return /(__[^/]*)/.test(path);
}