const sourceIdLocaleMap = {
  'de': 1,
  'nl': 2,
  'it': 3,
  'fr': 4,
  'es': 5,
  'pl': 6,
  'de-AT': 7,
  'nl-BE': 8,
  'fr-BE': 9,
  'sv-SE': 11,
  'da': 14,
  'pt': 23
};
export const getSourceIdByLocale = locale => sourceIdLocaleMap[locale];