import { getQueryParams } from '../getQueryParams';
const stringTrueValue = 'yes';
const stringFalseValue = 'no';
export default function abSwitch(req) {
  const {
    abtesting
  } = req.query;
  let abTestingEnabled = true;
  if (abtesting && (abtesting === stringTrueValue || abtesting === stringFalseValue)) {
    abTestingEnabled = abtesting === stringTrueValue;
  }
  return abTestingEnabled;
}
export function parseExperimentDataParam(utmExpIdParam) {
  if (typeof utmExpIdParam !== 'string') return;
  const parts = utmExpIdParam.split('.');
  if (parts.length !== 3) return;
  const [_, id, variant] = parts;
  if (!id.length || !variant.length) return;
  return {
    id,
    variant
  };
}
export function getExperimentData(req) {
  if (!req && typeof window === 'undefined') return undefined;
  const queryParams = getQueryParams(req);
  return parseExperimentDataParam(queryParams['utm_expid']);
}