import { createApi } from './api';
export function create(_ref) {
  let {
    httpClient,
    config
  } = _ref;
  const api = createApi(httpClient);
  return {
    partnerContactPageLanding,
    savePartnerContactInfo,
    searchBranches,
    searchClosestBranches,
    getBranchMerchantInfo,
    getBranchProperties
  };
  function partnerContactPageLanding(carleadHash) {
    return api.partnerContactPageLanding(carleadHash).then(res => res.data);
  }
  function savePartnerContactInfo(carleadHash, values) {
    return api.savePartnerContactInfo(carleadHash, values).then(res => res.data);
  }
  function getBranchMerchantInfo(carleadHash) {
    return api.getBranchMerchantInfo(carleadHash).then(res => res.data);
  }
  function getBranchProperties(branchId) {
    return api.getBranchProperties(branchId).then(res => {
      var _res$data;
      return ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.entities) || [];
    });
  }
  function searchBranches(_ref2) {
    let {
      area,
      ipAddress,
      hash,
      limit = config.branchSearchLimit,
      slotAvailabilityMaxDays = config.multiBranchWidget.slotAvailabilityMaxDays,
      includeExperimentalBranches = false,
      enableBranchPreselection = true
    } = _ref2;
    return api.searchBranches({
      location: getLocationFromArea(area),
      hash,
      limit,
      specialAreas: config.specialAreas || [],
      ipAddress,
      enableBranchPreselection,
      slotAvailabilityMaxDays,
      includeExperimentalBranches
    }).then(res => res.data);
  }
  function searchClosestBranches(_ref3) {
    let {
      carleadId,
      countryCode,
      distance = config.closestBranchesSearchDistance || 150,
      limit = config.closestBranchesSearchLimit || 3,
      location
    } = _ref3;
    return api.searchClosestBranches({
      carleadId,
      countryCode,
      distance,
      limit,
      location
    }).then(res => res.data);
  }
}
function getLocationFromArea(area) {
  if (!area) {
    return null;
  }
  return {
    label: area.name,
    type: area.type || 'wkda',
    lat: area.lat,
    lng: area.lng
  };
}