import { createLogger } from '@wkda/elastic-rum';
import { isDevelopment } from '@wkda/funnel-utils';

/**
 * @typedef {import('@wkda/elastic-rum').createLogger} CreateLogger
 * @typedef {import('@wkda/elastic-rum').ElasticRumConfig} ElasticRumConfig
 */

/**
 * @param {import('@wkda/internal-types').IBaseAppConfig} config
 * @returns {import('@wkda/elastic-rum').RumLogger}
 */
export function initRum(config) {
  let active = false;

  if (!config.rumServerUrl || isNaN(config.enableRumPercentage)) {
    if (isDevelopment()) {
      console.warn('config.rumServerUrl is not configured.');
    }
  } else {
    //@ts-expect-error TODO: parseFloat should be used with a string
    active = Math.random() < parseFloat(config.enableRumPercentage);
  }

  if (!active) {
    if (isDevelopment()) {
      console.warn('RUM is disabled for this user.');
    }
  }

  /** @satisfies {ElasticRumConfig} */
  const rumConfig = {
    serverUrl: config.rumServerUrl,
    serviceName: config.appName.replace('@wkda/', 'wkda-'),
    serviceVersion: config.appVersion,
    environment: 'fe-wkda-sf',
    // according to @wkda/elastic-rum types and implementation, this should be 0 or 1
    active: active ? 1 : 0,
  };

  console.log('RUM config', rumConfig, config.enableRumPercentage);

  return createLogger(rumConfig, {
    onLoad: () => {
      console.log('RUM loaded');
    },
  });
}
