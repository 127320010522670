import { createApi } from './api';
export function create(_ref, external) {
  let {
    httpClient
  } = _ref;
  const {
    cjTracking
  } = createApi(httpClient);
  function cjTrackingWithDefaults(params) {
    return cjTracking({
      appname: 'self-evaluation-frontend',
      type: 'cfe',
      appver: external.appver,
      timestamp: "" + Date.now() / 1000,
      ...params
    });
  }
  return {
    cjTracking: cjTrackingWithDefaults
  };
}