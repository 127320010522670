import { createApi } from './api';
export function create(_ref) {
  let {
    httpClient
  } = _ref;
  const {
    subscribeToNewsletter
  } = createApi(httpClient);
  return {
    subscribeToNewsletter
  };
}