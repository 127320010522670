export interface GdprPreferences {
  categories: string[];
}

export default function getGdprPreferences(): GdprPreferences | null {
  try {
    const cookies = decodeURIComponent(document.cookie).split(';');
    for (let i = 0; i < cookies.length; i++) {
      let c = cookies[i].trim();
      if (c.indexOf('gdpr_preferences') === 0) {
        const cookieValue = c.substring('gdpr_preferences='.length, c.length);
        const gdprPreferences: GdprPreferences = JSON.parse(cookieValue);
        return gdprPreferences;
      }
    }
  } catch (err) {
    console.error('Failed to parse gdpr_preferences cookie: ', err);
  }
  return null;
}
