const CONSUMER_CARLEAD_PATH = 'consumer/';
export function createApi(httpClient) {
  return {
    getConsumerCarlead,
    getConsumerCarleadPrices,
    getConsumerCarleadPriced,
    getCarleadObfuscation,
    getConsumerSelfEvaluation,
    patchConsumerCarlead,
    createCarlead,
    createCarleadLicensePlate,
    createExpectedPrice,
    getAveragePriceCounter
  };
  function getConsumerCarlead(carleadHash) {
    return httpClient.get(createApiPath("carlead/" + carleadHash));
  }
  function getConsumerSelfEvaluation(carleadHash) {
    return httpClient.get(createApiPath("self-evaluation/" + carleadHash));
  }
  function getConsumerCarleadPrices(carleadHash) {
    return httpClient.get(createApiPath("carlead/" + carleadHash + "/aux-prices"));
  }
  function getConsumerCarleadPriced(carleadHash) {
    return httpClient.head(createApiPath("carlead/" + carleadHash + "/price"));
  }
  function getCarleadObfuscation(carleadHash) {
    return httpClient.get(createApiPath("carlead-obfuscation/" + carleadHash));
  }
  function patchConsumerCarlead(carleadHash, data) {
    return httpClient.patch(createApiPath("carlead/" + carleadHash), data, {
      headers: {
        'Content-Type': 'application/json-patch+json'
      }
    });
  }
  function createCarlead(payload) {
    return httpClient.post(createApiPath('carlead'), payload);
  }
  function createCarleadLicensePlate(payload) {
    return httpClient.post(createApiPath('carlead/licenseplate'), payload);
  }
  function createExpectedPrice(hash, expectedPrice) {
    return httpClient.post(createApiPath("expected-price/" + hash), {
      expectedPrice
    }).then(response => {
      return response.data;
    });
  }
  function getAveragePriceCounter() {
    return httpClient.get(createApiPath("averaged-priced-count")).then(response => {
      return response.data;
    });
  }
}
function createApiPath(path) {
  return "" + CONSUMER_CARLEAD_PATH + path;
}