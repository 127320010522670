const compose = function () {
  for (var _len = arguments.length, fns = new Array(_len), _key = 0; _key < _len; _key++) {
    fns[_key] = arguments[_key];
  }
  return fns.reduceRight((prevFn, nextFn) => function () {
    return nextFn(prevFn(...arguments));
  }, value => value);
};

/**
 * Creates a function expression object
 * @param {{[key: string]: Function}} fnsMap
 * @param {[]} list
 * @returns {{ fns: Function[], args: []}}
 */
function createFunctionsMap(fnsMap, list) {
  const args = list.slice(list.length - 1)[0];
  const fnsNames = list.slice(0, list.length - 1);
  const fns = fnsNames.reduce((acc, fnName) => {
    return typeof fnsMap[fnName] === 'function' ? [...acc, fnsMap[fnName]] : acc;
  }, []);
  return {
    fns,
    args
  };
}

/**
 * Runs check
 * @param {{[key: string]: Function}} checkers
 * @param {[]} experession
 */
function runCheck(checkers, experession) {
  const {
    fns,
    args
  } = createFunctionsMap(checkers, experession);
  return compose(...fns)(args);
}

/**
 * Flattens object to list
 * @example
 * { a: { b: [1,2]}} -> ['a', 'b', [1,2]]
 * @param {{[key: string]: Object | []}} obj
 * @param {string[]} result
 */
const transformToList = function (obj) {
  let result = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return Object.entries(obj).reduce((acc, _ref) => {
    let [key, value] = _ref;
    if (Array.isArray(value)) {
      return [...acc, key, value];
    }
    return transformToList(value, [...acc, key]);
  }, result);
};

/**
 * @typedef {{[key: string]: string[] | FeatureConfig}} FeatureConfig
 */

export function getFeatures(features, locale) {
  const availableCheckers = {
    /**
     * @param {string[]} locales
     */
    allowLocales: locales => locales.includes(locale),
    not: v => !v
  };
  const mapToExpressions = featuresConfig => Object.entries(featuresConfig).reduce((acc, _ref2) => {
    let [key, value] = _ref2;
    return {
      ...acc,
      [key]: transformToList(value)
    };
  }, {});
  const runChecks = expressionsMap => Object.entries(expressionsMap).reduce((acc, _ref3) => {
    let [key, expression] = _ref3;
    return {
      ...acc,
      [key]: runCheck(availableCheckers, expression)
    };
  }, {});
  return runChecks(mapToExpressions(features));
}