export async function getAppointmentCreationEvent(_ref) {
  let {
    hash,
    type,
    getAppointmentsByReference
  } = _ref;
  function getHasShownOnAppointment(appointments) {
    return appointments.filter(appointment => !!appointment.shownOn).length > 0;
  }
  if (type === 1) {
    const appointments = await getAppointmentsByReference(hash, 'evaluation');
    const hasShownOn = getHasShownOnAppointment(appointments);
    if (hasShownOn) {
      return 'REEVALUATION';
    }
    if (appointments.length > 1) {
      return 'REBOOKING';
    } else {
      return 'BOOKING';
    }
  } else {
    const appointments = await getAppointmentsByReference(hash, 'handover');
    if (appointments.length > 1) {
      return 'HANDOVERREBOOKING';
    } else {
      return 'HANDOVERBOOKING';
    }
  }
}
const eventMap = {
  REEVALUATION: 'bookingCreated',
  REBOOKING: 'rebookingCreated',
  BOOKING: 'handoverBookingCreated',
  HANDOVERREBOOKING: 'handoverRebookingCreated',
  HANDOVERBOOKING: 'reevaluationCreated'
};
export function getDataLayerEvent(appointmentEvent) {
  return eventMap[appointmentEvent];
}