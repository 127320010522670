import { createSelector } from 'reselect';
import { createApi } from './api';
export function create(_ref) {
  let {
    httpClient
  } = _ref;
  const {
    fetchQuestionnaire,
    submitQuestion,
    submitQuestionnaire,
    completeQuestionnaire
  } = createApi(httpClient);
  return {
    fetchQuestionnaire,
    submitQuestion,
    submitQuestionnaire,
    completeQuestionnaire
  };
}
export const questionnaireAsOptionsSelector = createSelector([questionnaireSelector], questions => {
  return Object.entries(questions).map(_ref2 => {
    let [key, value] = _ref2;
    return {
      value: key,
      name: "_" + value,
      label: value
    };
  });
});
export function questionnaireSelector(state) {
  var _state$questions;
  return (_state$questions = state === null || state === void 0 ? void 0 : state.questions) !== null && _state$questions !== void 0 ? _state$questions : {};
}