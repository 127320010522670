import { isQAAppEnvironment } from './appEnvironments';
// This base url is required to enable branch support. i.e
// For master: inspection/basic/29843983271983721/thank-you
// For branches: inspection/basic/__CFE-999/29843983271983721/thank-you
export function generateFunnelPath(config) {
  let includeVersionPath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  const configuredPath = getFunnelConfiguredPath(config, includeVersionPath);
  return "/" + configuredPath;
}
function getFunnelConfiguredPath(config, includeVersionPath) {
  if (config.routePathSchema) {
    return appendVersionPath(config.routePathSchema, config.env, includeVersionPath);
  }
  return config.funnelBaseUrl;
}
function appendVersionPath(path, env, includeVersionPath) {
  if (isQAAppEnvironment(env)) {
    return includeVersionPath ? path + "/(__[^/]*)" : path;
  }
  return path;
}