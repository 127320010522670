import { LogLevel } from '@wkda/logger-core';
// TODO: refactor into `parseError unknown -> Error`
function getErrorMessage(err) {
  if (err instanceof Error) return err.message;
  if (typeof err === 'string') return err;
  return 'Unknown error';
}
export class CFELoggerModule {
  constructor(_loggerService, _appConfig) {
    this._loggerService = _loggerService;
    this._appConfig = _appConfig;
  }
  _enrichMeta(meta) {
    return this._appConfig.appName && this._appConfig.appVersion ? {
      ...meta,
      appName: this._appConfig.appName,
      appVersion: this._appConfig.appVersion
    } : meta;
  }
  error(_ref) {
    let {
      error,
      message,
      meta = {}
    } = _ref;
    this._loggerService.log({
      logger: meta.logger || 'sf-error-logger',
      logLevel: LogLevel.ERROR,
      timestamp: new Date(),
      message: message || getErrorMessage(error),
      error: {
        message: getErrorMessage(error),
        stackTrace: error instanceof Error ? error.stack : undefined
      },
      meta: this._enrichMeta(meta)
    });
  }
  info(_ref2) {
    let {
      message,
      meta = {}
    } = _ref2;
    this._loggerService.log({
      logger: meta.logger || 'sf-logger-info',
      logLevel: LogLevel.INFO,
      timestamp: new Date(),
      message: message,
      meta: this._enrichMeta(meta)
    });
  }
  log(logRecord) {
    this._loggerService.log({
      ...logRecord,
      logger: logRecord.logger || 'sf-logger-info',
      timestamp: new Date(),
      meta: this._enrichMeta(logRecord.meta || {})
    });
  }
}