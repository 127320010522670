export function createApi(httpClient) {
  return {
    getManufacturers: getApiHandler('/manufacturers'),
    getMainTypes: getApiHandler('/main-types'),
    getBuiltDates: getApiHandler('/built-years'),
    getBodyTypes: getApiHandler('/body-types'),
    getMainTypesDetails: getApiHandler('/main-types-details'),
    getMainTypesSub: getApiHandler('/main-types-sub'),
    getSubtypeExtra: getApiHandler('/main-types-sub'),
    getFuelTypes: getApiHandler('/fuel-types'),
    getHorsePower: getApiHandler('/kw'),
    getGearTypes: getApiHandler('/gear-types'),
    getDoorCount: getApiHandler('/door-counts')
  };
  function getApiHandler(url) {
    return params => {
      const {
        builtDate,
        horsePower,
        ...otherParams
      } = params;
      const newParams = otherParams;
      if (builtDate) {
        newParams.builtYear = builtDate;
      }
      if (horsePower) {
        newParams.kw = horsePower;
      }
      return httpClient.get("/cardata/types" + url, {
        params: newParams
      }).then(r => {
        return r.data;
      });
    };
  }
}