export function injectAPICache(apiCache, axiosInstance) {
  const originalGet = axiosInstance.get.bind(axiosInstance);
  function getWithCache(url, config) {
    const cached = apiCache.get(url, config === null || config === void 0 ? void 0 : config.params);
    if (cached !== null) {
      console.log('[API] cache hit', url, config === null || config === void 0 ? void 0 : config.params);
      return Promise.resolve({
        data: cached,
        status: 200,
        statusText: 'OK',
        headers: (config === null || config === void 0 ? void 0 : config.headers) || {},
        config
      });
    }
    return originalGet(url, config).then(r => {
      //@ts-expect-error
      apiCache.set(url, config === null || config === void 0 ? void 0 : config.params, r.data);
      return r;
    });
  }
  return {
    ...axiosInstance,
    get: getWithCache
  };
}