import merge from 'lodash-es/merge';
import { getSourceIdByLocale } from '../sourceId';
import generateBaseConfig from './generateBaseConfig';
const defaults = {
  appType: 'carlead-funnel',
  googleAutocompleteLetterThreshold: 3,
  googleAutocompleteDebounce: 500,
  emailSuggestionEnabled: false,
  bookingUrl: '',
  confirmationUrl: '',
  redirectOnSuccessUrl: '',
  routePathSchema: ':pathPrefix/:dlp',
  // TODO: will be done in separate task
  stepsTimeTracking: {
    isEnabledFor: [],
    options: {
      idleTimeout: 20,
      afterIDLETimeoutInMilliseconds: 10 * 1000 //10 seconds (3000 milliseconds)
    }
  },
  // default features values
  featuresFlags: {
    redirectToSelfEvaluation: false,
    pricingScope: 'none',
    selfEvaHideOnlinePriceBucket: false,
    honeyPotEmail: false,
    skipOfferEmail: false,
    estateAgentFunnel: false,
    affiliateScenario: 'none',
    dropOffEmailDelayInMinutes: 'none',
    unspecifiedExperiment: false,
    sendLeadCIDChannel: true,
    exactMileage: false,
    walkInSelfEvaluation: false
  },
  autoSelectCallback: function (fieldName) {
    if (typeof window === 'object' && typeof window.addFormHistoryStep === 'function') {
      window.addFormHistoryStep(fieldName);
    }
  },
  abExperiment: {
    experimentSnippetName: 'carlead-experiment-snippet',
    partialGroupId: 1
  },
  step2Path: '/{currentPath}/step-2/',
  marketingAttributes: {},
  licensePlateFallbackPath: '',
  invalidLicensePlateFallbackPath: ''
};

/**
 * This type allows us to limit what could be configured with generateCarleadCreationConfig function.
 */

export default function generateCarleadCreationConfig() {
  let overrides = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const options = merge({}, defaults, overrides);
  const baseConfig = generateBaseConfig(overrides);

  //@ts-expect-error
  return {
    ...baseConfig,
    ...options,
    sourceId: getSourceIdByLocale(baseConfig.locale)
  };
}