export function createApi(httpClient) {
  return {
    get,
    save,
    clear
  };
  function save(hash, body) {
    return httpClient.put("car-details/marketing-property/" + hash, body).then(r => r.data);
  }
  function get(hash) {
    return httpClient.get("car-details/marketing-property/" + hash).then(r => r.data);
  }

  /**
   * Clears property value by setting it to null
   * @param id id of the property to clear, do not confuse with `MarketingProperty.propertyId`
   */
  async function clear(id) {
    await httpClient.patch("car-details/marketing-property/" + id, [{
      op: 'replace',
      path: '/value',
      value: ''
    }], {
      headers: {
        'Content-Type': 'application/json-patch+json'
      }
    });
  }
}