import { CIDData } from './cidData';

interface CIDParserConfig {
  itemSeparator?: string;
  fieldSeparator?: string;
  parameterSeparator?: string;
}

export interface CID {
  channel: string;
  daysSinceAttribution: number;
  fullCID: string;
  timestamp: number;
}

export function parse(
  rawCID?: string | null,
  config: CIDParserConfig = {},
): CID[] {
  const {
    itemSeparator = '||',
    fieldSeparator = '&',
    parameterSeparator = '_',
  } = config;
  const decodedCIDString = decodeRawCID(rawCID);
  const list = decodedCIDString ? decodedCIDString.split(itemSeparator) : [];

  return list.map((item) => {
    const cidData = new CIDData(item, fieldSeparator, parameterSeparator);

    return {
      channel: cidData.channel,
      daysSinceAttribution: cidData.daysSinceAttribution,
      fullCID: cidData.cid,
      timestamp: cidData.timestamp,
    };
  });
}

function decodeRawCID(rawCID: string | unknown): string {
  if (typeof rawCID !== 'string') {
    return '';
  }

  try {
    return decodeURIComponent(rawCID);
  } catch (_) {
    return '';
  }
}
