import Cookies from 'universal-cookie';
import { DataLayerEvents } from './DataLayerEventStack';
import type { AppointmentDataLayerEventName, CID } from './dataLayer.types';
import { createEvent } from './createEvent';

interface CarLeadData {
  hash: string;
  stockNumber: string;
  createdOn?: string;
}

interface InitDataLayerParams {
  locale: string;
  CIDChannels: CID[];
  carLeadData: CarLeadData;
  isUnique: boolean;
  event: string;
}

interface AppointmentEventParams {
  CIDChannels: CID[];
  carLeadData: CarLeadData;
  isUnique: boolean;
  event: AppointmentDataLayerEventName;
}

type GTMDataLayer = object[];

const DataLayerEventCookieFlag = {
  NEW_LEAD: 'lp/new-lead',
};

/**
 * Singleton class for managing data layer
 */
export class DataLayer {
  private static instance: DataLayer;
  private static cookieService = new Cookies();
  initialized: boolean = false;

  private getDataLayer(): GTMDataLayer {
    (window as any).dataLayer = (window as any).dataLayer || [];
    return (window as any).dataLayer as GTMDataLayer;
  }

  static getInstance(): DataLayer {
    if (!DataLayer.instance) {
      DataLayer.instance = new DataLayer();
    }

    return DataLayer.instance;
  }

  /**
   * Remove cookie flags created when we create a lead. This function should be called after pushing related datalayer event.
   * https://wkdauto.atlassian.net/browse/CFE-12603
   */
  private checkDataLayerEventCookieFlags(data: object) {
    const { event } = data as {
      event: string;
      data: object;
    };

    if (
      event === DataLayerEvents.LEAD_CREATED &&
      typeof DataLayer.cookieService.get(DataLayerEventCookieFlag.NEW_LEAD) !==
        'undefined'
    ) {
      DataLayer.cookieService.remove(DataLayerEventCookieFlag.NEW_LEAD, {
        path: '/',
      });
    }
  }

  /**
   * Pushes initial events to data layer.
   * Can be initialized only once. All other attempts will
   * be ignored
   */
  init({
    CIDChannels,
    locale,
    carLeadData,
    isUnique,
    event,
  }: InitDataLayerParams) {
    if (!this.initialized) {
      this.add({ locale });

      this.add(createEvent(event, CIDChannels, carLeadData, isUnique));

      this.initialized = true;
    }
  }

  /**
   * Adds arbitrary data to the data layer
   */
  add(item: object) {
    this.getDataLayer().push(item);
    this.checkDataLayerEventCookieFlags(item);
  }

  /**
   * Add arbitrary data to the beginning of the data layer
   * @param item
   */
  unshift(item: object) {
    this.getDataLayer().unshift(item);
    this.checkDataLayerEventCookieFlags(item);
  }

  /**
   * Adds event to the data layer
   * @param event
   * @param data
   */
  addEvent(event: string, data: object) {
    return this.add({ event, ...data });
  }
  /**
   * Add event to the beginning of the data layer
   * @param event
   * @param data
   */
  unshiftEvent(event: string, data: object) {
    return this.unshift({ event, ...data });
  }

  /**
   * Pushes appointment event to data layer
   */
  addAppointmentEvent({
    CIDChannels,
    carLeadData,
    isUnique,
    event,
  }: AppointmentEventParams) {
    this.add(createEvent(event, CIDChannels, carLeadData, isUnique));
  }
}
