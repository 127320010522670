import orderBy from 'lodash-es/orderBy';
import { createSelector } from 'reselect';
import { createApi } from './api';
export function create(_ref) {
  let {
    httpClient
  } = _ref;
  const {
    getManufacturers,
    getMainTypes,
    getBuiltDates,
    getBodyTypes,
    getMainTypesDetailKey,
    getMainTypesDetails,
    getMainTypesSub,
    getSubtypeExtra
  } = createApi(httpClient);
  return {
    getManufacturers,
    getMainTypes,
    getMainTypesDetailKey,
    getBuiltDates,
    getBodyTypes,
    getMainTypesDetails,
    getMainTypesSub,
    getSubtypeExtra
  };
}
export const carTypeResponseAsOptionsSelector = createSelector([carTypeResponseSelector, orderByConfig], (response, orderConfig) => {
  return orderBy(Object.entries(response).map(_ref2 => {
    let [key, value] = _ref2;
    return {
      value: key,
      name: "_" + value,
      label: value
    };
  }), orderConfig.orderBy, orderConfig.order);
});
export function carTypeResponseSelector(state) {
  var _state$wkda;
  return (_state$wkda = state === null || state === void 0 ? void 0 : state.wkda) !== null && _state$wkda !== void 0 ? _state$wkda : {};
}
export function orderByConfig(_, orderBy, order) {
  return {
    orderBy: orderBy !== null && orderBy !== void 0 ? orderBy : 'label',
    order: order
  };
}