// This base url is required to enable branch support. i.e
// For master: inspection/basic/29843983271983721/thank-you
// For branches: inspection/basic/__CFE-999/29843983271983721/thank-you

import {
  generateFunnelPath,
  isQAAppEnvironment,
  removeVersionFromPath,
} from '@wkda/funnel-utils';

/**
 * @type {Array<{ path?: string, exact?: boolean, component: any }>}
 */
const routes = require(
  `${process.env.RAZZLE_ROOT_APP_PATH}/src/routes.js`,
).default;

/**
 * @typedef {import('@wkda/internal-types').ISelfEvaluationAppConfig} ISelfEvaluationAppConfig
 * @typedef {import('@wkda/internal-types').IBookingAppConfig} IBookingAppConfig
 * @typedef {import('@wkda/internal-types').ICarleadCreationAppConfig} ICarleadCreationAppConfig
 * @typedef {{routePathSchema?: string} & (ISelfEvaluationAppConfig | IBookingAppConfig | ICarleadCreationAppConfig)} IAppConfig
 */

/**
 * @param {IAppConfig} config
 */
export function buildRoutes(config) {
  const generated = routes.flatMap((route) => {
    return buildRoute(route, config);
  });

  return generated;
}

/**
 * @param {{path?: string;}} route
 * @param {IAppConfig} config
 */
function buildRoute(route, config) {
  if (route.path === undefined) {
    return route;
  }
  const { path, ...restRoute } = route;
  let routes = [{ path: `${generateFunnelPath(config)}${path}`, ...restRoute }];
  if (isQAAppEnvironment(config.env)) {
    routes.push({
      path: config.routePathSchema
        ? `${generateFunnelPath(config, false)}${path}`
        : `${removeVersionFromPath(generateFunnelPath(config))}${path}`,
      ...restRoute,
    });
  }
  return routes;
}
