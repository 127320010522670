const CONSUMER_SERVICE_PATH = 'consumer/';
export function createApi(httpClient) {
  return {
    getAvailableAppointmentTimes,
    getLatestAppointmentDetails,
    createAppointment,
    cancelAppointment,
    markAppointmentAsActive
  };
  function getAvailableAppointmentTimes(branchId, hash, options) {
    return httpClient.post(createApiPath('appointment/search'), {
      branchId,
      hash,
      options: {
        dayOffset: 7,
        ...options
      }
    });
  }
  function getLatestAppointmentDetails(hash) {
    return httpClient.post(createApiPath('appointment/related/latest'), {
      referenceId: hash
    });
  }
  function markAppointmentAsActive(data) {
    return httpClient.post(createApiPath('appointment/related/cancel'), data);
  }
  function createAppointment(data) {
    return httpClient.post(createApiPath('appointment/extended'), data);
  }

  //not conventional request just to send some kind of DELETE_POST request because of java
  //https://github.com/axios/axios/issues/1083
  function cancelAppointment(referenceId, comment, statusChangeSubreason) {
    return httpClient.delete(createApiPath('appointment'), {
      params: {
        comment,
        referenceId,
        statusChangeSubreason
      },
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        comment,
        referenceId,
        statusChangeSubreason
      }
    });
  }
}
function createApiPath(path) {
  return "" + CONSUMER_SERVICE_PATH + path;
}