import { tryCatch } from '../tryCatch';
import memoryStorage from './memoryStorage';
export function createStorage(getStorage) {
  const storage = getStorage();
  const internalStorage = memoryStorage;
  return {
    getItem() {
      return storageToUse().getItem(...arguments);
    },
    setItem() {
      storageToUse().setItem(...arguments);
    },
    removeItem() {
      storageToUse().removeItem(...arguments);
    },
    clear() {
      storageToUse().clear(...arguments);
    },
    key() {
      return storageToUse().key(...arguments);
    },
    getJSON(key) {
      const st = storageToUse();
      return tryCatch(k => {
        const i = st.getItem(k);
        return i ? JSON.parse(i) : null;
      }, () => null)(key);
    },
    setJSON(key, value) {
      const st = storageToUse();
      tryCatch(v => st.setItem(key, JSON.stringify(v)), _v => {})(value);
    },
    get length() {
      return storageToUse().length;
    }
  };
  function storageToUse() {
    return isSupported(getStorage) ? storage : internalStorage;
  }
}
function isSupported(getStorage) {
  try {
    const storage = getStorage();
    if (storage !== null) {
      const testKey = '__some_random_key_you_are_not_going_to_use__';
      storage.setItem(testKey, testKey);
      storage.removeItem(testKey);
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
}
export function isLocalStorageSupported() {
  return isSupported(() => typeof window !== 'undefined' ? window.localStorage : null);
}
export const localStore = createStorage(() => isLocalStorageSupported() ? window.localStorage : memoryStorage);
export const sessionStore = createStorage(() => isLocalStorageSupported() ? window.sessionStorage : memoryStorage);