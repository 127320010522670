export function isQAAppEnvironment(env) {
  return appEnvironmentIs(env, 'qa');
}
export function isProdAppEnvironment(env) {
  return appEnvironmentIs(env, 'prod');
}
export function isLocalAppEnvironment(env) {
  return appEnvironmentIs(env, 'local');
}
export function appEnvironmentIs(env, whichEnv) {
  return env === whichEnv;
}