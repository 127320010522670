const licensePlateErrorPattern = 'Cannot identify license plate in ';
export function isVINVerificationError(err) {
  var _err$response, _err$response2;
  return Boolean((err === null || err === void 0 || (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.status) === 409 && (err === null || err === void 0 || (_err$response2 = err.response) === null || _err$response2 === void 0 || (_err$response2 = _err$response2.data) === null || _err$response2 === void 0 || (_err$response2 = _err$response2.globalErrors) === null || _err$response2 === void 0 ? void 0 : _err$response2.find(message => {
    return message.includes(licensePlateErrorPattern);
  })));
}
export const identificationStates = {
  success: 'IDENTIFICATION_SUCCESS',
  failed: 'IDENTIFICATION_ERROR',
  skipped: 'IDENTIFICATION_SKIPED',
  previousFailed: 'PREVIOUS_IDENTIFICATION_ERROR',
  previousSuccess: 'PREVIOUS_IDENTIFICATION_SUCCESS'
};
export function maybeIdentifyVehicle(previousIndetification, carLeadHash, licensePlate, currentStep, identifyOnStep, identifyVehicle) {
  if (!shouldIdentify(previousIndetification, currentStep, identifyOnStep, licensePlate)) {
    return Promise.resolve(false);
  }
  return identifyVehicle(carLeadHash, removeLicensePlateSeparators(licensePlate)).then(() => {
    return true;
  });
}
function shouldIdentify(previousIndetification, currentStep, identifyOnStep, licensePlate) {
  return previousIndetification !== 'IDENTIFICATION_FAILED' && currentStep === identifyOnStep && Boolean(licensePlate);
}
export function removeLicensePlateSeparators(licensePlate) {
  return String(licensePlate).replace(/\s|-/g, '');
}