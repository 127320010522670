import { NotFoundComponent } from './NotFoundComponent';

export function isInstantTransition(transition) {
  return transition === 'instant';
}

export function get404Component(routes) {
  const match = is404ComponentAvailable(routes);
  return match ? match.component : NotFoundComponent;
}

export function is404ComponentAvailable(routes) {
  return (
    routes.find((route) => ['**', '*', '', undefined].includes(route.path)) ||
    false
  );
}

export function getAllRoutes(routes) {
  return is404ComponentAvailable(routes)
    ? routes
    : [...routes, { component: NotFoundComponent }];
}

export function isLoadableComponent(Component) {
  return Component.load !== undefined;
}

export function isJS(str) {
  return str.endsWith('.js');
}
