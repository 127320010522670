import { createApi } from './api';
import { isAxiosError } from '@wkda/funnel-utils';
export function create(httpClient) {
  const {
    searchOrUpdateQuestionnaire: _searchOrUpdateQuestionnaire,
    submitQuestionnaire: _submitQuestionnaire
  } = createApi(httpClient);
  return {
    searchQuestionnaire,
    searchQuestionnaireLogic,
    updateQuestionnaire,
    submitQuestionnaire
  };
  function searchQuestionnaireLogic(carleadHash, type) {
    return searchQuestionnaire(carleadHash, type).then(response => {
      const data = response.data;
      if (!data) {
        return {
          status: 'NEW_QUESTIONNAIRE',
          payload: {
            type
          }
        };
      }
      if (data.status !== 2) {
        return {
          status: 'ONGOING_QUESTIONNAIRE',
          payload: data
        };
      } else {
        return {
          status: 'SUBMITTED_QUESTIONNAIRE'
        };
      }
    }).catch(error => {
      if (isAxiosError(error)) {
        var _error$response;
        if (((_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status) === 404) {
          return {
            status: 'NEW_QUESTIONNAIRE',
            payload: {
              type
            }
          };
        }
      }
      throw error;
    });
  }
  function submitQuestionnaire(carleadHash, type) {
    return _submitQuestionnaire(carleadHash, type);
  }
  function searchQuestionnaire(carleadHash, type) {
    return _searchOrUpdateQuestionnaire(carleadHash, type);
  }
  function updateQuestionnaire(_ref) {
    let {
      carleadHash,
      type,
      questions
    } = _ref;
    return _searchOrUpdateQuestionnaire(carleadHash, type, questions);
  }
}
export function getQuestionnaireTypeFromStatus(status) {
  return status === 6 || status === 106 || status === 114 || status === 214 ? 'feedbackBought' : 'feedbackNotBought';
}