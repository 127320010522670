import { createApi } from './api';
export function create(_ref) {
  let {
    httpClient
  } = _ref;
  const {
    uploadSelfEvaluationImage
  } = createApi(httpClient);
  function uploadClassifiedImages(carleadHash, images) {
    return Promise.all([images].flat().map(file => {
      return uploadSelfEvaluationImage({
        carleadHash,
        file,
        timeout: 180000
      }).then(transformImageRespose);
    }));
  }
  return {
    uploadClassifiedImages
  };
}
export function transformImageRespose(img) {
  const fullUrl = img.fullUrl.replace('{size}', '');
  return {
    ...img,
    fullUrl
  };
}