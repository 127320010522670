import { createLogger } from '@wkda/funnel-modules';

/**
 * @typedef {import('@wkda/funnel-modules').ModulesLogger} ModulesLogger
 * */

/**
 * @param {import("@wkda/internal-types").IBaseAppConfig} config
 * @param {import("@wkda/elastic-rum").RumLogger} [rumLogger]
 * @return {import("@wkda/funnel-modules").ModulesLogger}
 * */
export function createClientLogger(config, rumLogger) {
  if (!rumLogger) {
    return createLogger(config);
  }

  /**@satisfies {ModulesLogger} */
  return {
    info({ message, meta }) {
      rumLogger.log(meta.name, meta.scope, message);
    },
    error({ error }) {
      if (error instanceof Error) {
        rumLogger.error(error);
      }
    },
    log(logRecord) {
      rumLogger.log(
        logRecord.meta?.name,
        logRecord.meta?.scope,
        logRecord.message,
      );
    },
  };
}
