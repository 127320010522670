import type { Request } from 'express';

import type { APIService } from './api-service.interface';
import type { LoggerService } from './logger-service.interface';
import type { BannerLandingEvent } from './banner-landing-event';
import { createEvent } from './banner-landing-event';

interface TrackingServiceParams {
  apiService: APIService;
  loggerService: LoggerService;
  locale: string;
}

interface TrackingResponse {
  createdAt: Date;
  event: string;
  uuid: string;
}

export class TrackingService {
  private apiService: APIService;
  private loggerService: LoggerService;
  private locale: string;

  constructor({ apiService, locale, loggerService }: TrackingServiceParams) {
    this.apiService = apiService;
    this.loggerService = loggerService;
    this.locale = locale;
  }

  async trackBannerLanding(req: Request, userHash: string) {
    try {
      const event = createEvent(req, { userHash, locale: this.locale });
      const response = await this.apiService.post<
        BannerLandingEvent,
        { data: TrackingResponse }
      >('/v1/tracking', event);

      return response.data;
    } catch (err: any) {
      const url = `${req?.protocol}://${req?.get('host')}${req?.originalUrl}`;
      this.loggerService.error(
        `Banner landing tracking error: ${err.message || err}; locale: ${this.locale}; url: ${url}`
      );

      return null;
    }
  }
}
