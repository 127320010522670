import { buildPathWithQuery, getQueryParams } from '@wkda/funnel-utils';
import { Statuses } from './statuses';
const SELF_EVALUATION_AUTOPRICING_STATUS = 8780;
export const DEFAULT_PRICING_TIMEOUT_IN_SEC = 80;
export { Statuses } from './statuses';
const warnAboutMissingLogger = () => console.warn('`auto-pricing module` is missing `logger` dependency');
export function createAutoPricing(_ref) {
  let {
    autoPricingService,
    marketingPropertiesService,
    logger,
    apmLogger,
    config
  } = _ref;
  const redirectWithQuery = (history, url) => history.replace(buildPathWithQuery(url, getQueryParams(), config.whitelistedQueryParams));
  function handleAutoPricingMessage(_ref2, status) {
    let {
      history,
      funnelBaseUrl,
      carleadHash,
      bookingUrl,
      pageSource
    } = _ref2;
    let snapshotTime = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    const redirect = url => redirectWithQuery(history, url);
    if (status === Statuses.IN_PROGRESS) {
      return redirect("/" + funnelBaseUrl + "/" + carleadHash + "/calculating-price");
    }
    autoPricingService.closeConnection();
    if (status === Statuses.TIMEOUT || status === Statuses.FAILURE) {
      return redirect("/" + funnelBaseUrl + "/" + carleadHash + "/thank-you-calc");
    }
    if (status === Statuses.SKIPPED) {
      return redirect("/" + funnelBaseUrl + "/" + carleadHash + "/thank-you");
    }
    if (status === Statuses.SUCCESS) {
      const redirectUrl = bookingUrl + "/" + carleadHash + "/?from=" + pageSource;
      const diff = Date.now() - snapshotTime;
      if (!snapshotTime || diff < 0) {
        return window.location.assign(redirectUrl);
      }

      // Pricing should take at least 10 seconds
      setTimeout(() => {
        return window.location.assign(redirectUrl);
      }, 10000 - diff);
    }
  }
  function isValidStatus(status) {
    return Object.values(Statuses).includes(status);
  }
  const saveStatusProperty = (carleadHash, status) => marketingPropertiesService.save(carleadHash, {
    propertyId: SELF_EVALUATION_AUTOPRICING_STATUS,
    value: status
  });
  const checkStatus = status => {
    if (!isValidStatus(status)) {
      throw new Error("Unknown auto-pricing status: " + status + ". Valid values are: " + Object.values(Statuses));
    }
  };
  async function getAutoPricingStatus(_ref3) {
    let {
      carleadHash,
      funnelBaseUrl,
      bookingUrl,
      history,
      pageSource = 'step-3'
    } = _ref3;
    // TODO: move init iside the constructor
    autoPricingService.init(carleadHash);
    try {
      const {
        status
      } = await autoPricingService.listenOnce();
      checkStatus(status);
      await saveStatusProperty(carleadHash, status);
      handleAutoPricingMessage({
        history,
        funnelBaseUrl,
        bookingUrl,
        carleadHash,
        pageSource
      }, status);
    } catch (err) {
      apmLogger.error(err);
      if (logger !== null && logger !== void 0 && logger.error) {
        logger.error({
          error: err,
          message: '[AUTO PRICING] Cannot get auto pricing status'
        });
      } else {
        console.error(err);
        warnAboutMissingLogger();
      }
      redirectWithQuery(history, "/" + funnelBaseUrl + "/" + carleadHash + "/thank-you");
    }
  }
  function subscribe(_ref4) {
    let {
      carleadHash,
      timeoutInSec = DEFAULT_PRICING_TIMEOUT_IN_SEC,
      onMessageHandler,
      onErrorHandler
    } = _ref4;
    const onMessage = _ref5 => {
      let {
        status
      } = _ref5;
      checkStatus(status);
      saveStatusProperty(carleadHash, status);
      onMessageHandler(carleadHash, status, autoPricingService);
    };
    const onError = err => {
      apmLogger.error(err);
      if (logger !== null && logger !== void 0 && logger.error) {
        logger.error({
          error: err,
          message: '[AUTO PRICING] Subscription error'
        });
      } else {
        console.error(err);
        warnAboutMissingLogger();
      }
      onErrorHandler(err, carleadHash);
    };
    try {
      autoPricingService.init(carleadHash);
      const unsubscribe = autoPricingService.listen(onMessage, onError);
      setTimeout(() => {
        if (autoPricingService.isInitialized) {
          unsubscribe();
          autoPricingService.closeConnection();
          onMessage({
            status: Statuses.TIMEOUT
          });
        }
      }, timeoutInSec * 1000);
    } catch (err) {
      onError(err);
    }
  }
  function subscribeToAutoPricing(_ref6) {
    let {
      carleadHash,
      bookingUrl,
      funnelBaseUrl,
      history,
      timeoutInSec = DEFAULT_PRICING_TIMEOUT_IN_SEC,
      pageSource = 'calculating-price',
      delayPricing = false
    } = _ref6;
    const snapshotTime = delayPricing ? Date.now() : undefined;
    return subscribe({
      carleadHash,
      timeoutInSec,
      onMessageHandler: (hash, status) => handleAutoPricingMessage({
        history,
        funnelBaseUrl,
        bookingUrl,
        carleadHash: hash,
        pageSource
      }, status, snapshotTime),
      onErrorHandler: (_, hash) => redirectWithQuery(history, "/" + funnelBaseUrl + "/" + hash + "/thank-you-calc")
    });
  }
  return {
    subscribe,
    getAutoPricingStatus,
    subscribeToAutoPricing
  };
}