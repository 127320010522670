import { createWhatsapp } from './whatsapp';
import { EventSourceManager } from '../auto-pricing/eventSourceManager';
import { WhatsappService } from './whatsappService';
export function create(apiUrl, _ref) {
  let {
    logger,
    apmLogger,
    config
  } = _ref;
  const whatsappService = new WhatsappService(new EventSourceManager('whatsapp-sse'), apiUrl);
  return createWhatsapp({
    whatsappService,
    logger,
    apmLogger,
    config
  });
}