import { removeLeadingAndTrailingSlashes, isAbsoluteUrl, removeTrailingSlashes } from '@wkda/funnel-utils';
export function getFormattedSubmitRedirectUrl(url, hash, config, params) {
  let urlObj;
  if (isAbsoluteUrl(url)) {
    urlObj = new URL(removeTrailingSlashes(url));
  } else {
    urlObj = new URL("/" + config.funnelBaseUrl + "/" + removeLeadingAndTrailingSlashes(url), window.location.origin);
  }
  urlObj.pathname = urlObj.pathname + "/" + hash + "/";
  if (params) urlObj.search = params.toString();
  return urlObj.toString();
}