import { isAxiosError } from '@wkda/funnel-utils';
import { LogLevel } from '@wkda/logger-core';
import axios from 'axios';
import * as rax from 'retry-axios';
import { ApiError } from './ApiError';
export const LIMIT_ERROR_MESSAGE = 1000;
export function createHttpClient(config, appConfig, logger) {
  const axiosInstance = axios.create({
    withCredentials: true,
    raxConfig: {
      retry: 5
    },
    ssrLogger: logger,
    ...config
  });
  axiosInstance.interceptors.request.use(createPartnerFunnelInterceptor(appConfig));
  addTracingInterceptor(axiosInstance);
  if (typeof window === 'undefined') {
    addSsrLoggingInterceptor(axiosInstance);
  }
  axiosInstance.defaults.raxConfig = {
    instance: axiosInstance
  };
  rax.attach(axiosInstance);
  return axiosInstance;
}
export function modifyAxiosDefaults(configuration, isServer, waKey, traceId) {
  if (isServer) {
    modifyAxiosDefaultsServer(configuration, waKey, traceId);
  }
}
function modifyAxiosDefaultsServer(configuration, waKey, traceId) {
  const {
    appName,
    appVersion,
    serverName,
    serverVersion
  } = configuration;
  axios.defaults.headers.common['User-Agent'] = serverName + "/<" + serverVersion + "> " + appName + "/<" + appVersion + ">";
  if (traceId) {
    axios.defaults.headers.common['x-b3-traceid'] = traceId;
  }
  if (configuration.isPartnerFunnel) {
    axios.defaults.params = {
      ...axios.defaults.params,
      wa_key: waKey
    };
  }
}
function createPartnerFunnelInterceptor(appConfig) {
  return reqConfig => {
    if (appConfig.isPartnerFunnel && typeof window !== 'undefined') {
      if (!reqConfig.params) {
        reqConfig.params = {};
      }
      reqConfig.params['wa_key'] = new URL(window.location.href).searchParams.get('wa_key');
    }
    return reqConfig;
  };
}
function addTracingInterceptor(axios) {
  axios.interceptors.response.use(response => response, error => {
    let err;
    try {
      err = new ApiError(error);
      if (typeof window !== 'undefined') {
        // @ts-expect-error Type Defs.
        const elasticApm = window.elasticApm;
        if (elasticApm !== null && elasticApm !== void 0 && elasticApm.isActive() && elasticApm !== null && elasticApm !== void 0 && elasticApm.isEnabled()) elasticApm === null || elasticApm === void 0 || elasticApm.captureError(err);
      }
    } catch (error) {
      console.error('Error while adding tracing interceptor', error);
    }
    return Promise.reject(error);
  });
}
function addSsrLoggingInterceptor(axios) {
  axios.interceptors.request.use(config => {
    config.metadata = {
      startTime: new Date()
    };
    return config;
  }, error => {
    return Promise.reject(error);
  });
  axios.interceptors.response.use(response => {
    apiLog(response);
    return response;
  }, error => {
    if (isAxiosError(error) && error.response) {
      apiLog(error.response);
    }
    return Promise.reject(error);
  });
}
function apiLog(response) {
  var _config$metadata, _startTime$getTime, _config$method, _config$ssrLogger;
  const {
    config,
    headers,
    status
  } = response;
  const url = config.url;
  const traceId = headers['x-b3-traceid'];
  const endTime = new Date();
  const startTime = (_config$metadata = config.metadata) === null || _config$metadata === void 0 ? void 0 : _config$metadata.startTime;
  const duration = endTime.getTime() - ((_startTime$getTime = startTime === null || startTime === void 0 ? void 0 : startTime.getTime()) !== null && _startTime$getTime !== void 0 ? _startTime$getTime : endTime.getTime());
  const payload = {
    message: "Outbound: " + ((_config$method = config.method) === null || _config$method === void 0 ? void 0 : _config$method.toUpperCase()) + " " + url + "; Response " + status + " in " + duration + "ms " + traceId,
    logger: 'ssr-logger',
    logLevel: status >= 500 ? LogLevel.WARN : LogLevel.INFO,
    traceId: traceId,
    duration
  };
  (_config$ssrLogger = config.ssrLogger) === null || _config$ssrLogger === void 0 || _config$ssrLogger.log(payload);
}