export function createApi(httpClient) {
  return {
    submitQuestionnaire,
    searchOrUpdateQuestionnaire
  };
  function searchOrUpdateQuestionnaire(hash, type) {
    let questions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    return httpClient.put("/crm-questionnaire/car/" + hash + "/review/" + type + "/questions", {
      questions
    });
  }
  function submitQuestionnaire(hash, type) {
    return httpClient.post("/crm-questionnaire/car/" + hash + "/review/" + type, {});
  }
}