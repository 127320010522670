import { LogLevel, LoggerProvider } from '@wkda/logger-core';
import { mapLogLevel } from './utils';
const getLoggerFn = logLevel => logLevel === LogLevel.ERROR ? console.error : console.log;
export class ConsoleLoggerProvider extends LoggerProvider {
  constructor(logFormatter) {
    super(logFormatter);
    this.logFormatter = logFormatter;
  }
  getLogger(loggerName) {
    return {
      log: logRecord => {
        var _logRecord$logLevel, _logRecord$logLevel2, _logRecord$meta, _logRecord$meta2, _logRecord$meta3, _logRecord$error;
        return getLoggerFn((_logRecord$logLevel = logRecord.logLevel) !== null && _logRecord$logLevel !== void 0 ? _logRecord$logLevel : 'INFO')({
          level: mapLogLevel((_logRecord$logLevel2 = logRecord.logLevel) !== null && _logRecord$logLevel2 !== void 0 ? _logRecord$logLevel2 : 'INFO'),
          appName: (_logRecord$meta = logRecord.meta) === null || _logRecord$meta === void 0 ? void 0 : _logRecord$meta.appName,
          appVersion: (_logRecord$meta2 = logRecord.meta) === null || _logRecord$meta2 === void 0 ? void 0 : _logRecord$meta2.appVersion,
          stepName: (_logRecord$meta3 = logRecord.meta) === null || _logRecord$meta3 === void 0 ? void 0 : _logRecord$meta3.stepName,
          payload: {
            type: logRecord.logger,
            message: logRecord.message,
            stack: (_logRecord$error = logRecord.error) === null || _logRecord$error === void 0 ? void 0 : _logRecord$error.stackTrace
          }
        });
      },
      name: loggerName
    };
  }
}