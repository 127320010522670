export const DSBMapper = {
  fromExternal(data) {
    if (!data || !data.response) return null;
    return {
      data: data.response.result,
      expires: new Date(data.response.expireDate)
    };
  },
  toExternal(record) {
    return {
      response: {
        result: record.data,
        expireDate: record.expires.toISOString()
      }
    };
  }
};