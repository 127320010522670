import isNil from 'lodash-es/isNil';
import { DSBMapper } from './dsb-data-mapper';
const HOUR_IN_SEC = 3600;
export class DSBAPICache {
  constructor(storage, storageKey) {
    let config = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
      maxAge: HOUR_IN_SEC
    };
    this.storage = storage;
    this.storageKey = storageKey;
    this.config = config;
  }
  getData() {
    return this.storage.getItem(this.storageKey);
  }
  setData(data) {
    this.storage.setItem(this.storageKey, data);
  }
  getRecordKeyName(url, params) {
    const serializedParams = params ? JSON.stringify(params) : 'undefined';
    return JSON.stringify(url + "-" + serializedParams);
  }
  getExpirationDate() {
    const date = new Date();
    date.setSeconds(date.getSeconds() + this.config.maxAge);
    return date;
  }
  invalidate(recordKey) {
    const data = this.getData();
    if (data) {
      delete data[recordKey];
      this.setData(data);
      console.log("[Cache] Cache for " + recordKey + " has expired");
    }
  }
  getRecord(recordKey) {
    var _this$getData;
    const cacheRecord = (_this$getData = this.getData()) === null || _this$getData === void 0 ? void 0 : _this$getData[recordKey];
    const mappedRecord = DSBMapper.fromExternal(cacheRecord);
    return mappedRecord || null;
  }
  createRecord(value) {
    return DSBMapper.toExternal({
      data: value,
      expires: this.getExpirationDate()
    });
  }
  get(url, params) {
    const key = this.getRecordKeyName(url, params);
    const record = this.getRecord(key);
    if (!record) return null;
    if (new Date() > record.expires) {
      this.invalidate(key);
      return null;
    }
    return record.data;
  }
  set(url, params, value) {
    const key = this.getRecordKeyName(url, params);
    const data = this.getData();
    const record = this.createRecord(value);
    const dataToSet = isNil(data) ? {
      [key]: record
    } : {
      ...data,
      [key]: record
    };
    this.setData(dataToSet);
  }
}