export const DOIDomains = {
  'de-DE': {
    'gmail': {
      domain: 'Gmail',
      url: 'https://mail.google.com'
    },
    'tonline': {
      domain: 'T-Online',
      url: 'https://email.t-online.de'
    },
    'livemail': {
      domain: 'Live mail',
      url: 'https://login.live.com'
    },
    'yahoo': {
      domain: 'Yahoo mail',
      url: 'https://login.yahoo.com'
    },
    'freenet': {
      domain: 'Freenet',
      url: 'https://www.freenet.de/index.html'
    },
    'gmx': {
      domain: 'GMX',
      url: 'http://www.gmx.net'
    },
    'web-de': {
      domain: 'WEB.DE',
      url: 'http://www.web.de/'
    },
    'AOL': {
      domain: 'AOL',
      url: 'https://my.screenname.aol.com/'
    }
  },
  'fr-FR': {
    'gmail': {
      domain: 'Gmail',
      url: 'https://mail.google.com'
    },
    'tonline': {
      domain: 'T-Online Webmail',
      url: 'https://email.t-online.fr'
    },
    'livemail': {
      domain: 'Live mail',
      url: 'https://login.live.com'
    },
    'yahoo': {
      domain: 'Yahoo mail',
      url: 'https://login.yahoo.com'
    },
    'freenet': {
      domain: 'Freenet',
      url: 'https://www.freenet.de/index.html'
    },
    'gmx': {
      domain: 'GMX',
      url: 'http://www.gmx.net'
    },
    'web-de': {
      domain: 'WEB.DE',
      url: 'http://www.web.de/'
    }
  },
  'es-ES': {
    gmail: {
      domain: 'Gmail',
      url: 'https://mail.google.com'
    },
    hotmail: {
      domain: 'Hotmail',
      url: 'https://login.live.com/'
    },
    livemail: {
      domain: 'Live mail',
      url: 'https://login.live.com'
    },
    yahoo: {
      domain: 'Yahoo mail',
      url: 'https://login.yahoo.com'
    },
    outlook: {
      domain: 'Outlook',
      url: 'https://login.live.com/'
    },
    icloud: {
      domain: 'iCloud',
      url: 'https://www.icloud.com/'
    },
    ono: {
      domain: 'Ono',
      url: 'https://www.ono.com/correoONO/login.html'
    },
    msn: {
      domain: 'MSN',
      url: 'https://login.live.com/'
    }
  },
  'de-AT': {
    gmail: {
      domain: 'Gmail',
      url: 'https://mail.google.com'
    }
  },
  'it-IT': {
    gmail: {
      domain: 'Gmail',
      url: 'https://accounts.google.com/'
    },
    libero: {
      domain: 'Libero',
      url: 'https://login.libero.it'
    },
    livemail: {
      domain: 'Live mail',
      url: 'https://login.live.com'
    },
    yahoo: {
      domain: 'Yahoo mail',
      url: 'https://login.yahoo.com'
    },
    alice: {
      domain: 'Alice',
      url: 'https://mail.tim.it/'
    },
    tiscali: {
      domain: 'Tiscali',
      url: 'https://mail.tiscali.it/'
    },
    virgilio: {
      domain: 'Virgilio',
      url: 'https://mail.virgilio.it'
    }
  },
  'nl-BE': {
    gmail: {
      domain: 'Gmail',
      url: 'https://mail.google.com'
    },
    livemail: {
      domain: 'Live mail',
      url: 'https://login.live.com'
    },
    yahoo: {
      domain: 'Yahoo mail',
      url: 'https://login.yahoo.com'
    },
    skynet: {
      domain: 'Skynet mail',
      url: 'https://webmail.skynet.be'
    },
    telenet: {
      domain: 'Telenet mail',
      url: 'https://webmail.telenet.be'
    }
  }
};