const SUBSCRIPTION_API_PATH = "subscription/";
export function createApi(httpClient) {
  function subscribeToNewsletter(email) {
    return httpClient.post(SUBSCRIPTION_API_PATH, {
      email,
      referrer_id: 'footer_newsletter'
    }, {
      baseURL: '/api/v1/'
    });
  }
  return {
    subscribeToNewsletter
  };
}