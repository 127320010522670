import base64 from 'base-64';
import { camelizeKeys } from 'humps';
export function createApi(httpClient) {
  const getPartial = _getPartial(httpClient);
  return {
    getPartial,
    getPartials: getAllPartials
  };
  function getAllPartials(locale) {
    const promises = [['header', '@wkda/partials/header/'], ['footer', '@wkda/partials/footer/'], ['header-user-nav-list', '@wkda/partials/header-user-nav-list/']].map(partial => {
      const [partialName, partialPath] = partial;
      return getPartial(partialPath, locale).then(parsedPartialResponse => {
        return {
          ...parsedPartialResponse,
          content: parseContent(parsedPartialResponse.content)
        };
      }).then(partial => {
        return {
          name: partialName,
          data: partial.content,
          ...partial
        };
      });
    });
    return Promise.all(promises).then(response => {
      return response.reduce((acc, partial) => {
        return {
          ...acc,
          [partial.name]: partial
        };
      }, {});
    }).then(response => camelizeKeys(response));
  }
}
export function getInnerPartialContent(_ref) {
  let {
    content
  } = _ref;
  return content;
}
export function parseContent() {
  let content = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '{}';
  try {
    return JSON.parse(content);
  } catch (error) {
    throw error;
  }
}
function _getPartial(httpClient) {
  function innerGetPartial(name, locale) {
    let params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    let resolvePartials = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    const {
      groupId
    } = params;
    const path = getPartialUrl({
      groupId,
      locale,
      encodedUrl: encodePartialUrl(name),
      resolvePartials
    });
    const task = httpClient.get(createApiPath(path), {
      params
    });
    return task.then(getContentFromAxiosResponse).then(content => parseContent(content));
  }
  return innerGetPartial;
}
function createApiPath(path) {
  return "cms" + path;
}
function encodePartialUrl(name) {
  return base64.encode("" + name);
}
function getContentFromAxiosResponse(obj) {
  return obj.data.content;
}
function getPartialUrl(_ref2) {
  let {
    groupId = 1,
    locale,
    encodedUrl,
    resolvePartials
  } = _ref2;
  const partialpath = "/" + groupId + "/" + locale + "/template/cms-public-content/page/" + encodedUrl + (resolvePartials ? '?resolvePartials=true' : '');
  return partialpath;
}