import type {
  ElasticApmInstance,
  ElasticRumConfig,
  ElasticTransaction,
  Logger,
} from './types';

class ServerElasticApm implements ElasticApmInstance {
  constructor(private logger: ServerLogger) {}

  init(_: ElasticRumConfig) {
    console.warn(`Elastic RUM was initialized on SSR. Skipped.`);
  }

  startTransaction(name: string, scope: string): ElasticTransaction {
    return {
      startSpan: (message: string) => {
        this.logger.log(name, scope, message);
        return {
          end: () => {},
        };
      },
      end: () => {},
    };
  }

  captureError(err: Error) {
    this.logger.error(err);
  }
}

export class ServerLogger implements Logger {
  public getApmInstance(): Promise<ElasticApmInstance> {
    return Promise.resolve(new ServerElasticApm(this));
  }

  public log(name: string, scope: string, message: string): void {
    if (!name || !scope || !message) {
      return;
    }
    console.log(
      JSON.stringify({
        message: `${name} - ${scope} - ${message}`,
        log: {
          level: 'INFO',
        },
      }),
    );
  }

  public error(error: Error): void {
    if (!error || !(error instanceof Error)) {
      return;
    }
    console.log(
      JSON.stringify({
        message: error.message,
        log: {
          level: 'ERROR',
        },
        error: {
          message: error.message,
          stack: error.stack,
        },
      }),
    );
  }
}
