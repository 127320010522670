import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { LoggerProvider } from '@wkda/logger-core';
import { mapLogLevel } from './utils';
export class CFELoggerProvider extends LoggerProvider {
  constructor(logFormatter, loggerAPIService) {
    super(logFormatter);
    _defineProperty(this, "_loggerAPIService", void 0);
    this._loggerAPIService = loggerAPIService;
  }
  getLogger(loggerName) {
    return {
      log: logRecord => {
        var _logRecord$logLevel, _logRecord$meta, _logRecord$meta2, _logRecord$meta3, _logRecord$error;
        this._loggerAPIService.post({
          level: mapLogLevel((_logRecord$logLevel = logRecord.logLevel) !== null && _logRecord$logLevel !== void 0 ? _logRecord$logLevel : 'INFO'),
          appName: (_logRecord$meta = logRecord.meta) === null || _logRecord$meta === void 0 ? void 0 : _logRecord$meta.appName,
          appVersion: (_logRecord$meta2 = logRecord.meta) === null || _logRecord$meta2 === void 0 ? void 0 : _logRecord$meta2.appVersion,
          stepName: (_logRecord$meta3 = logRecord.meta) === null || _logRecord$meta3 === void 0 ? void 0 : _logRecord$meta3.stepName,
          payload: {
            type: logRecord.logger,
            message: logRecord.message,
            stack: (_logRecord$error = logRecord.error) === null || _logRecord$error === void 0 ? void 0 : _logRecord$error.stackTrace
          }
        });
      },
      name: loggerName
    };
  }
}