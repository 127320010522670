import { CAR_PROPERTIES } from '../consumer/constants';
import { getCarPropertyByIdSelector } from '../consumer';
export function getDoubleBookingOptions(carlead, funnelConfig, _currentRoute, formValues) {
  const {
    canSeePrice,
    hasValidBid
  } = carlead;
  const {
    availableSlotsDayOffset,
    virtualSlots,
    allowSameDayOverBooking,
    includeOverbookingSlotAfterMinutes,
    dynamicSlot
  } = funnelConfig;
  const params = {
    virtualSlots,
    prioritybooking: false,
    overbooking: false
  };
  const purchaseTrackProperty = getCarPropertyByIdSelector(carlead, CAR_PROPERTIES.CAR_PURCHASE_TRACK);
  if (purchaseTrackProperty) {
    const isRiskFree = purchaseTrackProperty.value === 'risk-free',
      isBalanceSheet = purchaseTrackProperty.value === 'balance-sheet';
    if ((isRiskFree || isBalanceSheet) && (hasValidBid || canSeePrice) || isBalanceSheet && (!hasValidBid || !canSeePrice)) {
      params.overbooking = false;
      delete params.virtualSlots;
    }
  }
  if (allowSameDayOverBooking) {
    params.allowSameDayOverBooking = true;
    params.includeOverbookingSlotAfterMinutes = includeOverbookingSlotAfterMinutes;
  }
  if (availableSlotsDayOffset) {
    // TODO: do the same thing for search params
    params.dayOffset = formValues.dayOffset;
  }
  if (dynamicSlot) {
    params.dynamicSlot = dynamicSlot;
  }
  return params;
}