import { createApi } from './api';
export function create(_ref) {
  let {
    httpClient,
    config
  } = _ref;
  const {
    getMappingWithLicensePlate,
    searchCarDataMapping
  } = createApi(httpClient, config);
  return {
    getMappingWithLicensePlate,
    searchCarDataMapping
  };
}