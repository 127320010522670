export function createApi(httpClient) {
  function getAreas(country) {
    return httpClient.get('/branch/area', {
      params: {
        country
      }
    });
  }
  return {
    getAreas
  };
}