import includes from 'lodash-es/includes';
import * as cidChannelTypes from './channelTypes';
import * as cidCountryCodes from './countryCodes';

//catches DE or similar and B1, B2
const CID_PATTERN = /^([A-Za-z|A-Z0-9]{2})_([A-Za-z]{3})_([0-9A-Za-z\-_]+)&*/;
const parseCid = cid => {
  if (typeof cid !== 'string') return {};
  const matches = cid.match(CID_PATTERN);
  if (matches) {
    const [countryCode, channel, value] = matches.slice(1);
    return {
      countryCode,
      channel,
      value
    };
  }
  return {};
};
export const isCidValid = function () {
  let cid = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  const {
    countryCode,
    channel,
    value
  } = parseCid(cid);

  // Validate emptyness
  if (!countryCode || !channel || !value) {
    return false;
  }

  // Validate CountryCode
  if (countryCode && !includes(cidCountryCodes, countryCode)) {
    return false;
  }

  // Validate Channel
  if (channel && !includes(cidChannelTypes, channel)) {
    return false;
  }
  return true;
};