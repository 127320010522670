const SELF_EVALUATION_PATH = 'self-evaluation';
export function createApi(httpClient) {
  return {
    getSelfEvaluation,
    getSelfEvaluationPreview,
    postReevaluation,
    createSelfEvaluation,
    updateSelfEvaluation,
    updateEvaluationStep,
    updateSelfEvaluationCarleadBranch,
    updateACIEvaluationStep,
    finalizeSelfEvaluation,
    updateSelfEvaluationImage,
    removeSelfEvaluationImage,
    identifyVehicleByLicensePlate,
    patchSelfEvaluation,
    savePartialSelfEvaluation,
    updateSelfEvaluationProperties,
    getReevaluationApplicability,
    getVinVerificationOptions,
    getAutopricingElegibility,
    updateSelfEvaluationVin,
    createEssentialSelfEvaluation,
    createSelfEvaluationAssumption,
    createAciAuction,
    createSelfEvaluationDamage,
    deleteSelfEvaluationDamage,
    createACIRedirect,
    getSelfEvaluationDamage,
    updateSelfEvaluationDamage,
    deleteSelfEvaluationDamageImage,
    getVinUpdateRequest,
    postVinUpdateRequest
  };
  function getSelfEvaluation(carleadHash) {
    return httpClient.get(SELF_EVALUATION_PATH + "/" + carleadHash + "/").then(res => res.data);
  }
  function getSelfEvaluationPreview(carleadHash) {
    return httpClient.get(SELF_EVALUATION_PATH + "/" + carleadHash + "/preview").then(res => res.data);
  }
  function postReevaluation(carleadHash, data) {
    return httpClient.post(SELF_EVALUATION_PATH + "/" + carleadHash + "/reevaluation", data).then(res => res.data);
  }
  function createSelfEvaluation(carleadHash, data) {
    return httpClient.post(SELF_EVALUATION_PATH + "/" + carleadHash, {
      ...data,
      carLeadHash: carleadHash
    }).then(res => res.data);
  }
  function updateSelfEvaluation(carleadHash, data) {
    return httpClient.put(SELF_EVALUATION_PATH + "/" + carleadHash, data);
  }
  function patchSelfEvaluation(carleadHash, data) {
    return httpClient.patch(SELF_EVALUATION_PATH + "/" + carleadHash, data, {
      headers: {
        'Content-Type': 'application/json-patch+json'
      }
    });
  }
  function savePartialSelfEvaluation(carleadHash, data) {
    return httpClient.post(SELF_EVALUATION_PATH + "/" + carleadHash + "/partial-save", data, {
      headers: {
        'Content-Type': 'application/json-patch+json'
      }
    });
  }
  function finalizeSelfEvaluation(carleadHash, data) {
    return httpClient.post(SELF_EVALUATION_PATH + "/" + carleadHash + "/self-inspection", data);
  }
  function updateSelfEvaluationCarleadBranch(carleadHash) {
    return httpClient.put(SELF_EVALUATION_PATH + "/" + carleadHash + "/carlead/branch", {});
  }
  function updateEvaluationStep(carleadHash, step) {
    return httpClient.put(SELF_EVALUATION_PATH + "/" + carleadHash + "/funnel-step", {
      value: step
    });
  }
  function updateACIEvaluationStep(carleadHash, data) {
    return httpClient.put(SELF_EVALUATION_PATH + "/" + carleadHash + "/aci-funnel-step", data);
  }
  function updateSelfEvaluationImage(carleadHash, data) {
    return httpClient.put(SELF_EVALUATION_PATH + "/" + carleadHash + "/images", data);
  }
  function removeSelfEvaluationImage(carleadHash, id) {
    return httpClient.delete(SELF_EVALUATION_PATH + "/" + carleadHash + "/images/" + id);
  }
  function identifyVehicleByLicensePlate(carleadHash, licensePlate) {
    return httpClient.post(SELF_EVALUATION_PATH + "/" + carleadHash + "/vehicle-identification", {
      licensePlate
    });
  }
  function updateSelfEvaluationProperties(carleadHash, payload) {
    return httpClient.put(SELF_EVALUATION_PATH + "/" + carleadHash + "/properties", payload);
  }
  function getReevaluationApplicability(carleadHash, params) {
    return httpClient.get(SELF_EVALUATION_PATH + "/" + carleadHash + "/reevaluation/applicability", {
      params
    });
  }
  function getVinVerificationOptions(carleadHash) {
    return httpClient.get(SELF_EVALUATION_PATH + "/" + carleadHash + "/vin-verification-options");
  }
  function getAutopricingElegibility(carleadHash) {
    return httpClient.get(SELF_EVALUATION_PATH + "/" + carleadHash + "/autopricing/eligibility");
  }
  function updateSelfEvaluationVin(carleadHash, vin) {
    return httpClient.post(SELF_EVALUATION_PATH + "/" + carleadHash + "/vin", {
      vin
    });
  }
  function createEssentialSelfEvaluation(carleadHash, data) {
    return httpClient.post(SELF_EVALUATION_PATH + "/" + carleadHash + "/essential", data);
  }
  function createSelfEvaluationAssumption(carleadHash) {
    return httpClient.post(SELF_EVALUATION_PATH + "/" + carleadHash + "/assumption", {});
  }
  function createAciAuction(carleadHash, _data) {
    return httpClient.post(SELF_EVALUATION_PATH + "/" + carleadHash + "/aci-auction-start", {});
  }
  function createSelfEvaluationDamage(carleadHash, payload) {
    return httpClient.post(SELF_EVALUATION_PATH + "/" + carleadHash + "/damages", payload);
  }
  function updateSelfEvaluationDamage(carleadHash, payload) {
    return httpClient.put(SELF_EVALUATION_PATH + "/" + carleadHash + "/damages", payload);
  }
  function deleteSelfEvaluationDamage(carleadHash, damageId) {
    return httpClient.delete(SELF_EVALUATION_PATH + "/" + carleadHash + "/damages/" + damageId);
  }
  function createACIRedirect(carleadHash) {
    return httpClient.post(SELF_EVALUATION_PATH + "/" + carleadHash + "/aci-mobile-redirect", {});
  }
  function getSelfEvaluationDamage(id) {
    return httpClient.get(SELF_EVALUATION_PATH + "/damages/" + id).then(res => res.data);
  }
  function deleteSelfEvaluationDamageImage(carleadHash, damageId) {
    return httpClient.delete(SELF_EVALUATION_PATH + "/" + carleadHash + "/damages/images/" + damageId);
  }
  function getVinUpdateRequest(carleadHash) {
    return httpClient.get(SELF_EVALUATION_PATH + "/" + carleadHash + "/vin-update-request");
  }
  function postVinUpdateRequest(carleadHash, vin) {
    return httpClient.post(SELF_EVALUATION_PATH + "/" + carleadHash + "/vin-update-request", {
      vin
    });
  }
}