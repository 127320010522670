import type { ElasticRumConfig } from '../types';
import isGdprAnalyticsEnabled from './isGdprAnalyticsEnabled';

export default function prepareRumConfig(config: ElasticRumConfig) {
  const errorOnlyConfig = {
    ...config,
    active: 1,
    disableInstrumentations: [
      'page-load',
      'eventtarget',
      'history',
      'fetch',
      'xmlhttprequest',
    ],
  };

  if (config.active !== 1) {
    return errorOnlyConfig;
  }

  if (isGdprAnalyticsEnabled()) {
    return config;
  }

  return errorOnlyConfig;
}
