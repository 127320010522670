export function createApi(httpClient) {
  return {
    trackFieldValue,
    trackEvent
  };
  function trackFieldValue(hash, data) {
    return httpClient.put("/car-details/marketing-property/" + hash, data).catch(error => {
      console.log(error);
      throw error;
    });
  }
  function trackEvent(_ref) {
    let {
      source,
      timestamp,
      action,
      userAgent,
      referrer,
      hash,
      category = 'double-booking',
      sourceUrl,
      targetUrl
    } = _ref;
    return httpClient.post("/tracking", {
      type: 'cfe',
      appname: 'wkda',
      category,
      action,
      userAgent,
      referrer,
      hash,
      sourceUrl,
      targetUrl,
      source,
      timestamp
    });
  }
}