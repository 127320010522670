export const PROPERTY_IDs = {
  city: 3401,
  branch: 3402,
  date: 3403,
  time: 3404,
  firstName: 3405,
  lastName: 3406,
  phone: 3407,
  sellingDecision: 3408,
  directBooking: 3410,
  userSelectedBranch: 3473,
  doubleBooking: 3490
};
export const LEAD_SOURCE_IDs = {
  'de': '1',
  'nl': '2',
  'it': '3',
  'fr': '4',
  'es': '5',
  'pl': '6',
  'de-AT': '7',
  'nl-BE': '8',
  'fr-BE': '9',
  // 10 is skipped, since US-branches have IDs 10001 - 11000
  'sv-SE': '11',
  'lu': '12',
  'da': '14',
  'pt': '23'
};