import { addDays, formatDateString, removeTime } from '@wkda/funnel-utils';
import get from 'lodash-es/get';
import { createSelector } from 'reselect';
import { createApi } from './api';
import { createApi as createAppointmentsApi } from './appointmentsApi';
import { getCalendarDateOptions } from './getCalendarOptions';
export function create(_ref, _ref2) {
  let {
    httpClient
  } = _ref;
  let {
    marketingPropertiesModule,
    cidModule,
    loggerModule
  } = _ref2;
  const {
    getAvailableAppointmentTimes: _getAvailableAppointmentTimes,
    getLatestAppointmentDetails: _getLatestAppointmentDetails,
    createAppointment: _createAppointment,
    cancelAppointment: _cancelAppointment,
    markAppointmentAsActive: _markAppointmentAsActive
  } = createApi(httpClient);
  const {
    getAppointmentsByReference: _getAppointmentsByReference,
    getLastAppointments: _getLastAppointments
  } = createAppointmentsApi(httpClient);
  function getAvailableAppointmentTimes(branchId, hash, options) {
    return _getAvailableAppointmentTimes(branchId, hash, options).then(res => res.data);
  }
  function getLatestAppointmentDetails(hash) {
    return _getLatestAppointmentDetails(hash).then(resp => resp.data);
  }
  function createAppointment(data) {
    return _createAppointment(data).then(res => res.data);
  }
  async function trackCancellation(carleadHash) {
    try {
      const lastAppointment = await getLastAppointments(carleadHash);
      const appointmentType = lastAppointment.type;
      const event = appointmentType === 'evaluation' ? 'EVALUATION_APPOINTMENT_CANCELLED' : appointmentType === 'handover' ? 'HANDOVER_APPOINTMENT_CANCELLED' : undefined;
      if (event) {
        await cidModule.trackCidEvent({
          event,
          carleadHash: carleadHash
        });
      }
    } catch (error) {
      loggerModule.error({
        error,
        message: 'Appointment cancellation CID tracking error'
      });
    }
  }
  async function cancelAppointment(referenceId, comment, statusChangeSubreason) {
    try {
      const result = await _cancelAppointment(referenceId, comment, statusChangeSubreason);
      if (result.status !== 204) return false;
      trackCancellation(referenceId);
      return true;
    } catch (error) {
      loggerModule.error({
        error,
        message: 'Appointment cancellation error'
      });
      return false;
    }
  }
  function markAppointmentAsActive(data) {
    return _markAppointmentAsActive(data).then(res => res.status === 204).catch(() => {
      return false;
    });
  }
  function getLastAppointments(referenceId) {
    return _getLastAppointments(referenceId).then(res => res.data);
  }
  function getAppointmentsByReference(referenceId, type) {
    return _getAppointmentsByReference(referenceId, type).then(res => res.data.entities);
  }
  function setLocationProperty(hash, propertyId, area) {
    const {
      name,
      type = 'wkda',
      lat,
      lng
    } = area;
    return marketingPropertiesModule.save(hash, {
      propertyId,
      value: JSON.stringify({
        label: name,
        type,
        lat,
        lng
      })
    });
  }
  const setAppointmentLocation = (hash, area) => setLocationProperty(hash, marketingPropertiesModule.propertyIds.appointmentlocation, area);
  const setSessionLocationProperty = (hash, area) => setLocationProperty(hash, marketingPropertiesModule.propertyIds.sessionLocation, area);
  return {
    getAvailableAppointmentTimes,
    getLatestAppointmentDetails,
    createAppointment,
    cancelAppointment,
    setAppointmentLocation,
    setSessionLocationProperty,
    getAppointmentsByReference,
    getLastAppointments,
    markAppointmentAsActive
  };
}
// #region Selectors
export const availableDatesAsOptionsSelector = createSelector([availableDatesSelector, function (_, locale) {
  return locale;
}], (dates, locale) => {
  if (!dates) {
    return [];
  }
  return Object.keys(dates).map(date => {
    return {
      value: date,
      label: formatDateString(locale, date)
    };
  });
});
export const availableDatesAsCalendarOptionsSelector = createSelector([availableDatesSelector, function (_, locale) {
  return locale;
}, function (_, __, availableSlotsDayOffset) {
  return availableSlotsDayOffset;
}], (dates, locale, availableSlotsDayOffset) => {
  return getCalendarDateOptions(dates, locale, availableSlotsDayOffset, new Date());
});
export const availableTimesAsOptionsSelector = createSelector([availableDatesSelector, selectTimeSlotsOfDate, function (_, _date, locale) {
  return locale;
}], (dates, date, locale) => {
  var _dates$date$slots$map, _dates$date;
  if (typeof dates === 'undefined' || typeof dates[date] === 'undefined') {
    return [];
  }
  return (_dates$date$slots$map = dates === null || dates === void 0 || (_dates$date = dates[date]) === null || _dates$date === void 0 || (_dates$date = _dates$date.slots) === null || _dates$date === void 0 ? void 0 : _dates$date.map(slot => {
    return {
      value: slot.start,
      label: formatDateString(locale, slot.start, {
        hour: '2-digit',
        minute: '2-digit'
      }),
      slot
    };
  })) !== null && _dates$date$slots$map !== void 0 ? _dates$date$slots$map : [];
});
export const availableTimesAsCalendarOptionsSelector = createSelector([availableDatesSelector, selectTimeSlotsOfDate, function (_, _date, locale) {
  return locale;
}, function (_, _date, _locale, showDisabledTimeSlots) {
  return showDisabledTimeSlots;
}], (dates, date, locale, showDisabledTimeSlots) => {
  if (typeof dates === 'undefined' || typeof dates[date] === 'undefined') {
    return [];
  }
  const allSlots = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'];
  const availableSlots = dates[date].slots.reduce((result, slot) => {
    const key = formatDateString(locale, slot.start, {
      hour: '2-digit',
      minute: '2-digit'
    });
    if (key) result[key] = slot;
    return result;
  }, {});
  const availableSlotKeys = Object.keys(availableSlots);
  const slotAsNumber = slot => Number(slot.replace(':', ''));
  const firstAvailableSlot = availableSlotKeys[0];
  const lastAvailableSlot = availableSlotKeys[availableSlotKeys.length - 1];
  return allSlots.reduce((result, slotTime) => {
    const slotInAvailableRange = slotAsNumber(slotTime) >= slotAsNumber(firstAvailableSlot) && slotAsNumber(slotTime) <= slotAsNumber(lastAvailableSlot);
    const slot = availableSlots[slotTime];
    const disabledSlot = !slot;
    if (slotInAvailableRange) {
      if (disabledSlot && showDisabledTimeSlots) {
        result.push({
          value: slotTime + "-disabled",
          label: slotTime,
          slot: slot,
          disabled: true,
          className: 'box-selector__item--disabled'
        });
      } else if (!disabledSlot) {
        result.push({
          value: slot === null || slot === void 0 ? void 0 : slot.start,
          label: slotTime,
          slot: slot
        });
      }
    }
    return result;
  }, []);
});
export const isSelectionAvailableInTimeSlotSelector = createSelector([availableDatesSelector, (_, date, time) => {
  return {
    date,
    time
  };
}], (dates, params) => {
  const targetSlots = get(dates, [params.date, 'slots'], []);
  return Boolean(targetSlots.find(slot => {
    return slot.start === params.time;
  }));
});
function selectTimeSlotsOfDate(_, date) {
  return date;
}
function availableDatesSelector(state) {
  return get(state, ['availableDates']);
}

// #endregion Selectors

export function isDynamicExtensionSlot(config, availableSlotsDayOffset, date) {
  var _config$dynamicSlot, _config$dynamicSlot2;
  const isDynamicExtensionConfigEnabled = ((_config$dynamicSlot = config.dynamicSlot) === null || _config$dynamicSlot === void 0 ? void 0 : _config$dynamicSlot.cutoffDaysToShowSlots) || ((_config$dynamicSlot2 = config.dynamicSlot) === null || _config$dynamicSlot2 === void 0 ? void 0 : _config$dynamicSlot2.minimumDaysToShowSlot);
  if (!isDynamicExtensionConfigEnabled) return false;
  const now = removeTime(new Date());
  const maxDateWithDaysOffset = addDays(now, availableSlotsDayOffset);
  return removeTime(date) > maxDateWithDaysOffset;
}