import { createApi } from './api';
export function create(_ref) {
  let {
    httpClient
  } = _ref;
  const {
    getEmailCount: _getEmailCount
  } = createApi(httpClient);
  function getEmailCount() {
    let email = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    if (email.trim() === '') {
      return {
        count: null
      };
    }
    return _getEmailCount(email).then(res => {
      return res.data;
    }).catch(() => {
      console.log("[GET email count error]");
      return {
        count: null
      };
    });
  }
  return {
    getEmailCount
  };
}