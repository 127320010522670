import loadRumAsync from './loadRumAsync';
import type {
  ElasticApmInstance,
  ElasticRumConfig,
  Logger,
  UserOptions,
} from './types';

export class RumLogger implements Logger {
  private instancePromise: Promise<ElasticApmInstance>;

  constructor(config: ElasticRumConfig, userOptions: UserOptions) {
    this.instancePromise = loadRumAsync(config, userOptions);
    this.instancePromise.catch((error) => {
      console.warn('Error while loading Elastic APM');
      console.error(error);
    });
  }

  public getApmInstance(): Promise<ElasticApmInstance> {
    return this.instancePromise;
  }

  public log(name: string, scope: string, message: string): void {
    this.instancePromise
      .then((instance) => {
        const transaction = instance.startTransaction(name, scope);
        const span = transaction.startSpan(message);
        // TODO: figure out why it doesn't work without setTimeout
        setTimeout(() => {
          span.end();
          transaction.end();
        }, 0);
      })
      .catch((error) => {
        console.warn('Error while logging to Elastic APM');
        console.error(error);
      });
  }

  public error(error: Error): void {
    this.instancePromise
      .then((instance) => {
        instance.captureError(error);
      })
      .catch((error) => {
        console.warn('Error while logging to Elastic APM');
        console.error(error);
      });
  }
}
