export function create(_ref) {
  let {
    partialsModule,
    locale,
    cookieBannerName
  } = _ref;
  async function isBannerAvailable() {
    try {
      const banner = await partialsModule.getCookieBanner(locale, cookieBannerName);
      return Boolean(banner);
    } catch (err) {
      return false;
    }
  }
  return {
    isBannerAvailable
  };
}