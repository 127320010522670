import type { IStorage } from './dataLayer.types';

export class LocalStorage implements IStorage {
  private hasGlobalStorage(): boolean {
    try {
      return typeof localStorage !== 'undefined';
    } catch (err) {
      return false;
    }
  }

  get(key: string) {
    if (!this.hasGlobalStorage()) return;

    const value = localStorage.getItem(key);
    if (!value) return null;

    try {
      return JSON.parse(value);
    } catch (err) {
      return value;
    }
  }

  set(key: string, value: any) {
    if (!this.hasGlobalStorage()) return;

    localStorage.setItem(
      key,
      typeof value === 'object' ? JSON.stringify(value) : value,
    );
  }
}
