import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { isAxiosError, isFieldError } from '@wkda/funnel-utils';
import { LIMIT_ERROR_MESSAGE } from './axiosDefaults';
import { getHeaderCaseInsensitive } from './getHeaderCaseInsensitive';
export class ApiError extends Error {
  constructor(error) {
    var _error$config, _error$config2, _error$response, _error$config3, _error$response2;
    super(ApiError.constructErrorMessage(error));
    _defineProperty(this, "url", void 0);
    _defineProperty(this, "status", void 0);
    _defineProperty(this, "traceId", void 0);
    _defineProperty(this, "method", void 0);
    this.name = 'ApiError';
    this.url = "" + ((_error$config = error.config) === null || _error$config === void 0 ? void 0 : _error$config.baseURL) + ((_error$config2 = error.config) === null || _error$config2 === void 0 ? void 0 : _error$config2.url);
    this.status = (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status;
    this.method = (_error$config3 = error.config) === null || _error$config3 === void 0 || (_error$config3 = _error$config3.method) === null || _error$config3 === void 0 ? void 0 : _error$config3.toUpperCase();
    this.traceId = getHeaderCaseInsensitive(((_error$response2 = error.response) === null || _error$response2 === void 0 ? void 0 : _error$response2.headers) || {}, 'X-B3-Traceid');
  }
  static constructErrorMessage(error) {
    let errorMessage = '';
    if (isAxiosError(error)) {
      errorMessage += error.message + " ";
      if (isFieldError(error)) {
        var _error$response3;
        const {
          globalErrors,
          fieldErrors
        } = ((_error$response3 = error.response) === null || _error$response3 === void 0 ? void 0 : _error$response3.data) || {};
        if (globalErrors && globalErrors.length > 0) errorMessage += "\n" + globalErrors.join('; ');
        if (fieldErrors && fieldErrors.length > 0) {
          errorMessage += "\n" + fieldErrors.map(e => e.message).join('; ');
        }
      } else {
        var _error$response4, _error$response6;
        if (typeof ((_error$response4 = error.response) === null || _error$response4 === void 0 ? void 0 : _error$response4.data) === 'object') {
          var _error$response5;
          errorMessage += JSON.stringify((_error$response5 = error.response) === null || _error$response5 === void 0 ? void 0 : _error$response5.data);
        } else if (typeof ((_error$response6 = error.response) === null || _error$response6 === void 0 ? void 0 : _error$response6.data) === 'string') {
          var _error$response7;
          errorMessage += (_error$response7 = error.response) === null || _error$response7 === void 0 ? void 0 : _error$response7.data;
        }
      }
    } else {
      errorMessage += 'Unknown error';
    }

    // Limit to 1000 characters to ensure compatibility with Kibana.
    return errorMessage.length > LIMIT_ERROR_MESSAGE - 3 ? errorMessage.substring(0, LIMIT_ERROR_MESSAGE - 3) + "..." : errorMessage;
  }
}