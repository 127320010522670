import { create as createAppointment } from './appointment';
import { create as createAreaModule } from './area';
import { create as createAutoPricing } from './auto-pricing';
import { create as createBooking } from './booking';
import { create as createBookingTracking } from './booking-tracking';
import { create as createBranchModule } from './branch';
import { create as createCarCrm } from './car-crm';
import { create as createCarPurchase } from './car-purchase';
import { create as createCarTypes } from './car-types';
import { create as createCarTypesSplit } from './car-types-split';
import { create as createCarleadModule } from './carlead';
import { create as createCid } from './cid';
import { create as createCJTracking } from './cj-tracking';
import { create as createClassifiedImages } from './classified-images';
import { create as createConsumer } from './consumer';
import { create as createCrmModule } from './crm';
import { create as createCrmQuestionnaireModule } from './crm-questionnaire';
import { create as createCustomerAccount } from './customer-account';
import { create as createCustomerData } from './customer-data';
import { create as createEmail } from './email';
import { create as createGDPRBanner } from './gdpr-banner';
import { create as createGDPRPreferences } from './gdpr-preferences';
import { create as createGeolocationModule } from './geolocation';
import { modifyAxiosDefaults } from './httpClient/axiosDefaults';
import { create as createInspection } from './inspection';
import { create as createMapping } from './license-plate-mapping';
import { createLogger } from './logger';
import { create as createMarketingAttributes } from './marketing-attributes';
import { create as createMarketingProperties } from './marketing-properties';
import { create as createPartials } from './partials';
import { create as createQuestionnaire } from './questionnaire';
import { create as createSubscription } from './subscription';
import { create as createTracking } from './tracking';
import { create as createWhatsapp } from './whatsapp';
import { createHttpClients } from './httpClient/createHttpClient';
export function initialize(_ref) {
  let {
    configuration,
    waKey,
    isServer,
    apmLogger,
    modulesLogger,
    traceId
  } = _ref;
  modifyAxiosDefaults(configuration, isServer, waKey, traceId);
  const {
    baseNapiUrl
  } = configuration;
  const {
    apiHttpClient,
    carTypesHttpClient,
    napiHttpClient,
    noV1ApiHttpClient,
    sameOriginHttpClient
  } = createHttpClients(configuration, modulesLogger);
  const loggerModule = createLogger(configuration);
  const marketingPropertiesModule = createMarketingProperties({
    config: configuration,
    httpClient: apiHttpClient
  });
  const classifiedImagesModule = createClassifiedImages({
    config: configuration,
    httpClient: apiHttpClient
  });
  const consumerModule = createConsumer({
    config: configuration,
    httpClient: apiHttpClient
  });
  const partialsModule = createPartials({
    config: configuration,
    httpClient: apiHttpClient
  });
  const subscriptionModule = createSubscription({
    config: configuration,
    httpClient: apiHttpClient
  });
  const cidModule = createCid({
    config: configuration,
    httpClient: napiHttpClient
  }, {
    getConsumerCarlead: consumerModule.getConsumerCarlead,
    getPartial: partialsModule.getPartial,
    env: configuration.env
  }, loggerModule);
  const inspectionModule = createInspection({
    config: configuration,
    httpClient: apiHttpClient
  }, {
    uploadClassifiedImages: classifiedImagesModule.uploadClassifiedImages,
    trackCidEvent: cidModule.trackCidEvent,
    updateMarketingProperty: marketingPropertiesModule.save,
    marketingPropertyIds: marketingPropertiesModule.propertyIds
  });
  const customerAccountModule = createCustomerAccount({
    config: configuration,
    httpClient: apiHttpClient
  });
  const customerDataModule = createCustomerData({
    config: configuration,
    httpClient: apiHttpClient
  });
  const marketingAttributesModule = createMarketingAttributes({
    config: configuration,
    httpClient: apiHttpClient
  });
  const licensePlateMappingModule = createMapping({
    config: configuration,
    httpClient: apiHttpClient
  });
  const carTypesModule = createCarTypes({
    config: configuration,
    httpClient: carTypesHttpClient
  });
  const carTypesSplitModule = createCarTypesSplit({
    config: configuration,
    httpClient: carTypesHttpClient
  });
  const carCrmModule = createCarCrm({
    config: configuration,
    httpClient: apiHttpClient
  });
  const questionnaireModule = createQuestionnaire({
    config: configuration,
    httpClient: apiHttpClient
  });
  const gdprBannerModule = createGDPRBanner({
    partialsModule,
    locale: configuration.locale,
    cookieBannerName: configuration.cookieBannerName
  });
  const cjTrackingModule = createCJTracking({
    config: configuration,
    httpClient: apiHttpClient
  }, {
    appver: configuration.appVersion
  });
  const appointmentModule = createAppointment({
    config: configuration,
    httpClient: apiHttpClient
  }, {
    marketingPropertiesModule,
    cidModule,
    loggerModule
  });
  const bookingTrackingModule = createBookingTracking({
    config: configuration,
    httpClient: apiHttpClient
  }, loggerModule, apmLogger);
  const crmQuestionnaireModule = createCrmQuestionnaireModule(apiHttpClient);
  const branchDataModule = createBranchModule({
    config: configuration,
    httpClient: apiHttpClient
  });
  const bookingModule = createBooking({
    createBookingAppointment: appointmentModule.createAppointment,
    getAvailableAppointmentTimes: appointmentModule.getAvailableAppointmentTimes,
    getAppointmentsByReference: appointmentModule.getAppointmentsByReference,
    updateMarketingProperty: marketingPropertiesModule.save,
    updateCarCrmProperty: carCrmModule.update,
    trackEvent: bookingTrackingModule.trackEvent,
    setAppointmentLocation: appointmentModule.setAppointmentLocation,
    trackCidEvent: cidModule.trackCidEvent,
    getSelfEvaluation: inspectionModule.getSelfEvaluation,
    getConsumerCarlead: consumerModule.getConsumerCarlead,
    getBranchProperties: branchDataModule.getBranchProperties
  });
  const crmModule = createCrmModule({
    config: configuration,
    httpClient: apiHttpClient
  });
  const carPurchaseModule = createCarPurchase({
    config: configuration,
    httpClient: apiHttpClient
  });
  const gdprPreferencesModule = createGDPRPreferences({
    config: configuration,
    httpClient: sameOriginHttpClient
  }, {
    env: configuration.env
  });
  const emailModule = createEmail({
    config: configuration,
    httpClient: apiHttpClient
  });
  const autoPricingModule = createAutoPricing(baseNapiUrl, {
    marketingPropertiesService: marketingPropertiesModule,
    logger: loggerModule,
    apmLogger,
    config: configuration
  });
  const whatsappModule = createWhatsapp(baseNapiUrl, {
    config: configuration,
    apmLogger,
    logger: loggerModule
  });
  const trackingModule = createTracking({
    config: configuration,
    httpClient: noV1ApiHttpClient
  }, {
    gdprBannerModule,
    waKey: waKey,
    logger: loggerModule
  });
  const areaModule = createAreaModule({
    config: configuration,
    httpClient: apiHttpClient
  });
  const carleadModule = createCarleadModule({
    createConsumerCarlead: consumerModule.createConsumerCarlead,
    trackCidEvent: cidModule.trackCidEvent
  });
  const geolocationModule = createGeolocationModule({
    config: configuration,
    httpClient: apiHttpClient
  }, loggerModule);
  return {
    cidModule,
    classifiedImagesModule,
    consumerModule,
    partialsModule,
    subscriptionModule,
    inspectionModule,
    customerAccountModule,
    customerDataModule,
    marketingAttributesModule,
    licensePlateMappingModule,
    carTypesModule,
    carTypesSplitModule,
    carCrmModule,
    questionnaireModule,
    autoPricingModule,
    whatsappModule,
    marketingPropertiesModule,
    gdprPreferencesModule,
    bookingTrackingModule,
    gdprBannerModule,
    crmModule,
    cjTrackingModule,
    appointmentModule,
    bookingModule,
    branchDataModule,
    areaModule,
    carleadModule,
    apmLogger,
    crmQuestionnaireModule,
    carPurchaseModule,
    geolocationModule,
    email: emailModule,
    tracking: trackingModule,
    logger: loggerModule
  };
}